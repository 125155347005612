import React, { useState } from 'react';
import LoadingButton from "@mui/lab/LoadingButton";
import {Stack, Typography, Select, MenuItem, alpha } from '@mui/material';
import AppModal from "../../../AppModal";
function AddToGroupModal({ open, onClose, onSave, groups }) {
    const [selectedGroup, setSelectedGroup] = useState('');
    const [addToGroupLoading, setAddToGroupLoading] = useState(false);

    const handleGroupChange = (event) => {
        setSelectedGroup(event.target.value);
    };

    const handleSave = () => {
        setAddToGroupLoading(true);
        const res = onSave(selectedGroup);
        if (res){
            setAddToGroupLoading(false);
            onClose();
        }
    };

    return (
        <AppModal
        open={open}
        title={"Add Selected Clients to a Group"}
        handleClose={onClose}
        maxWidth="sm"
        closeIcon
        >
            <Stack gap={3}>
                <Stack gap={1.5}>
                    <Typography id="group-selection-modal-title" variant="h6" component="h2">
                    Select a Group
                    </Typography>
                    <Select
                    fullWidth
                    value={selectedGroup}
                    onChange={handleGroupChange}
                    displayEmpty
                    closeIcon
                    >
                    <MenuItem value="" disabled>
                        Choose a group
                    </MenuItem>
                    {groups.map(group => (
                        <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                    ))}
                    </Select>
                </Stack>
                <Stack gap={1.5}>
                    <LoadingButton
                        loading={addToGroupLoading}
                        variant={"contained"}
                        sx={{
                            alignSelf: "flex-end",
                            height: 48,
                            bgcolor: "#5177FC",
                            ":hover": {
                            bgcolor: alpha("#5177FC", 0.9),
                            },
                            fontSize: 16,
                            fontWeight: 500,
                            padding: "8px 16px",
                            borderRadius: "8px",
                            textTransform: "capitalize",
                        }}
                        onClick={handleSave}
                        disabled={!selectedGroup}
                        >
                        Save
                    </LoadingButton>
                </Stack>
            </Stack>
        </AppModal>
    );
}

export default AddToGroupModal;
