import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCognitoUser } from "../../../CognitoProvider";
import { CheckoutPlan } from "../../../services/api";
import { lazy } from "react";
import { Suspense } from "react";
import { PageLayout } from "../../page-layout";
import PasswordInput from "../../inputs/PasswordInput";
const ReCAPTCHA = lazy(() => import("react-google-recaptcha"));

const CognitoSignUp = () => {
  const enableCaptcha = process.env.REACT_APP_ENABLE_CAPTCHA === 'true';
  const navigate = useNavigate();
  const location = useLocation();
  const { signUp } = useCognitoUser();

  const [captchaValid, setCaptchaValid] = useState(false);
  const [showCaptchaError, setShowCaptchaError] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});

  const handleSignup = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    if(enableCaptcha){
      if (!captchaValid) {
        setShowCaptchaError(true);
        return;
      }
    }
    try {
      setErrors({});
      const user = await signUp({
        given_name: firstName,
        family_name: lastName,
        name: firstName + " " + lastName,
        username: email.toLowerCase(),
        password,
      });
      if (location.state?.plan) {
        const response = await CheckoutPlan(location.state.plan);
        window.location.replace(response.data.url);
      } else if (user?.username) {
        navigate("/login/redirection");
      }
    } catch (error) {
      if (error.message.includes("InvalidPasswordException")) {
        setErrors((prev) => ({
          ...prev,
          password:
            "Password did not conform with policy: Password not long enough.",
        }));
      } else if (error.message.includes("UsernameExistsException")) {
        setErrors((prev) => ({
          ...prev,
          email: "An account with the given email already exists.",
        }));
      }
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!firstName.trim()) {
      errors.firstName = "First name is required";
    }

    if (!lastName.trim()) {
      errors.lastName = "Last name is required";
    }

    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!isValidEmail(email)) {
      errors.email = "Invalid email address";
    }

    if (!password.trim()) {
      errors.password = "Password is required";
    } else if (password.length < 6) {
      errors.password = "Password should be at least 6 characters long";
    }

    return errors;
  };

  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSignup();
    }
  };

  const onChangeCaptcha = async (captchaCode) => {
    if (!captchaCode) {
      setCaptchaValid(false);
      return;
    }
    setCaptchaValid(true);
    if (showCaptchaError) {
      setShowCaptchaError(false);
    }
  };
  const current_url = window.location.href;
  const paramExists = current_url.includes('free-trial');
  let signinpUrl = '/login';
  if (paramExists){
    signinpUrl = '/login?free-trial=true';
  }
  return (
    <PageLayout>
      <div className="padding-sides" >
        <div className="signup-wrapper">
          <div className="signup-container">
            <h2 className="signup-title">Sign up</h2>
            <div className="signupTextfieldWrapper">
              <div>
                <label>First Name:</label>
                <input
                  className="signup-form-input"
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                {errors.firstName && (
                  <span className="signup-error-message">{errors.firstName}</span>
                )}
              </div>
              <div>
                <label>Last Name:</label>
                <input
                  className="signup-form-input"
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                {errors.lastName && (
                  <span className="signup-error-message">{errors.lastName}</span>
                )}
              </div>
              <div>
                <label>Email:</label>
                <input
                  className="signup-form-input"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errors.email && (
                  <span className="signup-error-message">{errors.email}</span>
                )}
              </div>
              <div>
                <label>Password:</label>
                <PasswordInput
                  password={password}
                  setPassword={setPassword}
                />
              </div>
            </div>
            {enableCaptcha && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: 16,
              }}
            >
              <Suspense fallback={<>Loading...</>}>
                <ReCAPTCHA
                  sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTCHA}`}
                  onChange={onChangeCaptcha}
                />
              </Suspense>
              {showCaptchaError && (
                <p style={{ color: "red", marginTop: 4 }}>
                  Please solve captcha to continue.
                </p>
              )}
            </div>
            )}
            <button className="signup-form-button" onClick={handleSignup}>
              Sign up
            </button>

            <p
              className="primary-text"
              style={{
                marginBlock: "20px",
                textAlign: "center",
                fontSize: 12,
              }}
            >
              Already have an account?{" "}
              <p
                style={{
                  cursor: "pointer",
                  color: "var(--brand)",
                  display: 'inline-block',
                  marginBottom: 0,
                }}
                onClick={() => navigate(signinpUrl)}
              >
                Sign in.
              </p>
            </p>

            <p style={{ marginBlock: "20px", textAlign: "center" }}>
              By registering for an account or using this website, you agree to our
              <a
                style={{ color: "var(--brand)", cursor: "pointer" }}
                href="https://homesage.ai/terms-of-service"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Terms of Use{" "}
              </a>
              and
              <a
                style={{ color: "var(--brand)", cursor: "pointer" }}
                href="https://homesage.ai/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Privacy Notice
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default CognitoSignUp;
