import ReactApexChart from "react-apexcharts";
import { Box, Typography } from "@mui/material";

const LineChart = ({
  labels,
  title,
  series,
  height = 300,
  legend = {},
  stroke,
  colors,
}) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      sx={{
        height,
        width: "70%",
        padding: 3,
        borderRadius: "12px",
        background: "#FFF",
      }}
    >
      <Typography fontWeight={700} fontSize={18}>
        {title}
      </Typography>
      <Box height={"100%"}>
        <ReactApexChart
          type={"line"}
          options={{
            colors,
            dataLabels: {
              enabled: false,
            },
            stroke,
            markers: { size: 4 },
            xaxis: { categories: labels },
            legend,
          }}
          series={series}
          height={"100%"}
        />
      </Box>
    </Box>
  );
};

export default LineChart;
