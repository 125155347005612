import React, { useState } from "react";
import AppModal from "../AppModal";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import { Label } from "../pages/targetedLists/newList";
import { ReportBug } from "../../services/api";
const ReportBugModal = ({ show, handleClose}) => {
    const [text, setText] = useState("");
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [thanksModal, setThanksModal] = useState(false);
    const closeModal = () => {
        handleClose();
        resetForm();
    };
    const resetForm = () => {
        setText("");
        setFile(null);
        setError("");
    };
    const handleReportBug = async () => {
        setLoading(true);
        if ((text === "")&&(file === null)) {
            setLoading(false);
            setError("Either description or attachment is required");
            return;
        }
        const formData = new FormData();
        formData.append("report", text);
        if (file) formData.append("attachment", file);
        try{
            const res = await ReportBug(formData);
            if (res.status === 201) {
                setLoading(false);
                handleClose();
                setThanksModal(true);
                resetForm();
            }
            else{
                setError("Something went wrong");
                setLoading(false);
            }
        }
        catch(err){
            setError("Something went wrong");
            setLoading(false);
        }
        
    };
    return (
        <>
            <AppModal
                open={show}
                title={"Report Bug"}
                handleClose={closeModal}
                maxWidth="md"
                closeIcon
            >
                <Stack gap={3}>
                    <Stack gap={1.5}>
                        {error && (
                            <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: "#FF0000",
                            }}
                            >
                            {error}
                            </Typography>
                        )}
                    </Stack>
                <Stack gap={1.5}>
                    <Label label={"Please describe the issue"} required />
                    <textarea name="text" id="text" cols="30" rows="10"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    style={{
                        fontSize: 14,
                        border: 0,
                        padding: "14px",
                        borderRadius: "12px",
                        bgcolor: "#F7F7F7",
                    }}></textarea>
                </Stack>
                <Stack gap={1.5}>
                    <Label label={"Add/or upload a screenshot"} />
                    <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: "12px",
                        padding: "14px",
                    }}
                    >
                        <Typography
                            sx={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#4E4E4E",
                            }}
                        >
                            {file?.name}
                        </Typography>
                        <Button
                            variant={"outlined"}
                            component={"label"}
                            sx={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#4E4E4E",
                            textTransform: "capitalize",
                            }}
                        >
                            Upload
                            <input
                            type="file"
                            hidden
                            onChange={(e) => setFile(e.target.files[0])}
                            />
                        </Button>
                    </Box>
                </Stack>
                <LoadingButton
                    loading={loading}
                    variant={"contained"}
                    sx={{
                    alignSelf: "flex-end",
                    height: 48,
                    bgcolor: "#5177FC",
                    ":hover": {
                        bgcolor: alpha("#5177FC", 0.9),
                    },
                    fontSize: 16,
                    fontWeight: 500,
                    padding: "8px 16px",
                    borderRadius: "8px",
                    textTransform: "capitalize",
                    }}
                    onClick={handleReportBug}
                >
                    Submit
                </LoadingButton>
                </Stack>
            </AppModal>
            <AppModal
                open={thanksModal}
                title={" "}
                handleClose={() => setThanksModal(false)}
                maxWidth="sm"
                closeIcon
            >
                <div style={{
                    paddingLeft: "32px",
                    paddingRight: "32px",
                    paddingTop: "8px",
                    gap: "20px",
                    display: "flex",
                    flexDirection: "column",
                }}>
                <h1 style={{
                    fontSize: "20px",
                    textAlign: "center",
                    fontWeight: "600",
                }}>Thank you for your feedback</h1>
                <p style={{
                    textAlign: "center",
                    lineHeight: "16px",
                    fontWeight: "400",
                    fontSize: "14px",
                }}>
                    By reporting bugs you are helping us to improve the product and provide you a better
                    experience
                </p>
                </div>
            </AppModal>
        </>
        
    );
};
export default ReportBugModal;