import {
    Box,
    Pagination,
    Typography,
    Link
  } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from '@mui/material/Checkbox';

const ContactsTable = ({ rows, page, setPage, pageCount, ShowUpdate, handleOpenUpdateContactModal, selectedIDs, setSelectedIDs, removeBoxPadding,height }) => {
    const isSelected = (id) => selectedIDs.indexOf(id) !== -1;
    const formatPhoneNumber = (phoneNumberString) => {
      let formattedPhoneNumber = phoneNumberString.replace(/\D/g, ''); // Remove all non-numeric characters
      // Format the numbers as US phone number in XXX-XXX-XXXX format
      if (formattedPhoneNumber.length > 3 && formattedPhoneNumber.length <= 6) {
        formattedPhoneNumber = formattedPhoneNumber.replace(/^(\d{3})(\d+)/, '$1-$2');
      } else if (formattedPhoneNumber.length > 6) {
        formattedPhoneNumber = formattedPhoneNumber.replace(/^(\d{3})(\d{3})(\d+)/, '$1-$2-$3');
      }
      return formattedPhoneNumber;
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
          const newSelecteds = rows.map((n) => n.id);
          setSelectedIDs(newSelecteds);
          return;
        }
        setSelectedIDs([]);
    }

    const handleClick = (event, id) => {
        const selectedIndex = selectedIDs.indexOf(id);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selectedIDs, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selectedIDs.slice(1));
        } else if (selectedIndex === selectedIDs.length - 1) {
          newSelected = newSelected.concat(selectedIDs.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selectedIDs.slice(0, selectedIndex),
            selectedIDs.slice(selectedIndex + 1),
          );
        }
        setSelectedIDs(newSelected);
    }

    return (
        <>
        <TableContainer
              component={Paper}
              sx={{
                position: "relative",
          maxHeight: height || "calc(100vh - 250px)",
                overflow: "auto",
                boxShadow: "none",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
                "& .MuiTableHead-root": {
                  bgcolor: "rgba(23, 16, 67, 0.02)",
                  "& .MuiTableCell-root": { fontWeight: 600, fontSize: 14 },
                },
                "& .MuiTableCell-root": {
                  fontWeight: 400,
                  fontSize: 12,
                  color: "#4E4E4E",
                },
              }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={selectedIDs.length > 0 && selectedIDs.length < rows.length}
                        checked={rows.length > 0 && selectedIDs.length === rows.length}
                        onChange={handleSelectAllClick}
                        inputProps={{ 'aria-label': 'select all clients' }}
                        sx={{
                          '&.Mui-checked': {
                            color: '#5177FC', // replace with your selected color
                          },
                          '&.MuiCheckbox-indeterminate': {
                            color: '#5177FC', // replace with your indeterminate color
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>Notes</TableCell>
                  </TableRow>
                </TableHead>
                  <TableBody>
                    {rows?.map((row, idx) => {
                      const isItemSelected = isSelected(row.id);
                      return (
                      <TableRow hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, row.id)}
                            inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${idx}` }}
                            sx={{
                              '&.Mui-checked': {
                                color: '#5177FC', // replace with your selected color
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell>
                            {(ShowUpdate) ? (
                                <Link
                                component="button"
                                variant="body2"
                                onClick={() => handleOpenUpdateContactModal(row)}
                              >
                                {row?.first_name}
                              </Link>
                            ):(
                                <Typography
                            minWidth={150}
                            maxWidth={150}
                            fontSize={14}
                            sx={{ wordBreak: "break-word" }}
                          >
                            {row?.first_name}
                          </Typography>
                            )}
                          
                        </TableCell>
                        <TableCell>
                          <Typography
                            minWidth={150}
                            maxWidth={150}
                            fontSize={14}
                            sx={{ wordBreak: "break-word" }}
                          >
                            {row?.last_name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            minWidth={150}
                            maxWidth={150}
                            fontSize={14}
                            sx={{ wordBreak: "break-word" }}
                          >
                            {formatPhoneNumber(row?.phone)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            minWidth={150}
                            maxWidth={150}
                            fontSize={14}
                            sx={{ wordBreak: "break-word" }}
                          >
                            {row?.email}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            minWidth={150}
                            maxWidth={150}
                            fontSize={14}
                            sx={{ wordBreak: "break-word" }}
                          >
                            {row?.address}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            minWidth={150}
                            maxWidth={150}
                            fontSize={14}
                            sx={{ wordBreak: "break-word" }}
                          >
                            {row?.notes}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )})}
                  </TableBody>
              </Table>
            </TableContainer>
            {(removeBoxPadding) ? (
                <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                bgcolor={"#FFF"}
                sx={{
                paddingTop:"0px",
                paddingBottom:"0px",
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                }}
                py={2}
              >
                <Pagination
                  page={page}
                  shape="rounded"
                  count={pageCount}
                  size={"medium"}
                  onChange={(_event, value) => setPage(value)}
                  sx={{
                    '.Mui-selected': {
                        backgroundColor: '#5177FC !important',
                        color: '#fff !important',
                    },
                  }}
                />
              </Box>
            ):(
                <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                bgcolor={"#FFF"}
                sx={{
                    borderBottomLeftRadius: "12px",
                    borderBottomRightRadius: "12px",
                }}
                py={2}
                >
                <Pagination
                    page={page}
                    shape="rounded"
                    count={pageCount}
                    size={"medium"}
                    onChange={(_event, value) => setPage(value)}
                    sx={{
                        '.Mui-selected': {
                            backgroundColor: '#5177FC !important',
                            color: '#fff !important',
                        },
                    }}
                />
                </Box>
            )}
            
        </>
    );
};

export default ContactsTable;