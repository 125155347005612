import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

const SchoolTable = ({ rows }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        height: 130,
        overflow: "auto",
        boxShadow: "none",
        borderRadius: "12px",
        "& .MuiTableHead-root": {
          bgcolor: "rgba(23, 16, 67, 0.02)",
          "& .MuiTableCell-root": { fontWeight: 600, fontSize: 14 },
        },
        "& .MuiTableCell-root": {
          fontWeight: 400,
          fontSize: 12,
          color: "#4E4E4E",
        },
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Rating</TableCell>
            <TableCell align="center">Name</TableCell>
            <TableCell align="center">Grades</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="center">Students</TableCell>
            <TableCell align="center">Distance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, idx) => (
            <TableRow
              key={idx}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row?.rating ?? "-"}
              </TableCell>
              <TableCell align="center">{row?.name}</TableCell>
              <TableCell align="center">{row?.grades ? row?.grades.join(","): ''}</TableCell>
              <TableCell align="center">{row.funding_type}</TableCell>
              <TableCell align="center">{row.student_count}</TableCell>
              <TableCell align="center">{row.distance_in_miles} mile</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SchoolTable;
