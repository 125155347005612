import React from "react";
import CognitoSettings from "./CognitoSettings";
import { ManageBilling } from "../../../services/api";

const openStripeManagement = async () => {
  const current_url = window.location.href;
  const response = await ManageBilling(current_url);
  window.open(response.data.url, "_self", "noreferrer");
};

const Settings = () => {
  return <CognitoSettings openStripeManagement={openStripeManagement} />;
};

export default Settings;
