import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import * as React from "react";
import { formatPrice } from "../pages/propertyReport";

const PropertyHistoryTable = ({ rows }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        height: 300,
        overflow: "auto",
        boxShadow: "none",
        borderRadius: "12px",
        "& .MuiTableHead-root": {
          bgcolor: "rgba(23, 16, 67, 0.02)",
          "& .MuiTableCell-root": { fontWeight: 600, fontSize: 14 },
        },
        "& .MuiTableCell-root": {
          fontWeight: 400,
          fontSize: 12,
          color: "#4E4E4E",
        },
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Date</TableCell>
            <TableCell align="center">Event</TableCell>
            <TableCell align="center">Price</TableCell>
            <TableCell align="center">Price / SQFT</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, idx) => (
            <TableRow
              key={idx}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {moment(row?.date).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell align="center">{row?.event_name}</TableCell>
              <TableCell align="center">{formatPrice(row?.price)}</TableCell>
              <TableCell align="center">
                {formatPrice(row?.price_per_sqft)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PropertyHistoryTable;
