import { Box } from "@mui/material";
import AppTabs from "../../../AppTabs";
import Contacts from "../contacts";
import Groups from "../groups";

const ClientsAndGroups = () => {
  return (
    <Box sx={{marginLeft:"2rem"}}>
      <AppTabs
        sx={{
          "& .MuiTabs-flexContainer": {
            borderBottom: 0,
          },
        }}
        tabs={[
          { label: "Clients", component: <Contacts /> },
          { label: "Groups", component: <Groups /> },
        ]}
      />
    </Box>
  );
};

export default ClientsAndGroups;
