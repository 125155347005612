import React from "react";
import { PageLayout } from "../../page-layout";
import { PageLoader } from "../../page-loader";
import { useCognitoUser } from "../../../CognitoProvider";
import ChangePassword from "./ChangePassword";
import { Grid } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import UpdateUserInfoComponent from "./AccountSettings";
function CognitoSettings(props) {
  const navigate = useNavigate();
  const {
    user: cognitoUser,
    isAuthenticated: IsCognitoUserAuthenticated,
    isLoading: isCognitoUserLoading,
  } = useCognitoUser();
  const { currentPlan } = useCognitoUser();
  const { openStripeManagement } = props;
  console.log("currentPlan", currentPlan);
  let isLoading = isCognitoUserLoading,
    isAuthenticated = IsCognitoUserAuthenticated,
    user = cognitoUser;

  if (isLoading) {
    return <PageLoader />;
  }
  return (
    isAuthenticated && (
      <PageLayout>
        <div className="padding-sides" >
          <Grid container>
            <Grid item md={4}>
              <div>
                <h3 style={{fontSize:"24px",fontWeight:800}} >Subscription</h3>
                <h5 style={{fontSize:"18px",marginTop:"4rem"}}>Current Plan</h5>
                <h4 style={{fontSize:"24px",fontWeight:800}}>{currentPlan?.plan_info ? currentPlan?.plan_info?.title : "Free"} {currentPlan?.plan_info ? "("+currentPlan?.plan_info?.type+")" : ""}</h4>
                <div>
                  <button className="button__brand" onClick={()=>{navigate('/subscription');}}>
                    Change Plan
                  </button>
                </div>
                <div style={{marginTop:"2rem"}}>
                  <span 
                  style={{
                    fontSize:"18px",
                    color:"#5177FC",
                    fontWeight:800,
                    cursor:"pointer"
                  }} 
                   onClick={openStripeManagement} >
                    Go to Billing
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item md={4}>
              <div>
                <UpdateUserInfoComponent />
              </div>
            </Grid>
            <Grid item md={4}>
              <div>
                <ChangePassword />
              </div>
            </Grid>
          </Grid>
        </div>
      </PageLayout>
    )
  );
}

export default CognitoSettings;
