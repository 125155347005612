import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { PageLoader } from "../../page-loader";
import { useCognitoUser } from "../../../CognitoProvider";
import {
  DeleteKey,
  GenerateKey,
  GetKeys,
  RevealKey,
} from "../../../services/api";
import { AuthenticationGuard } from "../../authentication-guard";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    borderRadius: 7,
    border: 0,
    padding: "32px 32px 0px 32px",
    minHeight: "150px",
    boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.25)",
  },
};

const CognitoKeys = () => {
  const { isLoading: isCognitoUserLoading, isAuthenticated } = useCognitoUser();
  const [APIKeys, setAPIKeys] = useState([]);
  const [APIKeyIdx, setAPIKeyIdx] = useState(1);
  const [APIKey, setAPIKey] = useState("");
  const [APIKeyId, setAPIKeyId] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [copied, setCopied] = useState({ key: "", value: false });

  let isLoading = isCognitoUserLoading;

  Modal.setAppElement("#root");

  async function fetchKeys() {
    const response = await GetKeys();
    setAPIKeys(response.data.keys);
  }

  async function createKey() {
    const response = await GenerateKey();
    setAPIKey(response.data.token);
    setAPIKeyIdx(APIKeys.length + 1);
    openModal();
    await fetchKeys();
  }

  async function revealKey(key, idx) {
    const response = await RevealKey(key);
    setAPIKeyIdx(idx);
    setAPIKey(response.data.token);
    openModal();
  }

  async function handleDeleteKey() {
    await DeleteKey(APIKeyId);
    await fetchKeys();
    closeDeleteModal();
  }

  async function handleCopyToClipboard(key) {
    const response = await RevealKey(key);
    handleCopied(key);
    navigator.clipboard.writeText(response.data.token);
  }

  function handleCopied(key) {
    setCopied({ key, value: true });
    setTimeout(() => {
      setCopied({ key: "", value: false });
    }, 2000);
  }
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openDeleteModal(id) {
    setAPIKeyId(id);
    setDeleteModal(true);
  }

  function closeDeleteModal() {
    setDeleteModal(false);
  }

  useEffect(() => {
    fetchKeys();
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <AuthenticationGuard title={"to start using API Keys"}>
      <div  className="padding-sides">
        <h2 className="text-black">Keys</h2>
        <h5 className="primary-text">
          To start using our products, you need an
          <span className="bolder-text"> API key</span>. An API key is a unique
          identifier that allows you to access our APIs.
        </h5>

        <h5 className="primary-text" style={{ marginBottom: 0 }}>
          <span className="bolder-text">If you are not a developer</span>, you can
          generate an API key and provide it to your developer. With that key,
          your developer will be able to implement our products to your website.
        </h5>

        <h5 className="primary-text" style={{ marginTop: 6, marginBottom: 0 }}>
          <span className="bolder-text">If you don't have a developer</span>,
          contact us to help you incorporating our products on your website.
        </h5>

        <button
          className="button__primary"
          onClick={createKey}
          disabled={!isAuthenticated}
          style={{ marginBlock: 42, display: "block" }}
        >
          Generate API Key
        </button>

        <span className="primary-text bolder-text">API key</span>

        {APIKeys.length ? (
          <div style={{ marginBlock: 32, display: "block" }}>
            {APIKeys.map((key, idx) => (
              <div key={idx} className="keyItem">
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 8,
                    fontSize: 14,
                  }}
                >
                  Key <span>{idx + 1}</span>
                </span>
                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    display: "flex",
                    gap: 12,
                  }}
                >
                  <img
                    src={"/assets/eye-visible.svg"}
                    alt="reveal"
                    width={18}
                    height={18}
                    style={{ cursor: "pointer" }}
                    onClick={() => revealKey(key.id, idx + 1)}
                  />

                  <div className="tooltip">
                    {copied.key === key.id && copied.value && (
                      <span className="tooltiptext" style={{ left: "-40px" }}>
                        Copied to clipboard
                      </span>
                    )}
                    <img
                      src={"/assets/copy.svg"}
                      alt="copy"
                      width={18}
                      height={18}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleCopyToClipboard(key.id)}
                    />
                  </div>

                  <img
                    src={"/assets/delete.svg"}
                    alt="delete"
                    width={18}
                    height={18}
                    style={{ cursor: "pointer" }}
                    onClick={() => openDeleteModal(key.id)}
                  />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <span
            className="primary-text"
            style={{ marginBlock: 32, marginLeft: 16, display: "block" }}
          >
            You don't have any API keys.
          </span>
        )}

        <span className="primary-text">
          To start using our APIs, follow these steps:
        </span>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 16,
            marginBlock: 32,
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: 32 }}>
            <span className="bullet-number">1</span>
            <span className="primary-text">
              <span className="bolder-text">Read API documentation</span> to learn
              what are the parameters for the API request.
            </span>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: 32 }}>
            <span className="bullet-number">2</span>
            <span className="primary-text">
              <span className="bolder-text">
                Insert the API key in the header.
              </span>{" "}
              This will vary depending on the API and the programming language you
              are using. However, it is typically included in the header of the
              HTTP request.
            </span>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: 32 }}>
            <span className="bullet-number">3</span>
            <span className="primary-text">
              <span className="bolder-text">
                Submit the API call and get results.
              </span>{" "}
              This can be done using a variety of tools, such as a web browser, a
              command-line tool, or a programming language library.
            </span>
          </div>
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className={"apiDialog"}
          contentLabel="API Key"
        >
          <span
            className="primary-text bolder-text"
            style={{
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={closeModal}
          >
            X
          </span>
          <span className="primary-text">Key {APIKeyIdx}:</span>
          <div className="keyWrapper" style={{ marginBottom: "auto" }}>
            <pre>{APIKey.slice(0)}</pre>
          </div>

          <div className="tooltip">
            {copied.key === "mCopy" && copied.value && (
              <span className="tooltiptext" style={{ left: -11 }}>
                Copied to clipboard
              </span>
            )}
            <button
              className="button__primary"
              style={{ width: "100px" }}
              onClick={() => {
                navigator.clipboard.writeText(APIKey);
                handleCopied("mCopy");
              }}
            >
              Copy
            </button>
          </div>
        </Modal>

        <Modal
          isOpen={deleteModal}
          onRequestClose={closeDeleteModal}
          style={customStyles}
          contentLabel="API Key"
        >
          <span
            className="primary-text bolder-text"
            style={{
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={closeDeleteModal}
          >
            X
          </span>
          <span className="primary-text" style={{ marginTop: 6 }}>
            Are you sure that you want to delete this key?
          </span>
          <div
            style={{
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 6,
            }}
          >
            <button
              className="button__primary"
              style={{ backgroundColor: "red", borderColor: "transparent" }}
              onClick={handleDeleteKey}
            >
              Confirm
            </button>
            <button className="button__primary" onClick={closeDeleteModal}>
              Close
            </button>
          </div>
        </Modal>
      </div>
    </AuthenticationGuard>
  );
};

export default CognitoKeys;
