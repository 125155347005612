import React, { useEffect } from "react";
import { useCognitoUser } from "../../../CognitoProvider";
import { useNavigate } from "react-router-dom";
const CognitoRedirection = () => {
  const navigate = useNavigate();
  const { isAuthenticated, isSubscribed } = useCognitoUser();
  useEffect(() => {
    const redirect = () => {
      if (isAuthenticated && isSubscribed)
        setTimeout(() => {
          navigate("/products", { replace: true });
        }, 2000);
      else
        setTimeout(() => {
          navigate("/", { replace: true });
        }, 2000);
    };

    redirect();
  }, [isAuthenticated, isSubscribed, navigate]);

  return <div>Redirecting to sign-in...</div>;
};

export default CognitoRedirection;
