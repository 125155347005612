import { Box, FormHelperText, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const Item = ({ tag, selected, onClick }) => {
  return (
    <Box
      border={1}
      borderColor={selected ? "#5177FC" : "#E9E9E9"}
      borderRadius={"8px"}
      minWidth={"100px"}
      padding={"8px 12px"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      gap={1}
      sx={{ cursor: !selected && "pointer" }}
      onClick={() => !selected && onClick()}
    >
      <Typography
        textAlign={"center"}
        fontSize={16}
        fontWeight={500}
        sx={{ p: 0, m: 0, color: selected ? "#5177FC" : "rgba(0, 0, 0, 0.40)" }}
      >
        {tag}
      </Typography>
      {selected && (
        <img
          alt="remove"
          src={"/assets/remove-circle-selected.svg"}
          onClick={onClick}
          style={{ cursor: "pointer" }}
        />
      )}
    </Box>
  );
};

const Tags = ({ values, items, onChange, error }) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const isItemInArray = (item) => selectedItems?.includes(item);
  const addItemInArray = (item) => {
    const updatedItems = [...selectedItems, item];
    setSelectedItems(updatedItems);
    return updatedItems;
  };
  const removeItemFromArray = (item) => {
    const updatedItems = selectedItems.filter((sItem) => sItem !== item);
    setSelectedItems(updatedItems);
    return updatedItems;
  };

  const onHandleChangeItems = (item) => {
    let items;
    if (isItemInArray(item)) {
      items = removeItemFromArray(item);
    } else {
      items = addItemInArray(item);
    }
    onChange && onChange(items);
  };

  useEffect(() => {
    if (values) {
      setSelectedItems(values);
    }
  }, [values]);

  return (
    <Grid container spacing={1.5}>
      <Grid container spacing={1.5} style={{ margin: "0.5rem" }}>
        {items.map((item) => (
          <Grid item key={item.name}>
            <Item
              tag={item.name}
              selected={isItemInArray(item.name)}
              onClick={() => onHandleChangeItems(item.name)}
            />
          </Grid>
        ))}
        
      </Grid>
      <Grid container spacing={1.5} style={{ margin: "0.5rem" }}>
      <FormHelperText variant="outlined" >
        <Typography
          component={"span"}
          variant="caption"
          sx={{
            ml: 1.5,
            opacity: error ? 1 : 0,
            color: "red",
            transition: "all ease-out 200ms",
          }}
        >
          {error}
        </Typography>
      </FormHelperText>
      </Grid>
    </Grid>
  );
};

export default Tags;
