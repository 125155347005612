import {
  Box,
  CircularProgress,
  Typography,
  circularProgressClasses,
} from "@mui/material";

const ProgressBar = (props) => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: props?.barcolor ? props?.barcolor : "#5177FC",
          opacity: 0.1,
        }}
        size={80}
        thickness={6}
        {...props}
        value={100}
      />
      
      <CircularProgress
        size={80}
        thickness={6}
        variant="determinate"
        {...props}
        sx={{
          color: props?.barcolor ? props?.barcolor : "#5177FC",
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          fontWeight={500}
          fontSize={14}
          sx={{ color: "#000" }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProgressBar;
