import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Button, Typography, alpha } from "@mui/material";
import { axiosInstance } from "../../../services/api";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";
import axios from "axios";

const Iframe = ({
  backgroundBgcolor,
  autocompleteHeight,
  autocompleteWidth,
  autocompleteFontSize,
  autocompleteFontWeight,
  autocompleteFontColor,
  autocompleteBgcolor,
  buttonHeight,
  buttonWidth,
  buttonFontSize,
  buttonFontWeight,
  buttonFontColor,
  buttonBgcolor,
  isCustomize,
}) => {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [text, setText] = React.useState("");
  const [error, setError] = React.useState("");
  const [settings, setSettings] = React.useState({
    backgroundBgcolor: "#FFF",
    autocompleteHeight: 48,
    autocompleteWidth: 410,
    autocompleteFontSize: 14,
    autocompleteFontWeight: 400,
    autocompleteFontColor: "#202124",
    autocompleteBgcolor: "#F7F7F7",
    buttonHeight: 48,
    buttonWidth: 127,
    buttonFontSize: 14,
    buttonFontWeight: 500,
    buttonFontColor: "#FFFFFF",
    buttonBgcolor: "#5177FC",

    backendUrl: "",
    token: "",
    tokenKey: "",
    tokenValue: "",
  });

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const search = location.search.substring(1);

    if (
      backgroundBgcolor &&
      autocompleteHeight &&
      autocompleteWidth &&
      autocompleteFontSize &&
      autocompleteFontWeight &&
      autocompleteFontColor &&
      autocompleteBgcolor &&
      buttonHeight &&
      buttonWidth &&
      buttonFontSize &&
      buttonFontWeight &&
      buttonFontColor &&
      buttonBgcolor
    ) {
      setSettings({
        backgroundBgcolor,
        autocompleteHeight,
        autocompleteWidth,
        autocompleteFontSize,
        autocompleteFontWeight,
        autocompleteFontColor,
        autocompleteBgcolor,
        buttonHeight,
        buttonWidth,
        buttonFontSize,
        buttonFontWeight,
        buttonFontColor,
        buttonBgcolor,
      });
    } else if (search) {
      setSettings(
        JSON.parse(
          '{"' +
            decodeURI(search)
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/%23/g, "#")
              .replace(/%3A/g, ":")
              .replace(/%2F/g, "/")
              .replace(/=/g, '":"') +
            '"}'
        )
      );
    }
  }, [
    backgroundBgcolor,
    autocompleteHeight,
    autocompleteWidth,
    autocompleteFontSize,
    autocompleteFontWeight,
    autocompleteFontColor,
    autocompleteBgcolor,
    buttonHeight,
    buttonWidth,
    buttonFontSize,
    buttonFontWeight,
    buttonFontColor,
    buttonBgcolor,
    location.search,
  ]);

  React.useEffect(() => {
    if (text) {
      (async () => {
        setLoading(true);
        try {
          const response = await axiosInstance.get(
            `/properties/auto-complete/?input=${text}`
          );
          setOptions(response.data?.data);
        } catch (error) {}
        setLoading(false);
      })();
    }
  }, [text]);

  const handleGenerateReport = async () => {
    if (!selectedOption) return setError("Please select a property first");
    setError("");

    // // FIXME: for Testing purposes
    // try {
    //   const response = await axios.get(
    //     `${settings.backendUrl}/?address=${JSON.stringify(selectedOption)}`,
    //     {
    //       headers: settings.tokenKey
    //         ? { [settings.tokenKey]: `Bearer ${settings.tokenValue}` }
    //         : { Authorization: `Bearer ${settings.token}` },
    //     }
    //   );
    //   window.open(
    //     `${process.env.REACT_APP_DEV_PORTAL}/report?token=${response.data.token}`
    //   );
    // } catch (error) {
    //   console.log(error);
    // }

    if (isCustomize || !settings.backendUrl) return;

    try {
      const response = await axios.post(
        settings.backendUrl,
        { address: JSON.stringify(selectedOption) },
        {
          headers: settings.tokenKey
            ? { [settings.tokenKey]: `${settings.tokenValue}` }
            : { Authorization: `Bearer ${settings.token}` },
        }
      );
      window.open(
        `${process.env.REACT_APP_DEV_PORTAL}/report?token=${response.data.token}`
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isCustomize ||
      (settings.backendUrl &&
        (settings.token || (settings.tokenKey && settings.tokenValue))) ? (
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={3}
          borderRadius={"12px"}
          width={"max-content"}
          p={"16px"}
          sx={{
            bgcolor: settings.backgroundBgcolor,
            "& .MuiInputBase-input": {
              height: Number(settings.autocompleteHeight) - 33,
            },
            "& .MuiFormHelperText-root": {
              color: "red",
              fontSize: settings.autocompleteFontSize - 4,
            },
            "& .MuiOutlinedInput-root": {
              outline: 0,
              "&.Mui-focused fieldset": {
                borderColor: alpha(settings.autocompleteBgcolor, 0.9),
                borderWidth: "2px",
              },
            },
          }}
        >
          <Autocomplete
            sx={{ width: Number(settings.autocompleteWidth) }}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            componentsProps={{
              paper: {
                sx: { bgcolor: alpha(settings.autocompleteBgcolor, 1) },
              },
            }}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            getOptionLabel={(option) => option.text}
            options={options}
            loading={loading}
            onChange={(_, v) => setSelectedOption(v)}
            noOptionsText={
              <Typography
                display={"flex"}
                textAlign={"center"}
                gap={1}
                fontSize={14}
                justifyContent={"center"}
                alignItems={"center"}
              >
                No Data found
              </Typography>
            }
            loadingText={
              <Typography
                display={"flex"}
                textAlign={"center"}
                gap={1}
                fontSize={14}
                justifyContent={"center"}
                alignItems={"center"}
              >
                Loading...
              </Typography>
            }
            onInputChange={debounce((_, v) => setText(v), 500)}
            renderOption={(props, option, { selected }) => {
              const newProps = { ...props, key: option.id };
              return (
                <li
                  {...newProps}
                  style={{
                    fontSize: 14,
                    color: autocompleteFontColor,
                  }}
                >
                  {option.text}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Property..."
                title="Search Property..."
                helperText={error}
                InputProps={{
                  ...params.InputProps,
                  style: { fontSize: Number(settings.autocompleteFontSize) },
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                InputLabelProps={{
                  style: {
                    fontSize: Number(settings.autocompleteFontSize),
                    color: settings.autocompleteFontColor,
                    fontWeight: Number(settings.autocompleteFontWeight),
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: alpha(settings.autocompleteBgcolor, 0.9),
                    fontSize: Number(settings.autocompleteFontSize),
                    fontWeight: 700,
                  },

                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: `${alpha(
                      settings.autocompleteBgcolor,
                      0.9
                    )} !important`,
                  },

                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    bgcolor: settings.autocompleteBgcolor,
                  },
                }}
              />
            )}
          />
          <Button
            variant="contained"
            onClick={handleGenerateReport}
            sx={{
              color: settings.buttonFontColor,
              bgcolor: settings.buttonBgcolor,
              height: Number(settings.buttonHeight),
              minHeight: Number(settings.buttonHeight),
              maxHeight: Number(settings.buttonHeight),
              width: Number(settings.buttonWidth),
              minWidth: Number(settings.buttonWidth),
              maxWidth: Number(settings.buttonWidth),
              fontSize: Number(settings.buttonFontSize),
              fontWeight: Number(settings.buttonFontWeight),
              paddingX: "12px",
              paddingY: "8px",
              borderRadius: "8px",
              textTransform: "capitalize",
              ":hover": { bgcolor: alpha(settings.buttonBgcolor, 0.9) },
              alignSelf: "flex-end",
            }}
          >
            Generate
          </Button>
        </Box>
      ) : (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100vw"}
          height={"100vh"}
          m={"auto"}
        >
          <Typography fontWeight={700}>Invalid URL or Token</Typography>
        </Box>
      )}
    </>
  );
};

export default Iframe;
