import {
  Box,
  Pagination,
  Stack,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useState } from "react";
import moment from "moment";
import AppModal from "../AppModal";
import { Label } from "../pages/targetedLists/newList";
import LoadingButton from "@mui/lab/LoadingButton";
import { UpdateDownloadTargetedMarketingList } from "../../services/api";
import { isArray } from "lodash";

const DownloadedListsTable = ({ rows, page, pageCount, setPage, refetch }) => {
  const [updateNameModal, setUpdateNameModal] = React.useState(false);
  const [name, setName] = React.useState("");
  const [id, setId] = React.useState("");
  const [nameLoading, setNameLoading] = React.useState(false);
  const [copied, setCopied] = useState({ key: "", value: false });
  const handleUpdateNameOpen = () => setUpdateNameModal(true);
  const handleUpdateNameClose = () => setUpdateNameModal(false);

  const handleUpdateName = async () => {
    if (!id) return;
    setNameLoading(true);
    try {
      const formData = new FormData();
      formData.append("download_id", id);
      formData.append("name", name);
      await UpdateDownloadTargetedMarketingList(formData);
      handleUpdateNameClose();
      refetch();
    } catch (error) {
    } finally {
      setNameLoading(false);
    }
  };
  
  function handleCopied(id, key) {
      navigator.clipboard.writeText(key);
      setCopied({ id, value: true });
      setTimeout(() => {
        setCopied({ key: "", value: false });
      }, 2000);
    }
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          position: "relative",
          maxHeight: "calc(100vh - 250px)",
          overflow: "auto",
          boxShadow: "none",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          "& .MuiTableHead-root": {
            bgcolor: "rgba(23, 16, 67, 0.02)",
            "& .MuiTableCell-root": { fontWeight: 600, fontSize: 14 },
          },
          "& .MuiTableCell-root": {
            fontWeight: 400,
            fontSize: 12,
            color: "#4E4E4E",
          },
        }}
      >
        <Table stickyHeader >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Filters</TableCell>
              <TableCell>Downloaded Date</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          {rows.length ? (
            <TableBody>
              {rows?.map((row, idx) => (
                <TableRow key={idx}>
                  <TableCell>
                    <Typography
                      minWidth={200}
                      maxWidth={200}
                      fontSize={14}
                      sx={{ wordBreak: "break-word" }}
                    >
                      {row?.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Stack minWidth={200}>
                      {row.filters
                        ?.filter((filter) => !!filter.value)
                        ?.map((filter, idx) => (
                          <Typography
                            key={idx}
                            fontSize={14}
                            color={"rgba(23, 16, 67, 0.88)"}
                            component={"span"}
                          >
                            <Typography
                              component={"span"}
                              fontSize={14}
                              color={"rgba(23, 16, 67, 0.88)"}
                              fontWeight={700}
                            >
                              {filter.label}:
                            </Typography>{" "}
                            {typeof filter.value === "object" &&
                            !isArray(filter.value)
                              ? filter.value.text
                              : typeof filter.value === "string"
                              ? filter.value
                              : isArray(filter.value) &&
                                filter.label !== "Location"
                              ? filter.value.join(" | ")
                              : isArray(filter.value)
                              ? filter.value
                                  .map((location) => location.text)
                                  .join(" | ")
                              : filter.value}
                          </Typography>
                        ))}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Typography fontSize={14} noWrap>
                      {moment(row?.download_date).format("DD MMM YYYY HH:mm A")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      gap={2}
                    >
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        height={32}
                        width={32}
                        onClick={() => window.open(row.url)}
                        sx={{
                          cursor: "pointer",
                          borderRadius: "6px",
                          border: "1px solid #171043",
                          boxShadow: " 0px 2px 0px 0px rgba(23, 16, 67, 0.02)",
                        }}
                      >
                        <img
                          src={"/assets/action-download.svg"}
                          alt={"download"}
                          style={{
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </Box>
                      
                      <div className="tooltip">
                        {copied.id === row.id && copied.value && (
                          <span className="tooltiptext" style={{ left: "-40px" }}>
                            Copied to clipboard
                          </span>
                        )}
                        <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        height={32}
                        width={32}
                        onClick={() => handleCopied(row.id,row.url)}
                        sx={{
                          cursor: "pointer",
                          borderRadius: "6px",
                          border: "1px solid #171043",
                          boxShadow: " 0px 2px 0px 0px rgba(23, 16, 67, 0.02)",
                        }}
                      >
                        <img
                          src={"/assets/copy.png"}
                          alt={"copy"}
                          style={{
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </Box>
                      </div>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        height={32}
                        width={32}
                        onClick={() => {
                          setName(row?.name);
                          setId(row?.id);
                          handleUpdateNameOpen();
                        }}
                        sx={{
                          cursor: "pointer",
                          borderRadius: "6px",
                          border: "1px solid #171043",
                          boxShadow: " 0px 2px 0px 0px rgba(23, 16, 67, 0.02)",
                        }}
                      >
                        <img
                          src={"/assets/action-edit.svg"}
                          alt={"edit"}
                          style={{
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <Typography
              position={"absolute"}
              fontSize={14}
              top={"50%"}
              left={"50%"}
              sx={{ transform: "translate(-50%)" }}
            >
              No Data found!
            </Typography>
          )}
        </Table>

        <AppModal
          open={updateNameModal}
          title={"Update Name"}
          handleClose={handleUpdateNameClose}
          maxWidth="xs"
          closeIcon
        >
          <Stack gap={3}>
            <Stack gap={1.5}>
              <Label label={"Name"} required />
              <TextField
                fullWidth
                label={"Name"}
                title={"Name"}
                inputProps={{ style: { fontSize: 14 } }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                type={"text"}
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    fontSize: 14,
                    border: 0,
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "14px",
                  },
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                    bgcolor: "#F7F7F7",
                  },
                }}
              />
            </Stack>
            <LoadingButton
              loading={nameLoading}
              variant={"contained"}
              sx={{
                alignSelf: "flex-end",
                height: 48,
                bgcolor: "#5177FC",
                ":hover": {
                  bgcolor: alpha("#5177FC", 0.9),
                },
                fontSize: 16,
                fontWeight: 500,
                padding: "8px 16px",
                borderRadius: "8px",
                textTransform: "capitalize",
              }}
              onClick={handleUpdateName}
            >
              Save
            </LoadingButton>
          </Stack>
        </AppModal>
      </TableContainer>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        bgcolor={"#FFF"}
        sx={{
          borderBottomLeftRadius: "12px",
          borderBottomRightRadius: "12px",
        }}
        py={2}
      >
        <Pagination
          page={page}
          shape="rounded"
          count={pageCount}
          size={"medium"}
          onChange={(_event, value) => setPage(value)}
          sx={{
            '.Mui-selected': {
                backgroundColor: '#5177FC !important',
                color: '#fff !important',
            },
          }}
        />
      </Box>
    </>
  );
};

export default DownloadedListsTable;
