import { Amplify, Auth } from "aws-amplify";
import Cookies from "js-cookie";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  GetUserSubscription,
  RegisterUser,
  SubscribeFreeTrial,
  axiosInstance,
} from "./services/api";

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_REGION,
    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: process.env.REACT_APP_IDENTITY_POOL_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: process.env.REACT_APP_REDIRECT_SIGNIN,
      redirectSignOut: process.env.REACT_APP_REDIRECT_SIGNOUT,
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});

const setToken = (idToken) => {
  Cookies.remove("id_token");
  axiosInstance.defaults.headers.common.Authorization = idToken;
  Cookies.set("id_token", idToken);
};

const removeToken = () => {
  delete axiosInstance.defaults.headers.common.Authorization;
  Cookies.remove("id_token");
};

const UserContext = createContext({});

export const CognitoProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);

  const [userLoaded, setUserLoaded] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [loading, setLoading] = useState(false);
  //const location = useLocation();
  //const queryParams = new URLSearchParams(location.search);
  //const paramExists = queryParams.has('free-trial');

  const signIn = useCallback(async (payload) => {
    try {
      removeToken();
      setLoading(true);
      const cognitoUser = await Auth.signIn(payload);
      const idToken = cognitoUser.signInUserSession.idToken.jwtToken;
      setToken(idToken);
      const current_url = window.location.href;
      console.log(current_url);
      //check if free-trial in current url
      const paramExists = current_url.includes('free-trial');
      console.log(paramExists);
      if (paramExists) {
        try{
          await SubscribeFreeTrial();
          setTimeout(async () => {
            setUser(cognitoUser?.attributes);
            const response = await GetUserSubscription();
            setSubscribed(response.data.is_subscribed);
            setLoading(false);
            setUserLoaded(true);
            return cognitoUser;
          }, 2000);
        }
        catch(error){
          setUser(cognitoUser?.attributes);
          setLoading(false);
          setUserLoaded(true);
          return cognitoUser;
        }
        
      }
      else{
        setUser(cognitoUser?.attributes);
        setLoading(false);
        setUserLoaded(true);
        return cognitoUser;
      }
      
    } catch (error) {
      setLoading(false);
      removeToken();
      setUser(null);
      throw Error(error);
    }
  }, []);

  const signUp = useCallback(
    async (payload) => {
      try {
        setLoading(true);
        const registeredUser = await Auth.signUp({
          ...payload,
          autoSignIn: {
            // optional - enables auto sign in after user is confirmed
            enabled: true,
          },
        });
        const current_url = window.location.href;
        console.log(current_url);
        //check if free-trial in current url
        const paramExists = current_url.includes('free-trial');
        console.log(paramExists);
        if (paramExists) {
          await RegisterUser({
            email: payload.username,
            firstname: payload.given_name,
            lastname: payload.family_name,
            cognito_username: registeredUser.userSub,
            free_trial: true,
          });
        }else{
          await RegisterUser({
            email: payload.username,
            firstname: payload.given_name,
            lastname: payload.family_name,
            cognito_username: registeredUser.userSub,
          });
        }
        
        const user = await signIn(
          {
            username: payload.username,
            password: payload.password,
          },
          true
        );
        setUserLoaded(true);
        setLoading(false);
        return user;
      } catch (error) {
        setLoading(false);
        throw Error(error);
      }
    },
    [signIn]
  );

  const signOut = useCallback(async () => {
    await Auth.signOut();
    removeToken();
    setUser(null);
    //go to sign in page
    window.open(process.env.REACT_APP_REDIRECT_SIGNOUT, "_self");
  }, []);

  const changePassword = useCallback(async (payload) => {
    try {
      await Auth.currentAuthenticatedUser().then((user) => {
        return Auth.changePassword(user, payload.oldPassword, payload.newPassword);
      });
    } catch (error) {
      throw Error(error);
    }
  }
  , []);

  const UpdateUser = useCallback(async (payload) => {
    try {
      await Auth.currentAuthenticatedUser().then((user) => {
        return Auth.updateUserAttributes(user, payload);
      });
    } catch (error) {
      throw Error(error);
    }
  }
  , []);

  const getUser = useCallback(async () => {
    let exists = false;
    try {
      setLoading(true);
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const idToken = cognitoUser.signInUserSession.idToken.jwtToken;
      setToken(idToken);
      setUser(cognitoUser?.attributes);
      exists = true;
      setLoading(false);
    } catch (error) {
      removeToken();
      setLoading(false);
      setUser(null);
    } finally {
      if (!exists) setUserLoaded(true);
    }
  }, []);
  const getCognitoUser = useCallback(async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      console.log(cognitoUser);
      console.log(cognitoUser?.attributes);
      return cognitoUser?.attributes;
    } catch (error) {
      throw Error(error);
    }
  }, []);
  const getUserSubscription = useCallback(async () => {
    try {
      setLoading(true);
      const response = await GetUserSubscription();
      setSubscribed(response.data.is_subscribed);
      setCurrentPlan(response.data)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSubscribed(false);
    } finally {
      setUserLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (!!user?.email && !!Cookies.get("id_token")) getUserSubscription();
    getUser();
  }, [getUser, getUserSubscription, user?.email]);

  return (
    <UserContext.Provider
      value={{
        isAuthenticated: useMemo(() => !!user?.email, [user?.email]),
        isLoading: loading,
        isSubscribed: useMemo(() => !!subscribed, [subscribed]),
        userLoaded: useMemo(() => !!userLoaded, [userLoaded]),
        currentPlan: currentPlan,
        user,
        signUp,
        signIn,
        signOut,
        getUserSubscription,
        changePassword,
        UpdateUser,
        getUser,
        getCognitoUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useCognitoUser = () => useContext(UserContext);
