import { Box, Stack, Typography } from "@mui/material";

const PropertyFeatureField = ({ title, value, icon }) => {
  return (
    <Stack spacing={0.5} minWidth={'150px'}>
      <Typography fontSize={16} fontWeight={500} sx={{ color: "#4E4E4E" }}>
        {title}
      </Typography>
      <Box display={"flex"} alignItems="center" gap={0.5}>
        <img src={icon} alt="feature-icon" />
        <Typography fontSize={20} fontWeight={700} sx={{ color: "#202124" }}>
          {value ? value : "N/A"}
        </Typography>
      </Box>
    </Stack>
  );
};

export default PropertyFeatureField;
