import { Box, Typography } from "@mui/material";
import { useCognitoUser } from "../../CognitoProvider";
import { Link } from "react-router-dom";
import { PageLoader } from "../page-loader";

const SecureRoute = ({ children, text, showSubscrbe }) => {
  const { isAuthenticated, userLoaded } = useCognitoUser();

  if (!userLoaded) return <PageLoader />;
  console.log(showSubscrbe);
  if (showSubscrbe){
    return (
      <Box
        position={"relative"}
        height={"100%"}
        width={"100%"}
        px={showSubscrbe ? 2 : 0}
        overflow={showSubscrbe ? "hidden" : "auto"}
      >
        {showSubscrbe && (
          <Box
            position={"absolute"}
            width={"100%"}
            height={"100%"}
            zIndex={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ backdropFilter: "blur(3px)" }}
            mx={-2}
          >
            <Box
              width={"100%"}
              height={"80px"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={"8px"}
              m={1}
              sx={{
                bgcolor: "#FFF",
                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
              }}
            >
              <Typography fontSize={18} fontWeight={700}>
                <Link to={"/subscription"} style={{ color: "#5177FC" }}>
                  Subscribe
                </Link>{" "}
                {text}
              </Typography>
            </Box>
          </Box>
        )}
        {children}
      </Box>
    );
  }

  return (
    <Box
      position={"relative"}
      height={"100%"}
      width={"100%"}
      px={isAuthenticated ? 0 : 2}
      overflow={isAuthenticated ? "auto" : "hidden"}
    >
      {!isAuthenticated && (
        <Box
          position={"absolute"}
          width={"100%"}
          height={"100%"}
          zIndex={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ backdropFilter: "blur(3px)" }}
          mx={-2}
        >
          <Box
            width={"100%"}
            height={"80px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"8px"}
            m={1}
            sx={{
              bgcolor: "#FFF",
              boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
            }}
          >
            <Typography fontSize={18} fontWeight={700}>
              <Link to={"/signup"} style={{ color: "#5177FC" }}>
                Sign up
              </Link>{" "}
              {text}
            </Typography>
          </Box>
        </Box>
      )}
      {children}
    </Box>
  );
};

export default SecureRoute;
