import React from "react";
import { LoginButton } from "../../buttons/login-button";
import { LogoutButton } from "../../buttons/logout-button";
import { SignupButton } from "../../buttons/signup-button";
import { useCognitoUser } from "../../../CognitoProvider";

export const MobileNavBarButtons = () => {
  const { isAuthenticated: IsCognitoUserAuthenticated } = useCognitoUser();

  let isAuthenticated;

  isAuthenticated = IsCognitoUserAuthenticated;

  return (
    <div className="mobile-nav-bar__buttons">
      {!isAuthenticated &&
        process.env.REACT_APP_AUTH_PROVIDER === "Cognito" && (
          <>
            <SignupButton />
            <LoginButton />
          </>
        )}
      {isAuthenticated && (
        <>
          <LogoutButton />
        </>
      )}
    </div>
  );
};
