import { Stack, Typography } from "@mui/material";
import AutomatedEmailLogsTable from "../../../table/AutomatedEmailLogsTable";
import { useEffect, useState } from "react";
import { GetAutomatedEmailLogs } from "../../../../services/api";
import { useCognitoUser } from "../../../../CognitoProvider";
import { AuthenticationGuard } from "../../../authentication-guard";
import { PageLoader } from "../../../page-loader";
const AutomatedEmailLogs = () => {
  const { userLoaded } = useCognitoUser();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const fetchData = async () => {
    setLoading(true);
    try{
    const response = await GetAutomatedEmailLogs(page);
    setRows(response.data?.logs);
    setPageCount(response.data?.total_pages);
    setPage(response.data?.page);
    }
    catch{
      setRows([]);
      setPageCount(1);
    }
    finally{
    setLoading(false);
    }
  };
  useEffect(() => {
    if (userLoaded) fetchData();
  }, [userLoaded, page]);

  if(loading) return <PageLoader />
  return (
    <AuthenticationGuard title={"to start using Automated Email Logs"}>
      <div className="padding-sides">
        <div style={{backgroundColor:"#F5F5F5",height:"100%",borderRadius:"8px",padding:"10px"}}>
          <AutomatedEmailLogsTable
            rows={rows}
            page={page}
            setPage={setPage}
            pageCount={pageCount}
            refetch={fetchData}
          />
        </div>
      </div>
    </AuthenticationGuard>
  );
};

export default AutomatedEmailLogs;
