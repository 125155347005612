import { Stack, Typography } from "@mui/material";
import DownloadedListsTable from "../../../table/DownloadedListsTable";
import { useEffect, useState } from "react";
import { GetDownloadTargetedMarketingList } from "../../../../services/api";
import { useCognitoUser } from "../../../../CognitoProvider";
import { AuthenticationGuard } from "../../../authentication-guard";
import { PageLoader } from "../../../page-loader";
const DownloadedLists = () => {
  const { userLoaded } = useCognitoUser();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const fetchData = async () => {
    setLoading(true);
    try{
    const response = await GetDownloadTargetedMarketingList(page);
    setRows(response.data?.downloaded_files);
    setPageCount(response.data?.total_pages);
    setPage(response.data?.page);
    }
    catch{
      setRows([]);
      setPageCount(1);
    }
    finally{
    setLoading(false);
    }
  };
  useEffect(() => {
    if (userLoaded) fetchData();
  }, [userLoaded, page]);
  if(loading) return <PageLoader />
  return (
    <AuthenticationGuard title={"to start using Downloaded Lists"}>
      <div className="padding-sides">
        <Stack bgcolor={"#F5F5F5"} height={"100%"} p={3} borderRadius={"8px"}>
          <Typography fontSize={24} fontWeight={600} mb={3}>
            Downloaded Lists
          </Typography>
          <DownloadedListsTable
            rows={rows}
            page={page}
            setPage={setPage}
            pageCount={pageCount}
            refetch={fetchData}
          />
        </Stack>
      </div>
    </AuthenticationGuard>
  );
};

export default DownloadedLists;
