import LoadingButton from "@mui/lab/LoadingButton";
import AppModal from "../../../AppModal";
import {
    Stack,
    TextField,
    alpha,
    Typography,
    FormControl,
    FormHelperText,
} from "@mui/material";
import SearchField from "../components/SearchField";
import {useEffect, useState, useCallback } from "react";
import Grid from '@mui/material/Grid';
import {GetGroupContacts,RemoveGroupContacts,SearchGroupContacts} from "../../../../services/api";
import ContactsTable from "../contacts/ContactsTable";
const UpdateGroupModal = ({ open, onClose, onSave, refetch, group_id, name, notes, setName, setNotes, currentOpenedModal, nameRequired, setNameRequired, page ,setPage ,selectedIDs ,setSelectedIDs }) => {
    const [updateGroupLoading, setUpdateGroupLoading] = useState(false);
    const [removeVontactsLoading, setRemoveVontactsLoading] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [keyword, setKeyword] = useState("");
    const handleSave = async () => {
        setUpdateGroupLoading(true);
        const success = await onSave(group_id, name, notes);
        setUpdateGroupLoading(false);
        console.log("success:"+success);
        if (success) onClose();
    };
    const fetchContacts = useCallback(async () => {
        if(keyword === ""){
          const response = await GetGroupContacts(group_id,page);
          setContacts(response.data?.contacts);
          setPageCount(response.data?.total_pages);
          setPage(response.data?.page);
        }else{
          const response = await SearchGroupContacts(group_id,keyword,page);
          setContacts(response.data?.contacts);
          setPageCount(response.data?.total_pages);
          setPage(response.data?.page);
        }
    }, [group_id,page,keyword, setPage, setPageCount]);

    const removeContacts = async () => {
        setRemoveVontactsLoading(true);
        const formData = new FormData();
        formData.append("group_id", group_id);
        formData.append("contact_ids", JSON.stringify(selectedIDs));
        const res = await RemoveGroupContacts(group_id, formData);
        if (res){
            setRemoveVontactsLoading(false);
            fetchContacts();
            refetch();
            setSelectedIDs([]);
        }
    };
    const executeSearch = async (search) => {
        if (search === ""){
            fetchContacts();
            return;
        }
        const response = await SearchGroupContacts(group_id,search,page);
        setContacts(response.data?.contacts);
        setPageCount(response.data?.total_pages);
        setPage(response.data?.page);
    };
    useEffect(() => {
        console.log("group_id:"+group_id);
        if ((group_id)&&(currentOpenedModal==="updateGroup")) fetchContacts();
    }, [group_id, page, currentOpenedModal, fetchContacts]);




    return (
        <AppModal
          open={open}
          title={"Edit Group"}
          handleClose={onClose}
          maxWidth="xl"
          closeIcon
        >
          <Stack gap={0.5}>
            <Grid container>
              <Grid item md={5} >
                <Stack gap={0.5} paddingTop={"2rem"} paddingBottom={"2rem"}>
                    <FormControl
                        sx={{
                        "& .MuiInputLabel-root": {
                            fontSize: 14,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: 0,
                        },
                        }}
                    >
                        <TextField
                        fullWidth
                        label={"Name"}
                        title={"Name"}
                        required
                        inputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        type={"text"}
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                            if (name !== "") setNameRequired(false);
                        }}
                        sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                            fontSize: 14,
                            border: 0,
                            },
                            "& .MuiOutlinedInput-input": {
                            padding: "14px",
                            },
                            "& .MuiOutlinedInput-root": {
                            borderRadius: "12px",
                            bgcolor: "#F7F7F7",
                            },
                        }}
                        />
                        <FormHelperText variant="outlined">
                        <Typography
                            component={"span"}
                            variant="caption"
                            textTransform={"capitalize"}
                            sx={{
                            opacity: nameRequired ? 1 : 0,
                            color: "red",
                            transition: "all ease-out 200ms",
                            }}
                        >
                            Group Name is Required.
                        </Typography>
                        </FormHelperText>
                    </FormControl>
                </Stack>
              </Grid>
              <Grid item md={2}></Grid>
              <Grid item md={5} >
                <Stack gap={0.5} paddingTop={"2rem"} paddingBottom={"2rem"}>
                  <TextField
                    fullWidth
                    label={"Notes"}
                    title={"Notes"}
                    required
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    type={"text"}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        fontSize: 14,
                        border: 0,
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "14px",
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "12px",
                        bgcolor: "#F7F7F7",
                      },
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container>
            <Grid item md={9}>
            </Grid>
            <Grid item md={3}>
              <SearchField executeSearch={executeSearch} keyword={keyword}
setKeyword={setKeyword} bgColor={"#FFF"} ></SearchField>
            </Grid>
          </Grid>
            <ContactsTable
                rows={contacts}
                page={page}
                setPage={setPage}
                pageCount={pageCount}
                selectedIDs={selectedIDs}
                setSelectedIDs={setSelectedIDs}
                ShowUpdate={false}
                removeBoxPadding={true}
                height={"calc(100vh - 450px)"}
            />
            <Grid container>
                <Grid item md={3}>
                    <LoadingButton
                    loading={removeVontactsLoading}
                    variant={"contained"}
                    disabled={selectedIDs.length === 0}
                    sx={{
                      height: 30,
                      bgcolor: "#fff",
                      ":hover": {
                        bgcolor: alpha("#fff", 0.9),
                      },
                      fontSize: 16,
                      fontWeight: 500,
                      padding: "2px 18px",
                      margin: "1rem",
                      borderRadius: "8px",
                      borderColor:"#EA5455",
                      border:"1px solid",
                      textTransform: "capitalize",
                      color:"#EA5455"
                    }}
                    onClick={removeContacts}
                    >
                        Remove Contacts
                    </LoadingButton>
            
                </Grid>
                <Grid item md={8}></Grid>
                <Grid item md={1}>
                    <LoadingButton
                    loading={updateGroupLoading}
                    variant={"contained"}
                    sx={{
                        height: 30,
                        fontSize: 16,
                        fontWeight: 500,
                        padding: "2px 18px",
                        margin: "1rem",
                        float: "right",
                        borderRadius: "8px",
                        alignSelf: "flex-end",
                        bgcolor: "#5177FC",
                        ":hover": {
                        bgcolor: alpha("#5177FC", 0.9),
                        },
                        textTransform: "capitalize",
                    }}
                    onClick={handleSave}
                    >
                    Save
                    </LoadingButton>
                </Grid>
            </Grid>
          </Stack>
            </AppModal>
    );
};

export default UpdateGroupModal;