import {useState, useEffect} from 'react';
import {GetWebsiteAuditReportData} from '../../../services/api';
import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DescriptionIcon from '@mui/icons-material/Description';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import LinkIcon from '@mui/icons-material/Link';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import ProgressBar from '../../charts/ProgressBar';
import { PageLoader } from '../../page-loader';
const WebsiteAuditReport = () => {
    //get website from url querystring
    var urlParams = new URLSearchParams(window.location.search);
    var website = urlParams.get('website');
    const [loading, setLoading] = useState(false);
    const [metrics, setMetrics] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await GetWebsiteAuditReportData(website);
                setMetrics(response.data);
                setLoading(false);
                console.log("data: ",response.data);
                console.log("metrics: ",metrics);
                console.log("Performance: ",metrics?.data?.performance);
            } catch (error) {
                setError(error);
            }
        };
        fetchData();
    }, [website]);
  return (
    <div style={{padding:"2rem 15rem"}}>
      <h1 style={{textAlign:"center"}}>Website Audit Report for {website}</h1>
      {loading && <PageLoader text="Collecting Information about your domain..." />}
      {!loading && (<div>
          <Grid container  sx={{marginTop:"5rem"}} >
            <Grid item xs={1}></Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} style={{marginTop: "1rem", marginRight:"2rem"}}>
              <Card>
                <CardContent>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={6} sm={6} md={8} lg={10}>
                    <Typography color="textSecondary" gutterBottom>
                      Visits
                    </Typography>
                    <Typography variant="h5" component="h2">
                      {metrics?.data?.visits ? metrics?.data?.visits.toLocaleString() : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={2}><VisibilityIcon style={{ fontSize: 40 }} /></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} style={{marginTop: "1rem", marginRight:"2rem"}}>
              <Card>
                <CardContent>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={6} sm={6} md={8} lg={10}>
                  <Typography color="textSecondary" gutterBottom>
                    Pages Per Visit
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {metrics?.data?.pages_per_visit ? metrics?.data?.pages_per_visit.toLocaleString() : "N/A"}
                  </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={2}><DescriptionIcon style={{ fontSize: 40 }} /></Grid>
                </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} style={{marginTop: "1rem"}}>
              <Card>
                <CardContent>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={6} sm={6} md={8} lg={10}>
                  <Typography color="textSecondary" gutterBottom>
                    Average Visit Duration
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {metrics?.data?.avg_visit_duration ? metrics?.data?.avg_visit_duration.toLocaleString() : "N/A"}
                  </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={2}><HourglassTopIcon style={{ fontSize: 40 }} /></Grid>
                </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} style={{marginTop: "1rem", marginRight:"2rem"}}>
              <Card>
                <CardContent>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={6} sm={6} md={8} lg={10}>
                  <Typography color="textSecondary" gutterBottom>
                    Back Links
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {metrics?.data?.backlinks ? metrics?.data?.backlinks.toLocaleString() : "N/A"}
                  </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={2}><LinkIcon style={{ fontSize: 40 }} /></Grid>
                </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} style={{marginTop: "1rem", marginRight:"2rem"}}>
              <Card>
                <CardContent>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={6} sm={6} md={8} lg={10}>
                  <Typography color="textSecondary" gutterBottom>
                    Broken Links
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {metrics?.data?.brokenlinks ? metrics?.data?.brokenlinks.toLocaleString() : "N/A"}
                  </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={2}><LinkOffIcon style={{ fontSize: 40 }} /></Grid>
                </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} style={{marginTop: "1rem", marginRight:"2rem"}}>
              <Card>
                <CardContent>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={6} sm={6} md={8} lg={10}>
                  <Typography color="textSecondary" gutterBottom>
                    Domain Authority
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {metrics?.data?.domain_authority ? metrics?.data?.domain_authority.toLocaleString() : "N/A"}
                  </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={2}><VerifiedUserIcon style={{ fontSize: 40 }} /></Grid>
                </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
          <Grid container sx={{marginTop:"5rem"}}>
            <Grid item md={3} lg={3} xl={3} sx={{textAlign:"center"}}>
              <h3>Performance</h3>
              <ProgressBar value={metrics?.data?.performance} />
            </Grid>
            <Grid item md={3} lg={3} xl={3} sx={{textAlign:"center"}} >
              <h3>Accessibility</h3>
              <ProgressBar value={metrics?.data?.accessibility} barcolor="#4caf50" />
            </Grid>
            <Grid item md={3} lg={3} xl={3} sx={{textAlign:"center"}} >
              <h3>Best Practices</h3>
              <ProgressBar value={metrics?.data?.best_practices} barcolor="#ff5722" />
            </Grid>
            <Grid item md={3} lg={3} xl={3} sx={{textAlign:"center"}} >
              <h3>SEO</h3>
              <ProgressBar value={metrics?.data?.seo} barcolor="#9c27b0"  />
              </Grid>
          </Grid>
      </div>)}
    </div>
  );
};

export default WebsiteAuditReport;