import React, { useState } from 'react';
import { useCognitoUser } from "../../../CognitoProvider";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Stack,
  TextField,
  Typography,
  alpha,
  FormControl,
  FormHelperText
} from "@mui/material";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { changePassword } = useCognitoUser();
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setShowError(false);
    setShowSuccess(false);
    if (newPassword !== confirmPassword) {
        setErrorMessage("Passwords do not match");
        setShowError(true);
        setIsLoading(false);
      return;
    }
    try {
      const response = await changePassword({ oldPassword, newPassword });
      console.log(response);
      setSuccessMessage("Password changed successfully");
      setShowSuccess(true);
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setIsLoading(false);
      setTimeout(() => {
        setShowSuccess(false);
      }, 10000);
    } catch (error) {
        console.log(error);
        if(error.message.includes("NotAuthorizedException")){
            setErrorMessage("Incorrect current password");
        }
        else if(error.message.includes("PasswordResetRequiredException")){
            setErrorMessage("Password reset required");
        }
        else if(error.message.includes("InvalidParameterException")){
            setErrorMessage("Password must be at least 8 characters long, and must have uppercase, lowercase, number and special character");
        }
        else if(error.message.includes("LimitExceededException")){
            setErrorMessage("Attempt limit exceeded, please try after some time");
        }
        else if(error.message.includes("UserNotFoundException")){
            setErrorMessage("User not found");
        }
        else if(error.message.includes("TooManyRequestsException")){
            setErrorMessage("Too many requests, please try after some time");
        }
        else if(error.message.includes("ResourceNotFoundException")){
            setErrorMessage("Resource not found");
        }
        else{
            setErrorMessage("Something went wrong, please try again later");
        }
        setShowError(true);
        setIsLoading(false);
    }
  };
  //create Change Password form
  return (
    <Box
      sx={{
        width: "80%",
        maxWidth: "500px",
        mx: "auto",
        mt: "20px",
        mb: "20px",
        px: "20px",
      }}
    >
      <h3 style={{fontSize:"24px",fontWeight:800}} >
        Reset Password
      </h3>
      <form onSubmit={handleSubmit} >
        <Stack spacing={2}>
          <TextField
            fullWidth
            label="Current Password"
            variant="outlined"
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                fontSize: 14,
                border: 0,
              },
              "& .MuiOutlinedInput-input": {
                padding: "14px",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
                bgcolor: "#F7F7F7",
              },
              marginTop:"3rem !important"
            }}
          />
          <TextField
            fullWidth
            label="New Password"
            variant="outlined"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                fontSize: 14,
                border: 0,
              },
              "& .MuiOutlinedInput-input": {
                padding: "14px",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
                bgcolor: "#F7F7F7",
              },
              marginTop:"3rem !important"
            }}
          />
          <TextField
            fullWidth
            label="Re-Type New Password"
            variant="outlined"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                fontSize: 14,
                border: 0,
              },
              "& .MuiOutlinedInput-input": {
                padding: "14px",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
                bgcolor: "#F7F7F7",
              },
              marginTop:"3rem !important"
            }}
          />
          <LoadingButton
            type="submit"
            variant="contained"
            loadingPosition="start"
            loading={isLoading}
            sx={{
              color: "#fff",
              backgroundColor: "#5177FC",
              "&:hover": {
                backgroundColor: alpha("#5177FC", 0.8),
              },
              textTransform: "none",
              width:"60%",
              fontSize:"16px",
              marginTop:"4rem !important",
              borderRadius:"10px"
            }}
          >
            Reset Password
          </LoadingButton>
          {showError && (
            <FormControl error>
              <FormHelperText>{errorMessage}</FormHelperText>
            </FormControl>
          )}
          {showSuccess && (
            <FormControl>
              <FormHelperText>{successMessage}</FormHelperText>
            </FormControl>
          )}
        </Stack>
      </form>
    </Box>
  );
};

export default ChangePassword;
