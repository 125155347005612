import Grid from '@mui/material/Grid';
import {ManageBilling} from '../../services/api';
const FreeTrial = ({ trialInfo, setTrialInfo, setShowTrialBanner }) => {
    const calculateDaysRemaining = (startDate, trialPeriodDays) => {
        const now = new Date();
        const endTrialDate = new Date(startDate);
        endTrialDate.setDate(endTrialDate.getDate() + trialPeriodDays);
        return Math.max(0, Math.ceil((endTrialDate - now) / (1000 * 60 * 60 * 24)));
    };
    
    const daysRemaining = calculateDaysRemaining(trialInfo.startDate, trialInfo.trialPeriodDays);

  console.log("trialInfo", trialInfo);
  if (!trialInfo.inTrial) {
    return null;
  }
  const OpenAddCreditCardPage = async () => {
    const current_url = window.location.href;
    const response = await ManageBilling(current_url);
    console.log(response);
    console.log(response.data?.url);
    window.open(response.data?.url, "_self");
  }
  const hideBanner = () => {
    console.log("hide banner");
    setShowTrialBanner(false);
    setTrialInfo({
      startDate: trialInfo.startDate,
      trialPeriodDays:trialInfo.trialPeriodDays,
      is_credit_card_exist:trialInfo.is_credit_card_exist,
      inTrial:false
    });
  }
  return (
    <div style={{ position: 'fixed', top: "80px", width: '100%', backgroundColor: '#D9E1FE', paddingTop: '10px', textAlign: 'center' }}>
        <Grid container>
            <Grid item xs={11}>
            <p style={{fontSize:"14px"}}>{`Your free trial ends in ${daysRemaining} day(s).`} {(trialInfo.is_credit_card_exist === false) && <>Your subscription will be canceled after trial ends. To automatically renew the subscription after trial ends <span className='span-link' onClick={OpenAddCreditCardPage}>Add Payment Method</span>.</>} {(trialInfo.is_credit_card_exist) && <>Your subscription will automatically renew after trial ends.</>}</p>
            </Grid>
            <Grid item xs={1}><span style={{marginRight:"2rem",float:"right"}} className='span-link' onClick={hideBanner}>X</span></Grid>
        </Grid>
    </div>
  );
      
};

export default FreeTrial;