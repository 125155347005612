import React, { Suspense, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCognitoUser } from "../../../CognitoProvider";
import ReCAPTCHA from "react-google-recaptcha";
import { FaFacebookF, FaAmazon, FaGoogle } from "react-icons/fa";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Auth } from "aws-amplify";
import Modal from "react-modal";
import toast from "react-hot-toast";
import { PageLoader } from "../../page-loader";
import {GetUserSubscription} from "../../../services/api";
import { PageLayout } from "../../page-layout";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    borderRadius: 7,
    border: 0,
    padding: "32px 32px 32px 32px",
    minHeight: "200px",
    maxWidth: "400px",
    boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.25)",
  },
};

export const SOCIAL_NETWORK_ACCOUNTS = [
  {
    id: "Facebook",
    icon: <FaFacebookF size={30} />,
    provider: CognitoHostedUIIdentityProvider.Facebook,
  },
  {
    id: "Google",
    icon: <FaGoogle size={30} />,
    provider: CognitoHostedUIIdentityProvider.Google,
  },
  {
    id: "Amazon",
    icon: <FaAmazon size={30} />,
    provider: CognitoHostedUIIdentityProvider.Amazon,
  },
];

function CognitoLogin() {
  const navigate = useNavigate();
  const { signIn } = useCognitoUser();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [forgetEmail, setForgetEmail] = useState("");
  const [forgetPassword, setForgetPassword] = useState("");
  const [forgetPasswordConfirm, setForgetPasswordConfirm] = useState("");
  const [forgetCode, setForgetCode] = useState("");

  const [forgetPasswordModal, setForgetPasswordModal] = useState(false);
  const [forgetPasswordStep, setForgetPasswordStep] = useState(1);

  const [captchaValid, setCaptchaValid] = useState(false);
  const [showCaptchaError, setShowCaptchaError] = useState(false);

  const closeForgetPassword = () => {
    setForgetPasswordStep(1);
    setForgetPasswordModal(false);
  };

  const handleSignin = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    if(enableCaptcha){
      if (!captchaValid) {
        setShowCaptchaError(true);
        return;
      }
    }
    
    try {
      setErrors({});
      setLoading(true);
      await signIn({ username: email, password });
      const response = await GetUserSubscription();
      if (response.data.is_subscribed) {
        navigate("/products");
      }
      else{
        navigate("/");
      }
    } catch (error) {
      setLoading(false);
      setErrors({
        email: "Incorrect Email or Password.",
        password: "Incorrect Email or Password.",
      });
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!isValidEmail(email)) {
      errors.email = "Invalid email address";
    }

    if (!password.trim()) {
      errors.password = "Password is required";
    } else if (password.length < 6) {
      errors.password = "Password should be at least 6 characters long";
    }

    return errors;
  };

  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // const handleKeyDown = (e) => {
  //   e.preventDefault();
  //   if (e.keyCode === 13) {
  //     handleSignin();
  //   }
  // };

  const onChangeCaptcha = async (captchaCode) => {
    if (!captchaCode) {
      setCaptchaValid(false);
      return;
    }
    setCaptchaValid(true);
    if (showCaptchaError) {
      setShowCaptchaError(false);
    }
  };

  const onHandleForgetPassword = async () => {
    if (!forgetEmail.trim()) {
      setErrors({ forgetEmail: "Email is required" });
      return;
    } else if (!isValidEmail(forgetEmail)) {
      setErrors({ forgetEmail: "Invalid email address" });
      return;
    }
    try {
      await Auth.forgotPassword(forgetEmail);
      setForgetPasswordStep(2);
    } catch (error) {
      setErrors({ forgetEmail: "Error" });
    }
  };

  const onHandleChangePassword = async () => {
    const validationErrors = validateForgetForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      await Auth.forgotPasswordSubmit(forgetEmail, forgetCode, forgetPassword);
      closeForgetPassword();
      setForgetCode("");
      setForgetPassword("");
      setForgetPasswordConfirm("");
      setForgetEmail("");
      toast.success(
        "Your password has been changed successfully, Use your new password to sign in.",
        { duration: 6000 }
      );
    } catch (error) {
      let err = {};
      if (error.message.includes("Password"))
        err = {
          forgetPassword: error.message,
          forgetPasswordConfirm: error.message,
        };
      else err = { forgetCode: error.message };
      setErrors(err);
    }
  };

  const validateForgetForm = () => {
    const errors = {};

    if (!forgetCode.trim()) {
      errors.forgetCode = "Validation Code is required";
    }

    if (forgetPassword.trim() !== forgetPasswordConfirm.trim()) {
      errors.forgetPassword = "Password doesn't match";
      errors.forgetPasswordConfirm = "Password doesn't match";
    }
    if (!forgetPassword.trim()) {
      errors.forgetPassword = "Password is required";
    } else if (forgetPassword.length < 6) {
      errors.forgetPassword = "Password should be at least 6 characters long";
    }

    if (!forgetPasswordConfirm.trim()) {
      errors.forgetPasswordConfirm = "Password is required";
    } else if (forgetPasswordConfirm.length < 6) {
      errors.forgetPasswordConfirm =
        "Password should be at least 6 characters long";
    }

    return errors;
  };

  if (loading) return <PageLoader />;
  const enableCaptcha = process.env.REACT_APP_ENABLE_CAPTCHA === 'true';
  const current_url = window.location.href;
  const paramExists = current_url.includes('free-trial');
  let signupUrl = '/signup';
  if (paramExists){
    signupUrl = '/signup?free-trial=true';
  }
  return (
    <PageLayout>
      <div className="padding-sides" >
        <div className="signup-wrapper">
          <div className="signin-container">
            <h2 className="signin-title">Sign in</h2>
            <div className="signinTextfieldWrapper">
              <div>
                <label>Email:</label>
                <input
                  className="signin-form-input"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errors.email && (
                  <span className="signin-error-message">{errors.email}</span>
                )}
              </div>
              <div>
                <label>Password:</label>
                <input
                  className="signin-form-input"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {errors.password && (
                  <span className="signin-error-message">{errors.password}</span>
                )}
              </div>
            </div>
            <div
              style={{ display: "flex", marginBottom: 16, alignItems: "center" }}
            >
              <input
                type="checkbox"
                id="remember"
                name="remember"
                style={{
                  marginRight: 8,
                  border: "1px solid grey",
                  borderRadius: "25px",
                }}
              />
              <label htmlFor="remember">Remember me</label>
            </div>
            {enableCaptcha && (
                    <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginBottom: 16,
                    }}
                  >
                    <Suspense fallback={<>Loading...</>}>
                    
                      <ReCAPTCHA
                        sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTCHA}`}
                        onChange={onChangeCaptcha}
                      />
                    </Suspense>
                    {showCaptchaError && (
                      <p style={{ color: "red", marginTop: 4 }}>
                        Please solve captcha to continue.
                      </p>
                    )}
                  </div>
                )}
            
            <button className="signin-form-button" onClick={handleSignin}>
              Sign in
            </button>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 16,
              }}
            >
              <p
                className="primary-text"
                style={{ cursor: "pointer", fontSize: 12 }}
                onClick={() => setForgetPasswordModal(true)}
              >
                Forgot Password
              </p>
              <p
                className="primary-text"
                style={{ cursor: "pointer", fontSize: 12 }}
                onClick={() => navigate(signupUrl)}
              >
                Create Account
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                margin: "10px 10%",
              }}
            >
              {SOCIAL_NETWORK_ACCOUNTS.map(({ id, icon, provider }) => (
                <div
                  key={id}
                  style={{
                    padding: 4,
                    borderRadius: "100%",
                    flexShrink: 0,
                    color: "var(--brand)",
                    cursor: "pointer",
                    filter:
                      "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))",
                  }}
                  onClick={() => {
                    Auth.federatedSignIn({
                      provider,
                    });
                  }}
                >
                  {icon}
                </div>
              ))}
            </div>
          </div>
          <Modal
            isOpen={forgetPasswordModal}
            onRequestClose={closeForgetPassword}
            style={customStyles}
            contentLabel="Forget Password"
          >
            <span
              className="primary-text bolder-text"
              style={{
                position: "absolute",
                top: 15,
                right: 15,
                cursor: "pointer",
              }}
              onClick={closeForgetPassword}
            >
              X
            </span>

            {forgetPasswordStep === 1 ? (
              <>
                <span
                  className="primary-text"
                  style={{ marginTop: 6, textAlign: "center" }}
                >
                  Please enter your email and we'll send you instructions for
                  resetting your password.
                </span>
                <div style={{ margin: 10 }}>
                  <label>Email:</label>
                  <input
                    className="signin-form-input"
                    type="email"
                    value={forgetEmail}
                    onChange={(e) => setForgetEmail(e.target.value)}
                  />
                  {errors.forgetEmail && (
                    <span className="signin-error-message">
                      {errors.forgetEmail}
                    </span>
                  )}
                </div>
                <button
                  className="button__primary"
                  style={{ margin: "auto" }}
                  onClick={onHandleForgetPassword}
                >
                  Confirm
                </button>
              </>
            ) : (
              <>
                <span
                  className="primary-text"
                  style={{ marginTop: 6, textAlign: "center" }}
                >
                  Enter your new password and validation code to reset your
                  password.
                </span>
                <div style={{ margin: 10 }}>
                  <label>New Password:</label>
                  <input
                    className="signin-form-input"
                    type="password"
                    value={forgetPassword}
                    onChange={(e) => setForgetPassword(e.target.value)}
                  />
                  {errors.forgetPassword && (
                    <span className="signin-error-message">
                      {errors.forgetPassword}
                    </span>
                  )}
                </div>
                <div style={{ margin: 10 }}>
                  <label>Retype Password:</label>
                  <input
                    className="signin-form-input"
                    type="password"
                    value={forgetPasswordConfirm}
                    onChange={(e) => setForgetPasswordConfirm(e.target.value)}
                  />
                  {errors.forgetPasswordConfirm && (
                    <span className="signin-error-message">
                      {errors.forgetPasswordConfirm}
                    </span>
                  )}
                </div>
                <div style={{ margin: 10 }}>
                  <label>Validation Code:</label>
                  <input
                    className="signin-form-input"
                    type="text"
                    value={forgetCode}
                    onChange={(e) => setForgetCode(e.target.value)}
                  />
                  {errors.forgetCode && (
                    <span className="signin-error-message">
                      {errors.forgetCode}
                    </span>
                  )}
                </div>
                <button
                  className="button__primary"
                  style={{ margin: "auto" }}
                  onClick={onHandleChangePassword}
                >
                  Confirm
                </button>
              </>
            )}
          </Modal>
        </div>
      </div>
    </PageLayout>
  );
}

export default CognitoLogin;
