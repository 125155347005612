import React from "react";
import CognitoSecureRoute from "./cognito/SecureRoute";
import { PageLoader } from "./page-loader";

export const AuthenticationGuard = ({ children, component, title, showSubscrbe }) => {
  return (
    <React.Suspense fallback={<PageLoader />}>
      <CognitoSecureRoute text={title} showSubscrbe={showSubscrbe} >{children}</CognitoSecureRoute>
    </React.Suspense>
  );
};
