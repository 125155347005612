import { Stack, Typography } from "@mui/material";
import SavedSearchesTable from "../../../table/SavedSearchesTable";
import { useEffect, useState, useCallback  } from "react";
import {
  DeleteSingleTargetedMarketingList,
  GetSavedTargetedMarketingList,
} from "../../../../services/api";
import { useCognitoUser } from "../../../../CognitoProvider";
import { AuthenticationGuard } from "../../../authentication-guard";
import { PageLoader } from "../../../page-loader";
const SavedSearches = () => {
  const { userLoaded } = useCognitoUser();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);


  const onDelete = async (id) => {
    await DeleteSingleTargetedMarketingList(id);
    fetchData();
  };
  const fetchData = useCallback(async () => {
    setLoading(true);
    try{
      const response = await GetSavedTargetedMarketingList(page);
      const count = Math.ceil(response.data?.count / 20);
      setRows(response.data?.results?.reverse());
      setPageCount(count ? count : 1);
    }
    catch{
      setRows([]);
      setPageCount(1);
    }
    finally{
      setLoading(false);
    }
  }, [page]);
  useEffect(() => {
    if (userLoaded) fetchData();
  }, [userLoaded, page, fetchData]);
  if(loading) return <PageLoader />
  return (
    <AuthenticationGuard title={"to start using Saved Searches"}>
      <div className="padding-sides">
        <Stack bgcolor={"#F5F5F5"} p={3} height={"100%"} borderRadius={"8px"}>
          <Typography fontSize={24} fontWeight={600} mb={3}>
            Saved Searches
          </Typography>
          <SavedSearchesTable
            rows={rows}
            page={page}
            setPage={setPage}
            pageCount={pageCount}
            onDelete={onDelete}
          />
        </Stack>
      </div>
    </AuthenticationGuard>
  );
};

export default SavedSearches;
