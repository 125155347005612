import LoadingButton from "@mui/lab/LoadingButton";
import AppModal from "../../../AppModal";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  alpha,
  FormControl,
  FormHelperText,
    MenuItem,
    Select
} from "@mui/material";
import * as React from "react";
import { AddAutomatedEmail, RemoveAutomatedEmail, UpdateAutomatedEmail, GetSavedSearchesSimplified, UpdateAutomatedEmailStatus, RemoveAutomatedEmailGroups, RemoveAutomatedEmailContacts, AddAutomatedEmailGroups, AddAutomatedEmailContacts, GetGroups, GetContacts, GetAutomatedEmailGroups, GetAutomatedEmailContacts, GetGroupsNotInAutomatedEmail, GetContactsNotInAutomatedEmail,SearchContactsNotInAutomatedEmail,SearchContacts,SearchAutomatedEmailContacts } from '../../../../services/api';
import Grid from '@mui/material/Grid';
import AutomatedEmailsTable from "./AutomatedEmailsTable";
import Recipients from "./Recipients";
import { useCallback, useState } from "react";
const AutomatedEmailsContent = ({ rows, page, pageCount, setPage, refetch, setRows }) => {
  const [OpenAddAutomatedEmailModal, setOpenAddAutomatedEmailModal] = React.useState(false);
  const [name, setName] = React.useState("");
  const [nameRequired, setNameRequired] = React.useState(false);
  const [savedSearch, setSavedSearch] = React.useState("");
  const [savedSearchRequired, setSavedSearchRequired] = React.useState(false);
  const [addAutomatedEmailLoading, setAddAutomatedEmailLoading] = React.useState(false);
  const [selectedIDs, setSelectedIDs] = React.useState([]);
  const [selectedGroups, setSelectedGroups] = React.useState([]);
  const [selectedContacts, setSelectedContacts] = React.useState([]);
  const [updateAutomatedEmailLoading, setUpdateAutomatedEmailLoading] = React.useState(false);
  const [openRemoveAutomatedEmailConfirmModal, setOpenRemoveAutomatedEmailConfirmModal] = React.useState(false);
  const [isUpdateAutomatedEmailModalOpen, setIsUpdateAutomatedEmailModalOpen] = React.useState(false);
  const [currentAutomatedEmail, setCurrentAutomatedEmail] = React.useState(null);
  const [savedSearches, setsavedSearches] = React.useState([]);
  const [currentOpenedModal, setCurrentOpenedModal] = React.useState(null);
  const [removeRecipientsLoading, setRemoveRecipientsLoading] = React.useState(false);
  const [addRecipientsLoading, setAddRecipientsLoading] = React.useState(false);
  const [groups, setGroups] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [groupsPage, setGroupsPage] = useState(1);
  const [groupsPageCount, setGroupsPageCount] = useState(1);
  const [contactsPage, setContactsPage] = useState(1);
  const [contactsPageCount, setContactsPageCount] = useState(1);
  const [purpose, setPurpose] = useState("");
  const [keyword, setKeyword] = useState("");

  const executeSearch = async (search) => {
    if (search === ""){
        fetchData();
        return;
    }
    if(purpose==="add"){
      const response = await SearchContacts(search,page);
      setContacts(response.data?.contacts);
      setContactsPageCount(response.data?.total_pages);
      setContactsPage(response.data?.page);
    }
    else if(purpose === "edit"){
      const response = await SearchAutomatedEmailContacts(currentAutomatedEmail.id,search,page);
      setContacts(response.data?.contacts);
      setContactsPageCount(response.data?.total_pages);
      setContactsPage(response.data?.page);
    }
    else if(purpose === "add_recipients"){
      const response = await SearchContactsNotInAutomatedEmail(currentAutomatedEmail.id,search,page);
      setContacts(response.data?.contacts);
      setContactsPageCount(response.data?.total_pages);
      setContactsPage(response.data?.page);
    }
  };

  const fetchData = useCallback(async () => {
    if (purpose === "add"){
      const response = await GetGroups(groupsPage);
      setGroups(response.data?.groups);
      setGroupsPageCount(response.data?.total_pages);
      setGroupsPage(response.data?.page);
      if(keyword === ""){
        const response2 = await GetContacts(contactsPage);
        setContacts(response2.data?.contacts);
        setContactsPageCount(response2.data?.total_pages);
        setContactsPage(response2.data?.page);
      }else{
        const response2 = await SearchContacts(keyword,contactsPage);
        setContacts(response2.data?.contacts);
        setContactsPageCount(response2.data?.total_pages);
        setContactsPage(response2.data?.page);
      }
    }
    else if (purpose === "edit"){
      const response = await GetAutomatedEmailGroups(currentAutomatedEmail.id, groupsPage);
      setGroups(response.data?.groups);
      setGroupsPageCount(response.data?.total_pages);
      setGroupsPage(response.data?.page);
      if(keyword === ""){
        const response2 = await GetAutomatedEmailContacts(currentAutomatedEmail.id, contactsPage);
        setContacts(response2.data?.contacts);
        setContactsPageCount(response2.data?.total_pages);
        setContactsPage(response2.data?.page);
      }else{
        const response2 = await SearchAutomatedEmailContacts(currentAutomatedEmail.id,keyword,contactsPage);
        setContacts(response2.data?.contacts);
        setContactsPageCount(response2.data?.total_pages);
        setContactsPage(response2.data?.page);
      }
    }
    else if (purpose === "add_recipients"){
      const response = await GetGroupsNotInAutomatedEmail(currentAutomatedEmail.id, groupsPage);
      setGroups(response.data?.groups);
      setGroupsPageCount(response.data?.total_pages);
      setGroupsPage(response.data?.page);
      if(keyword === ""){
        const response2 = await GetContactsNotInAutomatedEmail(currentAutomatedEmail.id, contactsPage);
        setContacts(response2.data?.contacts);
        setContactsPageCount(response2.data?.total_pages);
        setContactsPage(response2.data?.page);
      }else{
        const response2 = await SearchContactsNotInAutomatedEmail(currentAutomatedEmail.id,keyword,contactsPage);
        setContacts(response2.data?.contacts);
        setContactsPageCount(response2.data?.total_pages);
        setContactsPage(response2.data?.page);
      }
    }
  }, [currentAutomatedEmail, purpose, groupsPage, contactsPage,keyword]);
  const handleChangeStatus = async (id) => {
    const response = await UpdateAutomatedEmailStatus(id);
    if (response.status === 200) {
      refetch();
    }
  };
  const fetchSavedSearches = async () => {
    const response = await GetSavedSearchesSimplified();
    setsavedSearches(response?.data?.saved_searches);
  };


  const handleOpenUpdateAutomatedEmailModal = async (automatedEmail) => {
    await fetchSavedSearches();
    setKeyword("");
    setContacts([]);
    setGroups([]);
    setGroupsPage(1);
    setGroupsPageCount(1);
    setNameRequired(false);
    setSavedSearchRequired(false);
    setSavedSearch(automatedEmail.saved_search_id);
    console.log("Saved Search: ", savedSearch);
    setName(automatedEmail.name);
    console.log("Name: ", name);
    setCurrentAutomatedEmail(automatedEmail);
    setSelectedGroups([]);
    setSelectedContacts([]);
    setPurpose("edit");
    setIsUpdateAutomatedEmailModalOpen(true);
    setCurrentOpenedModal("edit");
  };

  const handleCloseUpdateAutomatedEmailModal = () => setIsUpdateAutomatedEmailModalOpen(false);
  const resetForm = () => {
    setNameRequired(false);
    setSavedSearchRequired(false);
    setName("");
    setSavedSearch("");
  };
  const handleAddAutomatedEmail = async () => {
    setAddAutomatedEmailLoading(true);
    //create formdata
    let errors_happened = false;
    if (name === "") {
      setNameRequired(true);
      setAddAutomatedEmailLoading(false);
      errors_happened = true;
    }
    if (savedSearch === "") {
      setSavedSearchRequired(true);
      setAddAutomatedEmailLoading(false);
      errors_happened = true;
    }
    if (errors_happened) return;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("saved_search", savedSearch);
    formData.append("Active", true);
    formData.append("group_ids", JSON.stringify(selectedGroups));
    formData.append("contact_ids", JSON.stringify(selectedContacts));
    const response = await AddAutomatedEmail(formData);
    if (response.status === 200) {
      setAddAutomatedEmailLoading(false);
      handleCloseAddAutomatedEmailModal();
      resetForm();
      refetch();
    }
  };

  const handleOpenAddAutomatedEmailModal = () => {
    fetchSavedSearches();
    setKeyword("");
    setContacts([]);
    setGroups([]);
    setGroupsPage(1);
    setGroupsPageCount(1);
    resetForm();
    setSavedSearchRequired(false);
    setNameRequired(false);
    setSelectedGroups([]);
    setSelectedContacts([]);
    setPurpose("add");
    setOpenAddAutomatedEmailModal(true);
    setCurrentOpenedModal("add");
  };
  const handleCloseAddAutomatedEmailModal = () => setOpenAddAutomatedEmailModal(false);
  const handleSavedSearchChange = (event) => {
    setSavedSearch(event.target.value);
    };
  const handleOpenRemoveAutomatedEmailConfirmModal = () => setOpenRemoveAutomatedEmailConfirmModal(true);
  const handleCloseRemoveAutomatedEmailConfirmModal = () => setOpenRemoveAutomatedEmailConfirmModal(false);
  const [openAddRecipientsModal, setOpenAddRecipientsModal] = React.useState(false);
  const handleOpenAddRecipientsModal = (automatedEmail) => {
    setKeyword("");
    setContacts([]);
    setGroups([]);
    setGroupsPage(1);
    setGroupsPageCount(1);
    setCurrentOpenedModal("add_recipients");
    setSelectedContacts([]);
    setSelectedGroups([]);
    setPurpose("add_recipients");
    setCurrentAutomatedEmail(automatedEmail);
    setOpenAddRecipientsModal(true);
  };
  const handleCloseAddRecipientsModal = () => setOpenAddRecipientsModal(false);
  const handleAddRecipients = async () => {
    setAddRecipientsLoading(true);
    if(selectedGroups.length > 0){
      const formData = new FormData();
      formData.append("automated_email_id", currentAutomatedEmail.id);
      formData.append("group_ids", JSON.stringify(selectedGroups));
      const res = await AddAutomatedEmailGroups(currentAutomatedEmail.id,formData);
      if (res){
        setSelectedGroups([]);
      }
    }
    if(selectedContacts.length > 0){
      const formData = new FormData();
      formData.append("automated_email_id", currentAutomatedEmail.id);
      formData.append("contact_ids", JSON.stringify(selectedContacts));
      const res = await AddAutomatedEmailContacts(currentAutomatedEmail.id,formData);
      if (res){
        setSelectedContacts([]);
      }
    }
    refetch();
    setAddRecipientsLoading(false);
    handleCloseAddRecipientsModal();
  };
  

  const handleRemoveAutomatedEmail = async () => {
    const automated_emails_ids = JSON.stringify(selectedIDs);
    const response = await RemoveAutomatedEmail(automated_emails_ids);
    if (response.status === 200) {
      handleCloseRemoveAutomatedEmailConfirmModal();
      resetForm();
      refetch();
    }
  };

  const handleUpdateAutomatedEmail = async () => {
    setUpdateAutomatedEmailLoading(true);
    let errors_happened = false;
    if (name === "") {
      setNameRequired(true);
      setUpdateAutomatedEmailLoading(false);
      errors_happened = true;
    }
    if (savedSearch === "") {
      setSavedSearchRequired(true);
      setUpdateAutomatedEmailLoading(false);
      errors_happened = true;
    }
    if (errors_happened) return;
    //create formdata
    const formData = new FormData();
    formData.append("id", currentAutomatedEmail.id);
    formData.append("name", name);
    formData.append("saved_search", savedSearch);
    const response = await UpdateAutomatedEmail(formData);
    if (response.status === 200) {
      setUpdateAutomatedEmailLoading(false);
      handleCloseUpdateAutomatedEmailModal();
      resetForm();
      refetch();
    }
  };

  const removeRecipients = async () => {
    setRemoveRecipientsLoading(true);
    if(selectedGroups.length > 0){
      const formData = new FormData();
      formData.append("automated_email_id", currentAutomatedEmail.id);
      formData.append("group_ids", JSON.stringify(selectedGroups));
      const res = await RemoveAutomatedEmailGroups(currentAutomatedEmail.id,formData);
      if (res){
        setSelectedGroups([]);
      }
    }
    if(selectedContacts.length > 0){
      const formData = new FormData();
      formData.append("automated_email_id", currentAutomatedEmail.id);
      formData.append("contact_ids", JSON.stringify(selectedContacts));
      const res = await RemoveAutomatedEmailContacts(currentAutomatedEmail.id,formData);
      if (res){
        setSelectedContacts([]);
      }
    }
    fetchData();
    refetch();
    setRemoveRecipientsLoading(false);
  };

  return (
    <>
      {rows.length ? (
        <>
            <Stack gap={2}>
              <Grid container>
                  <Grid item md={6}>
                  <Button 
                      disabled={selectedIDs.length === 0}
                      sx={{
                      height: 30,
                      bgcolor: "#fff",
                      ":hover": {
                          bgcolor: alpha("#fff", 0.9),
                      },
                      fontSize: 16,
                      fontWeight: 500,
                      padding: "2px 18px",
                      margin: "1rem",
                      borderRadius: "8px",
                      borderColor:"#EA5455",
                      border:"1px solid",
                      textTransform: "capitalize",
                      color:"#EA5455"
                      }}
                      onClick={(e) => handleOpenRemoveAutomatedEmailConfirmModal()}
                  
                  >
                      Remove
                  </Button>
                  <Button 
                      sx={{
                          height: 30,
                          bgcolor: "#5177FC",
                          ":hover": {
                          bgcolor: alpha("#5177FC", 0.9),
                          },
                          fontSize: 16,
                          fontWeight: 500,
                          padding: "2px 18px",
                          margin: "1rem",
                          borderRadius: "8px",
                          textTransform: "capitalize",
                          color:"#fff"
                      }}
                      onClick={(e) => handleOpenAddAutomatedEmailModal()}
                      
                      >
                      + Add New
                  </Button>
                  </Grid>
              </Grid>
            </Stack>
            <AutomatedEmailsTable
                rows={rows}
                page={page}
                setPage={setPage}
                pageCount={pageCount}
                selectedIDs={selectedIDs}
                setSelectedIDs={setSelectedIDs}
                ShowUpdate={true}
                handleOpenUpdateAutomatedEmailModal={handleOpenUpdateAutomatedEmailModal}
                removeBoxPadding={false}
                handleChange={handleChangeStatus}
                handleOpenAddRecipientsModal={handleOpenAddRecipientsModal}
            />
            <AppModal
            open={openAddRecipientsModal}
            title={"Add Recipients to Automated Email: "+currentAutomatedEmail?.name}
            handleClose={handleCloseAddRecipientsModal}
            maxWidth="xl"
            closeIcon
        >
                <Stack gap={1}>
                <Recipients
                selectedGroups={selectedGroups}
                setSelectedGroups={setSelectedGroups}
                selectedContacts={selectedContacts}
                setSelectedContacts={setSelectedContacts}
                currentAutomatedEmail={currentAutomatedEmail}
                currentOpenedModal={currentOpenedModal}
                removeRecipientsLoading={removeRecipientsLoading}
                groups={groups}
                contacts={contacts}
                groupsPage={groupsPage}
                setGroupsPage={setGroupsPage}
                groupsPageCount={groupsPageCount}
                contactsPage={contactsPage}
                setContactsPage={setContactsPage}
                contactsPageCount={contactsPageCount}
                purpose={purpose}
                fetchData={fetchData}
                executeSearch={executeSearch}
                keyword={keyword}
                setKeyword={setKeyword}
                dontshowRemoveRecipients={true}
                />
                <Grid container>
                  <Grid item md={10}>
                  </Grid>
                  <Grid item md={2}>
                    <LoadingButton
                        loading={addRecipientsLoading}
                        variant={"contained"}
                        sx={{
                          float:"right",
                        alignSelf: "flex-end",
                        height: 30,
                        bgcolor: "#5177FC",
                        ":hover": {
                            bgcolor: alpha("#5177FC", 0.9),
                        },
                        fontSize: 16,
                        fontWeight: 500,
                        padding: "8px 16px",
                        borderRadius: "8px",
                        textTransform: "capitalize",
                        }}
                        onClick={handleAddRecipients}
                    >
                        Add Recipients
                    </LoadingButton>
                  </Grid>
                </Grid>
                </Stack>
            </AppModal>
            <AppModal
            open={isUpdateAutomatedEmailModalOpen}
            title={"Edit Automated Email"}
            handleClose={handleCloseUpdateAutomatedEmailModal}
            maxWidth="xl"
            closeIcon
        >
                <Stack gap={1}>
                <Grid container>
                    <Grid item md={6} >
                      <FormControl
                          sx={{
                          "& .MuiInputLabel-root": {
                              fontSize: 14,
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                              border: 0,
                          },
                          }}
                      >
                          <TextField
                          fullWidth
                          label={"Name"}
                          title={"Name"}
                          required
                          inputProps={{ style: { fontSize: 14 } }}
                          InputLabelProps={{ style: { fontSize: 14 } }}
                          type={"text"}
                          value={name}
                          onChange={(e) => {
                              setName(e.target.value);
                              if (name !== "") setNameRequired(false);
                          }}
                          sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                              fontSize: 14,
                              border: 0,
                              },
                              "& .MuiOutlinedInput-input": {
                              padding: "14px",
                              },
                              "& .MuiOutlinedInput-root": {
                              borderRadius: "12px",
                              bgcolor: "#F7F7F7",
                              },
                          }}
                          />
                          <FormHelperText variant="outlined">
                          <Typography
                              component={"span"}
                              variant="caption"
                              textTransform={"capitalize"}
                              sx={{
                              opacity: nameRequired ? 1 : 0,
                              color: "red",
                              transition: "all ease-out 200ms",
                              }}
                          >
                              Name is Required.
                          </Typography>
                          </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item md={6} >
                        <FormControl
                            sx={{
                            "& .MuiInputLabel-root": {
                                fontSize: 14,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: 0,
                            },
                            }}
                        >
                            <Select
                    fullWidth
                    value={savedSearch}
                    onChange={handleSavedSearchChange}
                    displayEmpty
                    closeIcon
                    >
                    <MenuItem value="" disabled>
                        Choose a Saved Search
                    </MenuItem>
                    
                    {savedSearches.map(saved_search => (
                        
                        <MenuItem key={saved_search.id} value={saved_search.id}>{saved_search.name}</MenuItem>
                    ))}
                    </Select>
                            <FormHelperText variant="outlined">
                            <Typography
                                component={"span"}
                                variant="caption"
                                textTransform={"capitalize"}
                                sx={{
                                opacity: savedSearchRequired  ? 1 : 0,
                                color: "red",
                                transition: "all ease-out 200ms",
                                }}
                            >
                                Saved Search is Required.
                            </Typography>
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                <Recipients
                selectedGroups={selectedGroups}
                setSelectedGroups={setSelectedGroups}
                selectedContacts={selectedContacts}
                setSelectedContacts={setSelectedContacts}
                currentAutomatedEmail={currentAutomatedEmail}
                currentOpenedModal={currentOpenedModal}
                removeRecipientsLoading={removeRecipientsLoading}
                groups={groups}
                contacts={contacts}
                groupsPage={groupsPage}
                setGroupsPage={setGroupsPage}
                groupsPageCount={groupsPageCount}
                contactsPage={contactsPage}
                setContactsPage={setContactsPage}
                contactsPageCount={contactsPageCount}
                purpose={purpose}
                fetchData={fetchData}
                executeSearch={executeSearch}
                keyword={keyword}
                setKeyword={setKeyword}
                removeRecipients={removeRecipients}
                dontshowRemoveRecipients={false}
                />
                <Grid container>
                  <Grid item md={3}>
                    
                  </Grid>
                  <Grid item md={8}></Grid>
                  <Grid item md={1}>
                    <LoadingButton
                        loading={updateAutomatedEmailLoading}
                        variant={"contained"}
                        sx={{
                          float:"right",
                        alignSelf: "flex-end",
                        height: 30,
                        bgcolor: "#5177FC",
                        ":hover": {
                            bgcolor: alpha("#5177FC", 0.9),
                        },
                        fontSize: 16,
                        fontWeight: 500,
                        padding: "8px 16px",
                        borderRadius: "8px",
                        textTransform: "capitalize",
                        }}
                        onClick={handleUpdateAutomatedEmail}
                    >
                        Save
                    </LoadingButton>
                  </Grid>
                </Grid>
                </Stack>
            </AppModal>
            <AppModal
            open= {openRemoveAutomatedEmailConfirmModal}
            onClose={handleCloseRemoveAutomatedEmailConfirmModal}
            aria-labelledby="delete-confirmation-title"
            aria-describedby="delete-confirmation-description"
            >
            <Box >
                <Typography id="delete-confirmation-title" variant="h6" component="h2">
                Confirm Delete Automated Email
                </Typography>
                <Typography id="delete-confirmation-description" sx={{ mt: 2 }}>
                Are you sure you want to delete the selected automated emails?
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                <Button 
                    sx={{ mr: 1 }}
                    onClick={handleCloseRemoveAutomatedEmailConfirmModal}
                >
                    Cancel
                </Button>
                <Button 
                    variant="contained" 
                    color="error" 
                    onClick={handleRemoveAutomatedEmail}
                >
                    Delete
                </Button>
                </Box>
            </Box>
            </AppModal>
        </>
      ) : (
        <div className="centered-container">
          <img src="/assets/automated-emails.png" alt="Descriptive Text" />
          <h5>You don’t have any Automated Emails.</h5>
          <Button
            variant={"contained"}
            sx={{
              height: 30,
              bgcolor: "#5177FC",
              ":hover": {
                bgcolor: alpha("#5177FC", 0.9),
              },
              fontSize: 16,
              fontWeight: 500,
              padding: "2px 18px",
              marginTop: "1rem",
              borderRadius: "8px",
              textTransform: "capitalize",
            }}
            onClick={(e) => handleOpenAddAutomatedEmailModal()}
          >
            + Create New
          </Button>
        </div>
      )}
      <AppModal
        open={OpenAddAutomatedEmailModal}
        title={"Create New Automated Email"}
        handleClose={handleCloseAddAutomatedEmailModal}
        maxWidth="xl"
        closeIcon
      >
        <Stack gap={1}>
          <Grid container>
            <Grid item md={5} >
            <FormControl
                sx={{
                  "& .MuiInputLabel-root": {
                    fontSize: 14,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                  width:"100%"
                }}
              >
                <TextField
                  fullWidth
                  label={"Name"}
                  title={"Name"}
                  required
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  type={"text"}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    if (name !== "") setNameRequired(false);
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      fontSize: 14,
                      border: 0,
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "14px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                      bgcolor: "#F7F7F7",
                    },
                  }}
                />
                <FormHelperText variant="outlined">
                  <Typography
                    component={"span"}
                    variant="caption"
                    textTransform={"capitalize"}
                    sx={{
                      opacity: nameRequired ? 1 : 0,
                      color: "red",
                      transition: "all ease-out 200ms",
                    }}
                  >
                    Name is Required.
                  </Typography>
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={2}></Grid>
            <Grid item md={4} >
            <FormControl
                    sx={{
                    "& .MuiInputLabel-root": {
                        fontSize: 14,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                    },
                    width:"100%"
                    }}
                >
                    <Select
                    fullWidth
                    value={savedSearch}
                    onChange={handleSavedSearchChange}
                    displayEmpty
                    closeIcon
                    style={{ color: savedSearch === "" ? 'gray' : 'black'  }}
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        fontSize: 14,
                        borderRadius: "12px",
                        bgcolor: "#F7F7F7",
                      },
                    }}
                    >
                    <MenuItem value="" disabled style={{ color: savedSearch === "" ? 'lightgray' : 'black' }}>
                        Choose a Saved Search *
                    </MenuItem>
                    {savedSearches.map(saved_search => (
                        <MenuItem key={saved_search.id} value={saved_search.id}>{saved_search.name}</MenuItem>
                    ))}
                    </Select>
                    <FormHelperText variant="outlined">
                    <Typography
                        component={"span"}
                        variant="caption"
                        textTransform={"capitalize"}
                        sx={{
                        opacity: savedSearchRequired  ? 1 : 0,
                        color: "red",
                        transition: "all ease-out 200ms",
                        }}
                    >
                        Saved Search is Required.
                    </Typography>
                    </FormHelperText>
                </FormControl> 
            </Grid>
          </Grid>
          <Recipients
          selectedGroups={selectedGroups}
          setSelectedGroups={setSelectedGroups}
          selectedContacts={selectedContacts}
          setSelectedContacts={setSelectedContacts}
          currentAutomatedEmail={currentAutomatedEmail}
          currentOpenedModal={currentOpenedModal}
          groups={groups}
          contacts={contacts}
          groupsPage={groupsPage}
          setGroupsPage={setGroupsPage}
          groupsPageCount={groupsPageCount}
          contactsPage={contactsPage}
          setContactsPage={setContactsPage}
          contactsPageCount={contactsPageCount}
          purpose={purpose}
          fetchData={fetchData}
          executeSearch={executeSearch}
          keyword={keyword}
          setKeyword={setKeyword}
          dontshowRemoveRecipients={true}
          />
          <LoadingButton
            loading={addAutomatedEmailLoading}
            variant={"contained"}
            sx={{
              alignSelf: "flex-end",
              height: 30,
              bgcolor: "#5177FC",
              ":hover": {
                bgcolor: alpha("#5177FC", 0.9),
              },
              fontSize: 16,
              fontWeight: 500,
              padding: "8px 16px",
              borderRadius: "8px",
              textTransform: "capitalize",
            }}
            onClick={handleAddAutomatedEmail}
          >
            Save
          </LoadingButton>
        </Stack>
      </AppModal>
    </>
  );
};

export default AutomatedEmailsContent;
  