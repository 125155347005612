import React, { useState } from 'react';
import styles from './PasswordInput.module.css'; // Import the CSS module
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const PasswordInput = ({ password, setPassword }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState("");
  const [passwordValid, setPasswordValid] = useState({
    minChar: null,
    number: null,
    specialChar: null,
    upperCase: null,
    lowerCase: null,
    length: null,
    valid: null
  });

  const validatePassword = (value) => {
    setPasswordValid({
      minChar: value.length >= 8 && value.length <= 42,
      number: /\d/.test(value),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
      upperCase: /[A-Z]/.test(value),
      lowerCase: /[a-z]/.test(value),
      length: value.length > 0,
      valid: value.length >= 8 && value.length <= 42 && /\d/.test(value) && /[!@#$%^&*(),.?":{}|<>]/.test(value) && /[A-Z]/.test(value) && /[a-z]/.test(value)
    });
  };
  const determineStrength = (value) => {
    let strength = "";
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
    const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

    if(strongRegex.test(value)) {
      strength = "strong";
    } else if(mediumRegex.test(value)) {
      strength = "medium";
    } else {
      strength = "weak";
    }
    return strength;
  };
  const handleChange = (e) => {
    const { value } = e.target;
    setPassword(value);
    validatePassword(value);
    const strength = determineStrength(value);
    setPasswordStrength(strength);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onFocusIn = () => {
    setShowValidation(true);
  };

  const onFocusOut = () => {
    setShowValidation(false);
  };

  return (
    <div className={styles.passwordContainer}>
      <div className={styles.passwordInputWrapper}>
        <input
          type={showPassword ? 'text' : 'password'}
          className={`${styles.passwordInput} ${passwordValid.length === false ? styles.inputError : ''}`}
          value={password}
          onChange={handleChange}
          required
          onFocus={onFocusIn}
          onBlur={onFocusOut}
        />
        <span className={styles.eyeIcon} onClick={togglePasswordVisibility}>
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </span>
      </div>
      {(password.length > 0) && 
        <div className={`${styles.passwordStrengthIndicator} ${styles[passwordStrength]}`}>
            <div className={styles.passwordStrengthBar}></div>
        </div>
      }
      
      <div className={`${styles.passwordStrength} ${styles[passwordStrength]}`}>
        {password && passwordStrength.charAt(0).toUpperCase() + passwordStrength.slice(1)}
        </div>
      <div className={`${styles.passwordRequirements} ${passwordValid.valid ? styles.validPassword : styles.invalidPassword} ${showValidation ? styles.visible : styles.hidden}`}>
        <p className={`${styles.requirementsHeader} ${passwordValid.valid ? styles.validPassword : styles.invalidPassword}`}>Password requirements:</p>
        <ul className={styles.requirementsList}>
          <li className={passwordValid.lowerCase ? styles.valid : styles.invalid}>
            At least 1 lowercase letter
          </li>
          <li className={passwordValid.upperCase ? styles.valid : styles.invalid}>
            At least 1 uppercase letter
          </li>
          <li className={passwordValid.number ? styles.valid : styles.invalid}>
            At least 1 number
          </li>
          <li className={passwordValid.specialChar ? styles.valid : styles.invalid}>
            At least 1 special character
          </li>
          <li className={passwordValid.minChar ? styles.valid : styles.invalid}>
            Must have 8-42 characters
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PasswordInput;
