import { useNavigate } from "react-router-dom";
import { useCognitoUser } from "../../CognitoProvider";
import { useEffect } from "react";
import { PageLoader } from "../page-loader";

const UnSecureRoute = ({ children }) => {
  const { isSubscribed, isAuthenticated, userLoaded } = useCognitoUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && userLoaded) {
      if (isSubscribed){
        return navigate("/products", { replace: true });
      }
      else{
        return navigate("/subscription", { replace: true });
      }
    }
  }, [isSubscribed, isAuthenticated, userLoaded, navigate]);

  if (!userLoaded) {
    return <PageLoader />;
  }

  return children;
};

export default UnSecureRoute;
