import {
  Box,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import CustomAutocomplete from "../../../Autocomplete";
import CustomSelect from "../../../inputs/Select";
import Tags from "../../../inputs/Tags";
import DateRange from "../../../inputs/DateRange";
import { formatPriceToNumber, numberWithCommas } from "../../propertyReport";

const Label = ({ label, required, tooltip }) => {
  return (
    <Typography display={"flex"} fontSize={14} fontWeight={700}>
      {label}
      {required && (
        <Typography
          sx={{ color: "#FD5B5F", fontWeight: 700 }}
          component={"span"}
        >
          *
        </Typography>
      )}
      {tooltip && (
        <Tooltip
          title={<Typography fontSize={14}>{tooltip}</Typography>}
          placement="top"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#5177FC",
                textAlign: "center",
              },
            },
          }}
        >
          <img
            src={"/assets/tooltip.svg"}
            alt="tooltip"
            width={12}
            height={12}
            style={{
              cursor: "help",
              marginLeft: 4,
              marginBottom: 2,
              alignSelf: "center",
            }}
          />
        </Tooltip>
      )}
    </Typography>
  );
};
const Filters = ({ filters, onChange, errors }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Stack gap={1.5}>
          <Label label={"Location"} required />
          <CustomAutocomplete
            value={filters?.location}
            onChange={(items) => onChange("location", items)}
            error={errors?.location}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack gap={1.5}>
          <Label label={"Property Status"} required />
          <CustomSelect
            value={filters.propertyStatus}
            onChange={(val) => {
              if (val === "Sold") {
                onChange("priceFlexibilityScore.min", null);
                onChange("priceFlexibilityScore.max", null);
              }
              onChange("propertyStatus", val);
            }}
            items={[
              { label: "Active", value: "Active" },
              { label: "Pending", value: "Pending" },
              { label: "Sold", value: "Sold" },
            ]}
            error={errors?.propertyStatus}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack gap={1.5}>
          <Label
            label={"Deal Value"}
            tooltip={
              "Indicates the investment potential based on multiple property attributes."
            }
          />
          <Tags
            values={filters.investmentPotential}
            onChange={(items) => onChange("investmentPotential", items)}
            error={errors.investmentPotential}
            items={[
              { name: "Excellent" },
              { name: "High" },
              { name: "Good" },
              { name: "N/A" },
            ]}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack gap={1.5}>
          <Label
            label={
              filters.propertyStatus !== "Sold" ? "Days on Market" : "Sold Date"
            }
            required
          />
          {filters.propertyStatus !== "Sold" ? (
            <CustomSelect
              value={filters.dom}
              onChange={(val) => onChange("dom", val)}
              items={[
                { label: "1 Day", value: 1 },
                { label: "3 Days", value: 3 },
                { label: "1 Week", value: 7 },
                { label: "2 Weeks", value: 14 },
                { label: "1 Month", value: 30 },
                { label: "3 Months", value: 90 },
                { label: "6 Months", value: 180 },
              ]}
            />
          ) : (
            <DateRange
              values={{ from: filters.from, to: filters.to }}
              onChange={onChange}
              errors={{
                from: errors.from,
                to: errors.to,
              }}
            />
          )}
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack gap={1.5}>
          <Label label={"Property Condition"} />
          <Tags
            values={filters.propertyCondition}
            onChange={(item) => onChange("propertyCondition", item)}
            items={[
              { name: "Good" },
              { name: "Outdated" },
              { name: "Poor" },
              { name: "Unlivable" },
            ]}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack gap={1.5}>
          <Label label={"Property Type"} />
          <Tags
            values={filters.propertyType}
            onChange={(items) => onChange("propertyType", items)}
            items={[
              { name: "Detached" },
              { name: "Townhouse" },
              { name: "Condo/Co-op/Apartment" },
              { name: "Multi Family" },
              { name: "Other" },
            ]}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack gap={1.5}>
          <Label label={"Price Range"} />
          <Box
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            gap={2}
          >
            <TextField
              fullWidth
              label={filters.priceRange?.min ? "Min" : ""}
              title={filters.priceRange?.min ? "Min" : ""}
              placeholder={filters.priceRange?.min ? "" : "Min"}
              inputProps={{ style: { fontSize: 14 } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              value={numberWithCommas(filters.priceRange?.min ?? "")}
              error={!!errors?.priceRange?.min}
              helperText={errors?.priceRange?.min}
              onChange={(e) => {
                const price = formatPriceToNumber(e.target.value);
                if (price === "") {
                  onChange("priceRange.min", null);
                } else if (!isNaN(price)) {
                  onChange("priceRange.min", Number(price));
                }
              }}
              InputLabelProps={{ style: { fontSize: 14 } }}
              sx={{
                "& .MuiInputAdornment-root p": {
                  fontSize: 14,
                },
                "& .MuiOutlinedInput-input": {
                  padding: "14px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  fontSize: 14,
                  border: 0,
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "12px",
                  bgcolor: "#F7F7F7",
                },
                "& .MuiFormHelperText-root.Mui-error": {
                  color: "red",
                },
              }}
            />
            <TextField
              fullWidth
              label={filters.priceRange?.max ? "Max" : ""}
              title={filters.priceRange?.max ? "Max" : ""}
              placeholder={filters.priceRange?.max ? "" : "Max"}
              inputProps={{ style: { fontSize: 14 } }}
              InputLabelProps={{ style: { fontSize: 14 } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              value={numberWithCommas(filters.priceRange?.max ?? "")}
              error={!!errors?.priceRange?.max}
              helperText={errors?.priceRange?.max}
              onChange={(e) => {
                const price = formatPriceToNumber(e.target.value);
                if (price === "") {
                  onChange("priceRange.max", null);
                } else if (!isNaN(price) && price < 1000000000) {
                  onChange("priceRange.max", price);
                }
              }}
              sx={{
                "& .MuiInputAdornment-root p": {
                  fontSize: 14,
                },
                "& .MuiOutlinedInput-input": {
                  padding: "14px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  fontSize: 14,
                  border: 0,
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "12px",
                  bgcolor: "#F7F7F7",
                },
                "& .MuiFormHelperText-root.Mui-error": {
                  color: "red",
                },
              }}
            />
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack gap={1.5}>
          <Label
            label={"Price Flexibility Score"}
            tooltip={
              "Predicts how likely the property owner is willing to negotiate the price. A higher score indicates higher price flexibility. The score range is from 1 to 100."
            }
          />
          <Box
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            gap={2}
          >
            <TextField
              fullWidth
              label={"Min"}
              title={"Min"}
              disabled={filters.propertyStatus === "Sold"}
              inputProps={{ style: { fontSize: 14 } }}
              InputLabelProps={{ style: { fontSize: 14 } }}
              value={filters.priceFlexibilityScore?.min ?? ""}
              error={!!errors?.priceFlexibilityScore?.min}
              helperText={errors?.priceFlexibilityScore?.min}
              onChange={(e) => {
                const price = e.target.value;
                if (price === "") {
                  onChange("priceFlexibilityScore.min", null);
                } else {
                  !isNaN(price) &&
                    onChange("priceFlexibilityScore.min", Number(price));
                }
              }}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  fontSize: 14,
                  border: 0,
                },
                "& .MuiOutlinedInput-input": {
                  padding: "14px",
                },
                "& .MuiOutlinedInput-root .Mui-disabled": {
                  cursor: "not-allowed",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "12px",
                  bgcolor: "#F7F7F7",
                },
                "& .MuiFormHelperText-root.Mui-error": {
                  color: "red",
                },
              }}
            />
            <TextField
              fullWidth
              label={"Max"}
              title={"Max"}
              disabled={filters.propertyStatus === "Sold"}
              inputProps={{ style: { fontSize: 14 } }}
              InputLabelProps={{ style: { fontSize: 14 } }}
              value={filters.priceFlexibilityScore?.max ?? ""}
              onChange={(e) => {
                const price = e.target.value;
                if (price === "") {
                  onChange("priceFlexibilityScore.max", null);
                } else {
                  !isNaN(price) &&
                    onChange("priceFlexibilityScore.max", Number(price));
                }
              }}
              error={!!errors?.priceFlexibilityScore?.max}
              helperText={errors?.priceFlexibilityScore?.max}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  fontSize: 14,
                  border: 0,
                },
                "& .MuiOutlinedInput-input": {
                  padding: "14px",
                },
                "& .MuiOutlinedInput-root .Mui-disabled": {
                  cursor: "not-allowed",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "12px",
                  bgcolor: "#F7F7F7",
                },
                "& .MuiFormHelperText-root.Mui-error": {
                  color: "red",
                },
              }}
            />
          </Box>
        </Stack>
      </Grid>

      <Grid item xs={12} md={6}>
        <Stack gap={1.5}>
          <Label
            label={"Value Gap"}
            tooltip={
              "Spread divided by Listing Price. A higher value indicates better opportunity.  The range is from 1 to 100."
            }
          />

          <Box
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            gap={2}
          >
            <TextField
              fullWidth
              label={"Min"}
              title={"Min"}
              inputProps={{ style: { fontSize: 14 } }}
              InputLabelProps={{ style: { fontSize: 14 } }}
              value={filters.valueGap?.min ?? ""}
              error={!!errors?.valueGap?.min}
              helperText={errors?.valueGap?.min}
              onChange={(e) => {
                const price = e.target.value;
                if (price === "") {
                  onChange("valueGap.min", null);
                } else {
                  !isNaN(price) && onChange("valueGap.min", Number(price));
                }
              }}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  fontSize: 14,
                  border: 0,
                },
                "& .MuiOutlinedInput-input": {
                  padding: "14px",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "12px",
                  bgcolor: "#F7F7F7",
                },
                "& .MuiFormHelperText-root.Mui-error": {
                  color: "red",
                },
              }}
            />
            <TextField
              fullWidth
              label={"Max"}
              title={"Max"}
              inputProps={{ style: { fontSize: 14 } }}
              InputLabelProps={{ style: { fontSize: 14 } }}
              value={filters.valueGap?.max ?? ""}
              error={!!errors?.valueGap?.max}
              helperText={errors?.valueGap?.max}
              onChange={(e) => {
                const price = e.target.value;
                if (price === "") {
                  onChange("valueGap.max", null);
                } else {
                  !isNaN(price) && onChange("valueGap.max", Number(price));
                }
              }}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  fontSize: 14,
                  border: 0,
                },
                "& .MuiOutlinedInput-input": {
                  padding: "14px",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "12px",
                  bgcolor: "#F7F7F7",
                },
                "& .MuiFormHelperText-root.Mui-error": {
                  color: "red",
                },
              }}
            />
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack gap={1.5}>
          <Label
            label={"LP/FP"}
            tooltip={
              "Percentage of the listing price to the full potential. The range is from 1 to 100."
            }
          />

          <Box
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            gap={2}
          >
            <TextField
              fullWidth
              label={"Min"}
              title={"Min"}
              inputProps={{ style: { fontSize: 14 } }}
              InputLabelProps={{ style: { fontSize: 14 } }}
              value={filters.lpfp?.min ?? ""}
              error={!!errors?.lpfp?.min}
              helperText={errors?.lpfp?.min}
              onChange={(e) => {
                const price = e.target.value;
                if (price === "") {
                  onChange("lpfp.min", null);
                } else {
                  !isNaN(price) && onChange("lpfp.min", Number(price));
                }
              }}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  fontSize: 14,
                  border: 0,
                },
                "& .MuiOutlinedInput-input": {
                  padding: "14px",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "12px",
                  bgcolor: "#F7F7F7",
                },
                "& .MuiFormHelperText-root.Mui-error": {
                  color: "red",
                },
              }}
            />
            <TextField
              fullWidth
              label={"Max"}
              title={"Max"}
              inputProps={{ style: { fontSize: 14 } }}
              InputLabelProps={{ style: { fontSize: 14 } }}
              value={filters.lpfp?.max ?? ""}
              error={!!errors?.lpfp?.max}
              helperText={errors?.lpfp?.max}
              onChange={(e) => {
                const price = e.target.value;
                if (price === "") {
                  onChange("lpfp.max", null);
                } else {
                  !isNaN(price) && onChange("lpfp.max", Number(price));
                }
              }}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  fontSize: 14,
                  border: 0,
                },
                "& .MuiOutlinedInput-input": {
                  padding: "14px",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "12px",
                  bgcolor: "#F7F7F7",
                },
                "& .MuiFormHelperText-root.Mui-error": {
                  color: "red",
                },
              }}
            />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Filters;
