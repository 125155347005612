import React, { useState, useEffect } from 'react';
import { useCognitoUser } from '../../../CognitoProvider';
import LoadingButton from "@mui/lab/LoadingButton";
import { UpdateUserInfo } from '../../../services/api';
import {
  Box,
  Stack,
  TextField,
  Typography,
  alpha,
  FormControl,
  FormHelperText
} from "@mui/material";

const UpdateUserInfoComponent = () => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const { UpdateUser, currentPlan } = useCognitoUser();
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setShowError(false);
        setShowSuccess(false);
        try {
        await UpdateUser({
            "email":email,
            'given_name': firstName,
            'family_name': lastName,
        });
        const formData = new FormData();
        formData.append('email', email);
        formData.append('firstname', firstName);
        formData.append('lastname', lastName);
        formData.append('company_name', companyName);
        const response = await UpdateUserInfo(formData);
        console.log(response);
        setSuccessMessage("User information updated successfully");
        setShowSuccess(true);
        setIsLoading(false);
        setTimeout(() => {
            setShowSuccess(false);
        }, 10000);
        } catch (error) {
            console.log(error);
            if(error.message.includes("UsernameExistsException")){
                setErrorMessage("Email already exists");
            }
            else if(error.message.includes("InvalidParameterException")){
                setErrorMessage("Invalid email");
            }
            else if(error.message.includes("InvalidPasswordException")){
                setErrorMessage("Password must be at least 8 characters long, and must have uppercase, lowercase, number and special character");
            }
            else if(error.message.includes("LimitExceededException")){
                setErrorMessage("Attempt limit exceeded, please try after some time");
            }
            else if(error.message.includes("UserNotFoundException")){
                setErrorMessage("User not found");
            }
            else if(error.message.includes("NotAuthorizedException")){
                setErrorMessage("Incorrect current password");
            }
            else if(error.message.includes("PasswordResetRequiredException")){
                setErrorMessage("Password reset required");
            }
            else if(error.message.includes("InvalidParameterException")){
                setErrorMessage("Password must be at least 8 characters long, and must have uppercase, lowercase, number and special character");
            }
            else if(error.message.includes("LimitExceededException")){
                setErrorMessage("Attempt limit exceeded, please try after some time");
            }
            else if(error.message.includes("UserNotFoundException")){
                setErrorMessage("User not found");
            }
            else{
                setErrorMessage(error.message);
            }
            setShowError(true);
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        console.log('currentPlan ',currentPlan);

        setEmail(currentPlan.email);
        setFirstName(currentPlan.first_name);
        setLastName(currentPlan.last_name);
        setCompanyName(currentPlan.company_name);
    }, [currentPlan]);
    return (
        <Box
        sx={{
            width: "80%",
            maxWidth: "500px",
            mx: "auto",
            mt: "20px",
            mb: "20px",
            px: "20px",
        }}
        >
        <h3  style={{fontSize:"24px",fontWeight:800}}>
            Account
        </h3>
        <form onSubmit={handleSubmit} >
            <Stack spacing={2}>
            <TextField
                fullWidth
                label="First Name"
                variant="outlined"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                    fontSize: 14,
                    border: 0,
                },
                "& .MuiOutlinedInput-input": {
                    padding: "14px",
                },
                "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                    bgcolor: "#F7F7F7",
                },
                marginTop:"3rem !important"
                }}
            />
            <TextField
                fullWidth
                label="Last Name"
                variant="outlined"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                    fontSize: 14,
                    border: 0,
                },
                "& .MuiOutlinedInput-input": {
                    padding: "14px",
                },
                "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                    bgcolor: "#F7F7F7",
                },
                marginTop:"3rem !important"
                }}
            />
            <TextField
                fullWidth
                label="Email"
                variant="outlined"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                    fontSize: 14,
                    border: 0,
                },
                "& .MuiOutlinedInput-input": {
                    padding: "14px",
                },
                "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                    bgcolor: "#F7F7F7",
                },
                marginTop:"3rem !important"
                }}
            />
            <TextField
                fullWidth
                label="Company Name"
                variant="outlined"
                type="text"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                    fontSize: 14,
                    border: 0,
                },
                "& .MuiOutlinedInput-input": {
                    padding: "14px",
                },
                "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                    bgcolor: "#F7F7F7",
                },
                marginTop:"3rem !important"
                }}
            />
            <LoadingButton
                type="submit"
                variant="contained"
                loadingPosition="start"
                loading={isLoading}
                sx={{
                color: "#fff",
                backgroundColor: "#5177FC",
                "&:hover": {
                    backgroundColor: alpha("#5177FC", 0.8),
                },
                textTransform: "none",
                width:"100px",
                fontSize:"16px",
                marginTop:"4rem !important",
                borderRadius:"10px"
                }}
            >
                Save
            </LoadingButton>
            {showError && (
                <FormControl error>
                <FormHelperText>{errorMessage}</FormHelperText>
                </FormControl>
            )}
            {showSuccess && (
                <FormControl>
                <FormHelperText>{successMessage}</FormHelperText>
                </FormControl>
            )}
            </Stack>
        </form>
        </Box>
    );
};

export default UpdateUserInfoComponent;
