import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useCognitoUser } from "./CognitoProvider";
import { StripeProvider } from "./StripeProvider";
import { AuthenticationGuard } from "./components/authentication-guard";
import UnSecureRoute from "./components/cognito/UnSecureRoute";
import Settings from "./components/pages/settings/Settings";
import CognitoRedirection from "./components/pages/signup/CognitoRedirection";
import CognitoSignUp from "./components/pages/signup/CognitoSignUp";
import StripeProducts from "./components/stripe/StripeProducts";
import Sidebar from "./components/sidebar";
import SubscriptionPlans from "./components/pages/subscription/SubscriptionPlans";
import FullPropertyReport from "./components/pages/fullPropertyReport";
import Iframe from "./components/pages/iframe";
import PropertyReport from "./components/pages/propertyReport";
import TargetedLists from "./components/pages/targetedLists";
import DownloadedLists from "./components/pages/targetedLists/downloadedLists";
import SavedSearches from "./components/pages/targetedLists/savedSearches";
import Contacts from "./components/pages/targetedLists/contacts";
import Groups from "./components/pages/targetedLists/groups";
import AutomatedEmails from "./components/pages/targetedLists/automatedEmails";
import APIDocumentation from "./components/pages/apis/APIDocumentation";
import APIs from "./components/pages/apis";
import Login from "./components/pages/login/Login";
import EditSavedSearch from "./components/pages/targetedLists/editSavedSearch";
import Keys from "./components/pages/keys/Keys";
import Dashboard from "./components/pages/dashboard/Dashboard"; 
import Propeties from "./components/pages/properties/properties";
import OlderPropeties from "./components/pages/properties/old_properties";
import Demo from "./components/pages/fullPropertyReport/Demo";
import AutomatedEmailLogs from "./components/pages/targetedLists/automatedEmailLogs";
import FullReportHistory from "./components/pages/fullPropertyReport/History";
import WebsiteAuditReport from "./components/pages/websiteAuditReport";
import NewList from './components/pages/targetedLists/newList';
import ClientsAndGroups from './components/pages/targetedLists/contacts/clientsAndGroups'
import AutomatedEmailTabs from './components/pages/targetedLists/automatedEmails/automated_emails'
function App() {
  const { isAuthenticated: IsCognitoUserAuthenticated } = useCognitoUser();
  
  return (
    <div className="App">
      
      <header className="App-header">
        <BrowserRouter>
          <StripeProvider>
            <Routes>
              <Route
                path="/"
                element={
                  !IsCognitoUserAuthenticated ? (
                    <Navigate replace to={"products"} />
                  ) : (
                    <Navigate replace to={"subscription"} />
                  )
                }
              />

              {/* Auth */}
              <Route
                path="signup"
                element={
                  <UnSecureRoute>
                    <CognitoSignUp />
                  </UnSecureRoute>
                }
              />
              <Route
                path="login"
                element={
                  <UnSecureRoute>
                    <Login />
                  </UnSecureRoute>
                }
              />
              <Route
                path="login/redirection"
                element={<CognitoRedirection />}
              />

              {/* Products */}
              <Route path="products" element={<Sidebar />}>
                <Route index element={<Navigate to={"lists"} replace />} />
                <Route path="lists" element={<Navigate to={"new-search"} replace />} />
                <Route
                  path="lists/saved-searches"
                  element={<SavedSearches />}
                />
                <Route
                  path="lists/saved-searches/:id"
                  element={<EditSavedSearch />}
                />
                <Route
                  path="usage/download-history"
                  element={<DownloadedLists />}
                />
                <Route
                  path="lists/new-search"
                  element={<TargetedLists />}
                />
                <Route path="lists/clients-groups" element={<ClientsAndGroups />} />
                <Route path="lists/groups" element={<Groups />} />
                <Route
                  path="lists/automated-emails"
                  element={<AutomatedEmailTabs />}/>
                <Route
                  path="lists/automated-emails/logs"
                  element={<AutomatedEmailLogs />}/>
                <Route
                  path="integrations/full-report"
                  element={<FullPropertyReport />}
                />
                <Route 
                  path="full-property-report"
                  element={
                    <Navigate replace to={"demo"} />
                  }
                />
                <Route
                  path="full-property-report/demo"
                  element={<Demo />}
                />
                <Route
                  path="full-property-report/history"
                  element={<FullReportHistory />}
                />
                <Route path="usage" element={<Navigate replace to={"dashboard"}/>} />
                <Route path="usage/dashboard" element={<Dashboard />} />
                <Route path="integrations" element={<Navigate replace to={"full-report"}/>} />
                <Route path="integrations/keys" element={<Keys />} />
                <Route path="integrations/apis" element={<APIs />} />
                <Route
                  path="integrations/apis/documentation/:id"
                  element={<APIDocumentation />}
                />
              </Route>

              <Route
                path="settings"
                element={
                  <AuthenticationGuard>
                    <Settings />
                  </AuthenticationGuard>
                }
              />
              <Route path="subscription" element={<SubscriptionPlans />} />

              {/* Iframe with Report */}
              <Route path="iframe" element={<Iframe />} />
              <Route path="report" element={<PropertyReport />} />
              <Route path="websites/audit/report" element={<WebsiteAuditReport />} />

              {/* Not used */}
              <Route path="properties" element={<Propeties />} />
              <Route path="properties/older" element={<OlderPropeties />} />
              {/* Not used */}

              <Route path="product-select" element={<StripeProducts />} />
            </Routes>
          </StripeProvider>
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
