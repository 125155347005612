import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
const baseURL = process.env.REACT_APP_DEV_PORTAL_API_SERVER;

export const axiosInstance = axios.create({
  baseURL,
});

export const GetEndpoints = async () => {
  return await axiosInstance.get(`/endpoints/`);
};

export const GetEndpointDetails = async (id) => {
  return await axiosInstance.get(`/endpoints/${id}`);
};

export const RegisterUser = async (data) => {
  const formData = new FormData();
  formData.append("email", data.email);
  formData.append("cognito_username", data.cognito_username);
  formData.append("firstname", data.firstname);
  formData.append("lastname", data.lastname);
  if(data.free_trial){
    formData.append("free_trial", data.free_trial);
  }
  return await axiosInstance.post("/users/register/", formData);
};

export const GetUserSubscription = async () => {
  return await axiosInstance.get("/subscription/");
};

export const UpdateUserInfo = async (data) => {
  return await axiosInstance.put("/users/update/", data);
}

export const GetTrialInfo = async () => {
  axiosInstance.defaults.headers.common["Authorization"] = Cookies.get('id_token');
  return await axiosInstance.get("/subscription/trial/info/");
};

export const AddPaymentMethod = async (url) => {
  return await axiosInstance.post("/subscription/payment_method/add/?success_url="+url+"&cancel_url="+url);
};

export const GetDashboardData = async () => {
  axiosInstance.defaults.headers.common["Authorization"] = Cookies.get('id_token');
  return await axiosInstance.get("/dashboard/data/");
};

export const SubscribeFreeTrial = async () => {
  return await axiosInstance.post("/subscription/free_trial/subscribe/");
};

export const GetSubscriptions = async () => {
  return await axiosInstance.get("/subscription/plans/");
};

export const UpdateSubscription = async (plan) => {
  return await axiosInstance.post(`/subscription/update/`, { plan_id: plan });
};

export const CheckoutPlan = async (plan) => {
  return await axiosInstance.get(`/subscription/checkout/?plan_id=${plan}`);
};

export const ManageBilling = async (url) => {
  return await axiosInstance.get(
    `/subscription/manage/?return_url=${url}`
  );
};

export const GetTemplates = async () => {
  return await axiosInstance.get("/templates/dashboard/");
};

export const GetKeys = async () => {
  return await axiosInstance.get(`/keys/`);
};

export const GenerateKey = async () => {
  return await axiosInstance.get(`/keys/generate/?name=${uuidv4()}`);
};

export const RevealKey = async (id) => {
  return await axiosInstance.get(`/keys/reveal/?key_id=${id}`);
};

export const DeleteKey = async (id) => {
  return await axiosInstance.get(`/keys/delete/?key_id=${id}`);
};

// Targeted Marketing List

export const GetTargetedMarketingListCount = async (query) => {
  return await axiosInstance.get(`/searches/properties/count`, {
    params: query,
  });
};

export const SearchProperties = async (query) => {
  //add purchased_pages to query
  axiosInstance.defaults.headers.common["Authorization"] = Cookies.get('id_token');
  return await axiosInstance.get(`/searches/properties/`, {
    params: query,
  });
};

export const SaveTargetedMarketingList = async (data) => {
  return await axiosInstance.post(`/searches/saved/`, data);
};

export const GetSavedTargetedMarketingList = async (page) => {
  return await axiosInstance.get(`/searches/saved/?page=${page}`);
};
export const GetTargetedMarketingList = async (query) => {
  return await axiosInstance.get(`/searches/saved/`, {
    params: query,
  });
};

export const GetSingleTargetedMarketingList = async (id) => {
  return await axiosInstance.get(`/searches/saved/${id}`);
};

export const UpdateSingleSavedTargetedMarketingList = async (id, data) => {
  return await axiosInstance.put(`/searches/saved/${id}/`, data);
};

export const DeleteSingleTargetedMarketingList = async (id) => {
  return await axiosInstance.delete(`/searches/saved/${id}`);
};

// Get List
export const GetDownloadTargetedMarketingList = async (page) => {
  return await axiosInstance.get(
    `/searches/properties/downloads/?page=${page}`
  );
};
// Download
export const DownloadTargetedMarketingList = async (query) => {
  return await axiosInstance.get(`/searches/properties/download`, {
    params: query,
  });
};
// Update Name
export const UpdateDownloadTargetedMarketingList = async (data) => {
  return await axiosInstance.post(`/searches/properties/downloads/rename/`, data);
};

export const GetContacts = async (page) => {
  return await axiosInstance.get(`/crm/contacts/?page=${page}`);
};

export const AddContact = async (data) => {
  return await axiosInstance.post(`/crm/contacts/`, data);
};

export const UpdateContact = async (data) => {
  return await axiosInstance.put(`/crm/contacts/`, data);
};

export const RemoveContacts = async (selectedIDs) => {
  return await axiosInstance.delete(`/crm/contacts/?contact_ids=${selectedIDs}`);
};

export const GetContactDetails = async (id) => {
  return await axiosInstance.get(`/crm/contacts/${id}`);
};

export const SearchContacts = async (query,page) => {
  return await axiosInstance.get(`/crm/contacts/search/?keyword=${query}&page=${page}`);
}

export const AddToGroup = async (data, id) => {
  return await axiosInstance.post(`/crm/groups/${id}/contacts/add/`, data);
};

export const GetGroups = async (page) => {
  return await axiosInstance.get(`/crm/groups/?page=${page}`);
};

export const GetGroupsSimplified = async () => {
  return await axiosInstance.get(`/crm/groups/simplified/`);
};

export const GetGroupContacts = async (id, page) => {
  return await axiosInstance.get(`/crm/groups/${id}/contacts/?page=${page}`);
};

export const GetContactsNotInGroup = async (id, page) => {
  return await axiosInstance.get(`/crm/groups/${id}/contacts/notin/?page=${page}`);
};

export const SearchGroupContacts = async (id, query, page) => {
  return await axiosInstance.get(`/crm/groups/${id}/contacts/search/?keyword=${query}&page=${page}`);
};

export const SearchContactsNotInGroup = async (id, query, page) => {
  return await axiosInstance.get(`/crm/groups/${id}/contacts/notin/search/?keyword=${query}&page=${page}`);
};

export const AddGroup = async (data) => {
  return await axiosInstance.post(`/crm/groups/`, data);
};

export const UpdateGroup = async (data) => {
  return await axiosInstance.put(`/crm/groups/`, data);
};

export const DeleteGroup = async (groups) => {
  return await axiosInstance.delete(`/crm/groups/?group_ids=${groups}`);
};

export const RemoveGroupContacts = async (id, Data) => {
  return await axiosInstance.post(`/crm/groups/${id}/contacts/remove/`, Data);
};

export const GetGroupDetails = async (id) => {
  return await axiosInstance.get(`/crm/groups/${id}`);
};

export const GetAutomatedEmails = async (page) => {
  return await axiosInstance.get(`/automation/emails/?page=${page}`);
}

export const AddAutomatedEmail = async (data) => {
  return await axiosInstance.post(`/automation/emails/`, data);
}

export const GetAutomatedEmailDetails = async (id) => {
  return await axiosInstance.get(`/automation/emails/${id}`);
}

export const UpdateAutomatedEmail = async (data) => {
  return await axiosInstance.put(`/automation/emails/`, data);
}

export const RemoveAutomatedEmail = async (selectedIDs) => {
  return await axiosInstance.delete(`/automation/emails/?email_ids=`+selectedIDs);
}

export const GetSavedSearchesSimplified = async () => {
  return await axiosInstance.get(`/searches/saved/simplified/list/`);
};

export const UpdateAutomatedEmailStatus = async (id) => {
  return await axiosInstance.post(`/automation/emails/${id}/status/change/`);
};

export const GetAutomatedEmailGroups = async (id,page) => {
  return await axiosInstance.get(`/automation/emails/${id}/groups/?page=${page}`);
};
export const GetAutomatedEmailContacts = async (id,page) => {
  return await axiosInstance.get(`/automation/emails/${id}/contacts/?page=${page}`);
};

export const RemoveAutomatedEmailGroups = async (id,data) => {
  return await axiosInstance.post(`/automation/emails/${id}/groups/remove/`,data);
};
export const RemoveAutomatedEmailContacts = async (id,data) => {
  return await axiosInstance.post(`/automation/emails/${id}/contacts/remove/`,data);
};

export const AddAutomatedEmailGroups = async (id,data) => {
  return await axiosInstance.post(`/automation/emails/${id}/groups/add/`,data);
};
export const AddAutomatedEmailContacts = async (id,data) => {
  return await axiosInstance.post(`/automation/emails/${id}/contacts/add/`,data);
};

export const GetGroupsNotInAutomatedEmail = async (id,page) => {
  return await axiosInstance.get(`/automation/emails/${id}/groups/notin/?page=${page}`);
};
export const GetContactsNotInAutomatedEmail = async (id,page) => {
  return await axiosInstance.get(`/automation/emails/${id}/contacts/notin/?page=${page}`);
};

export const SearchContactsNotInAutomatedEmail = async (id,keyword,page) => {
  return await axiosInstance.get(`/automation/emails/${id}/contacts/notin/search/?keyword=${keyword}&page=${page}`);
};
export const SearchAutomatedEmailContacts = async (id,keyword,page) => {
  return await axiosInstance.get(`/automation/emails/${id}/contacts/search/?keyword=${keyword}&page=${page}`);
};

export const GetAutomatedEmailLogs = async (page) => {
  return await axiosInstance.get(`/automation/emails/logs/?page=${page}`);
};

export const GetFullReportsHistory = async(page) => {
  return await axiosInstance.get(`/report/?page=${page}`);
};

export const ReportBug = async(data) => {
  return await axiosInstance.post(`/bugs/`,data);
};

export const GetWebsiteAuditReportData = async(website) => {
  return await axiosInstance.get(`templates/audit/report/?website=${website}`);
};

