import { Box, Stack, Tooltip, Typography } from "@mui/material";

const Card = ({ title, value, tooltip, children }) => {
  return (
    <Box
      padding={2}
      bgcolor={"#FFF"}
      borderRadius={"12px"}
      display={"flex"}
      alignItems="center"
      justifyContent={"center"}
      textAlign={"center"}
      gap={"14px"}
    >
      <Stack spacing={"4px"}>
        <Box display="flex" alignItems="center" gap="6px">
          <Typography fontSize={14} fontWeight={500} sx={{ color: "#666666" }}>
            {title}
          </Typography>
          {tooltip && (
            <Tooltip
              title={<Typography fontSize={14}>{tooltip}</Typography>}
              placement="top"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#5177FC",
                    textAlign: 'center'
                  }
                }
              }}
            >
              <img
                src={"assets/tooltip.svg"}
                alt="tooltip"
                width={12}
                height={12}
                style={{ cursor: "help" }}
              />
            </Tooltip>
          )}
        </Box>
        <Typography fontSize={32} fontWeight={700} sx={{ color: "#202124" }}>
          {value}
        </Typography>
      </Stack>
      {children}
    </Box>
  );
};

export default Card;
