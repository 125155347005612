import React, { useEffect, useState } from "react";

import { GetEndpointDetails } from "../../../services/api";
import { CodeBlock, atomOneLight } from "react-code-blocks";
import Modal from "react-modal";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { AuthenticationGuard } from "../../authentication-guard";

function APIDocumentation() {
  const { id } = useParams();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(false);
  const [value, setValue] = useState();

  const [endpointDetails, setEndpointDetails] = useState({});

  const [copied, setCopied] = useState(false);
  const [activeEndpointExample, setActiveEndpointExample] = useState(0);

  Modal.setAppElement("#root");

  useEffect(() => {
    const fetchEndpointData = async () => {
      try {
        const response = await GetEndpointDetails(id);
        setActiveEndpointExample(0);
        setCopied(false);
        setEndpointDetails(response.data.endpoint);
      } catch (error) {
        setError(true);
      }
    };
    if (id) fetchEndpointData();
  }, [id]);

  function handleCopyToClipboard(code) {
    navigator.clipboard.writeText(code);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }

  function openModal(value) {
    setIsOpen(true);
    setValue(value);
  }

  function closeModal() {
    setIsOpen(false);
    setValue("");
  }


  if (error) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Typography fontSize={24} fontWeight={700}>
          No Data found
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {!!endpointDetails && (
        <div className="padding-sides endpointTitleContainer">
          <h1>{endpointDetails?.name}</h1>
          <div>
            {endpointDetails?.method && (
              <span className="method">{endpointDetails?.method}</span>
            )}
            {endpointDetails?.url && (
              <span className="url">{endpointDetails?.url}</span>
            )}
          </div>
        
        <p className="endpointDescription" dangerouslySetInnerHTML={{ __html: endpointDetails?.description }}>
        </p>
        <div className="endpointHeader">
          <span className="symbol">#</span>
          <span className="title">Examples</span>
        </div>

        <div className="endpointExampleTable">
          <div className="tableHeader">
            {endpointDetails?.code_snippets?.map((code, idx) => (
              <h2
                key={idx}
                className={`${
                  activeEndpointExample === idx && "active"
                } tableHeaderItem`}
                onClick={() => {
                  setActiveEndpointExample(idx);
                  setCopied(false);
                }}
              >
                {code?.programming_language}
              </h2>
            ))}
          </div>

          <div className="codeSnippetContainer">
            <CodeBlock
              customStyle={{
                borderRadius: 10,
              }}
              codeContainerStyle={{
                paddingTop: 20,
                height: 150,
                overflow: "auto",
                color: "black",
              }}
              text={
                endpointDetails?.code_snippets?.at(activeEndpointExample)
                  ?.code
              }
              theme={atomOneLight}
              showLineNumbers
              wrapLines
            />
          </div>

          <div className="tooltip" style={{ overflow: "visible" }}>
            {copied && (
              <span className="tooltiptext" style={{ left: -25, top: -20 }}>
                Copied to clipboard
              </span>
            )}
            <img
              className="codeSnippetFooter"
              src={"/assets/copy.svg"}
              alt="copy"
              width={19}
              height={19}
              onClick={() =>
                handleCopyToClipboard(
                  endpointDetails?.code_snippets?.at(activeEndpointExample)
                    ?.code
                )
              }
            />
          </div>
        </div>

        <div className="endpointHeader">
          <span className="symbol">#</span>
          <span className="title">Parameters</span>
        </div>

        <div className="parameterItemContainer">
          {endpointDetails?.parameters?.map((param, idx) => (
            <div key={idx} className="parameterItem">
              <span className="title">{param?.name}</span>
              <div className="subTitle">
                <span className="dataType">Type: {param?.data_type}</span>
                {param?.required && (
                  <span className="required">Required</span>
                )}
              </div>
              <span className="body">{param?.description}</span>
            </div>
          ))}
        </div>

        <div className="endpointHeader">
          <span className="symbol">#</span>
          <span className="title">Responses</span>
        </div>

        <div className="responseItemContainer">
          {endpointDetails?.response_templates?.map((response, idx) => (
            <div
              key={idx}
              className="responseItem"
              onClick={() => openModal(response?.value)}
            >
              <div className="titleContainer">
                <span className="title">{response?.description}</span>
                <span
                  className="statusCode"
                  style={{
                    color:
                      response?.status_code === 200 ? "var(--brand)" : "red",
                    borderColor:
                      response?.status_code === 200 ? "var(--brand)" : "red",
                  }}
                >
                  {response?.status_code}
                </span>
              </div>
            </div>
          ))}
        </div>
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Response Body"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            minWidth: "50%",
            maxWidth: "50%",
            maxHeight: "50%",
            overflow: "auto",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <span className="modalTitle">Response Body</span>
        <CodeBlock
          codeContainerStyle={{
            paddingTop: 20,
            height: 150,
            overflow: "auto",
            color: "black",
          }}
          customStyle={{
            borderRadius: 10,
          }}
          text={value}
          theme={atomOneLight}
          showLineNumbers
          wrapLines
        />
        <button className="button__close" onClick={closeModal}>
          Close
        </button>
      </Modal>
    </>
  );
}

export default APIDocumentation;
