import React from "react";

export const MobileMenuToggleButton = ({ icon, handleClick }) => {
  return (
    <span
      className="mobile-nav-bar__toggle material-icons"
      id="mobile-menu-toggle-button"
      onClick={handleClick}
    >
      <img src={icon} alt="navbar-icon" width={36} height={36}/>
    </span>
  );
};
