import LoadingButton from "@mui/lab/LoadingButton";
import AppModal from "../../../AppModal";
import {
  Stack,
  alpha,
} from "@mui/material";
import SearchField from "../components/SearchField";
import {useEffect, useState, useCallback } from "react";
import Grid from '@mui/material/Grid';
import {AddToGroup,GetContactsNotInGroup,SearchContactsNotInGroup} from "../../../../services/api";
import ContactsTable from "../contacts/ContactsTable";
const AddContactsModal = ({ open, onClose, refetch, group_id, name, currentOpenedModal,page, setPage, selectedIDs, setSelectedIDs}) => {
    const [addContactsLoading, setAddContactsLoading] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [keyword, setKeyword] = useState("");

    const handleSave = async () => {
        setAddContactsLoading(true);
        const formData = new FormData();
        formData.append("contact_ids", JSON.stringify(selectedIDs));
        const response = await AddToGroup(formData, group_id);
        if (response.status === 200){
            refetch();
            fetchContacts();
            setAddContactsLoading(false);
            setSelectedIDs([]);
        }
    };
    const fetchContacts = useCallback(async () => {
        if(keyword === ""){
            const response = await GetContactsNotInGroup(group_id,page);
            setContacts(response.data?.contacts);
            setPageCount(response.data?.total_pages);
            setPage(response.data?.page);
        }else{
            const response = await SearchContactsNotInGroup(group_id,keyword,page);
            setContacts(response.data?.contacts);
            setPageCount(response.data?.total_pages);
            setPage(response.data?.page);
        }
    }, [group_id,page,keyword, setPage, setPageCount]);

    const executeSearch = async (search) => {
        if (search === ""){
            fetchContacts();
            return;
        }
        const response = await SearchContactsNotInGroup(group_id,search,page);
        setContacts(response.data?.contacts);
        setPageCount(response.data?.total_pages);
        setPage(response.data?.page);
    };
    useEffect(() => {
        console.log("group_id:"+group_id);
        if ((group_id)&&(currentOpenedModal==="addContacts")) fetchContacts();
    }, [group_id, page, currentOpenedModal, fetchContacts]);




    return (
        <AppModal
          open={open}
          title={"Add Clients to Group: "+name}
          handleClose={onClose}
          maxWidth="xl"
          closeIcon
        >
          <Stack gap={0.5}>
            <Grid container>
                <Grid item md={9}>
                </Grid>
                <Grid item md={3}>
                <SearchField executeSearch={executeSearch} keyword={keyword} setKeyword={setKeyword} bgColor={"#FFF"} ></SearchField>
                </Grid>
            </Grid>
            <ContactsTable
                rows={contacts}
                page={page}
                setPage={setPage}
                pageCount={pageCount}
                selectedIDs={selectedIDs}
                setSelectedIDs={setSelectedIDs}
                ShowUpdate={false}
                removeBoxPadding={true}
                height={"calc(100vh - 450px)"}
            />
            <Grid container>
                <Grid item md={11}></Grid>
                <Grid item md={1}>
                    <LoadingButton
                    loading={addContactsLoading}
                    variant={"contained"}
                    sx={{
                        height: 30,
                        fontSize: 16,
                        fontWeight: 500,
                        padding: "2px 18px",
                        margin: "1rem",
                        float: "right",
                        borderRadius: "8px",
                        alignSelf: "flex-end",
                        bgcolor: "#5177FC",
                        ":hover": {
                        bgcolor: alpha("#5177FC", 0.9),
                        },
                        textTransform: "capitalize",
                    }}
                    onClick={handleSave}
                    >
                    Save
                    </LoadingButton>
                </Grid>
            </Grid>
          </Stack>
            </AppModal>
    );
};

export default AddContactsModal;