import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Grid,
  Button,
  Stack,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import PropertiesTable from "../../../table/PropertiesTable";
import { useFormik } from "formik";
import { isArray, omit } from "lodash";
import { useEffect, useState } from "react";
import * as yup from "yup";
import {
  DownloadTargetedMarketingList,
  GetTargetedMarketingListCount,
  SaveTargetedMarketingList,
  SearchProperties
} from "../../../../services/api";
import AppModal from "../../../AppModal";
import { numberWithCommas } from "../../propertyReport";
import Filters from "../components/Filters";
import { AuthenticationGuard } from "../../../authentication-guard";

function camelToUnderscore(key) {
  return key.replace(/([A-Z])/g, "_$1").toLowerCase();
}
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export const Label = ({ label, required }) => {
  return (
    <Typography display={"flex"} fontSize={14} fontWeight={700}>
      {label}
      {required && (
        <Typography
          sx={{ color: "#FD5B5F", fontWeight: 700 }}
          component={"span"}
        >
          *
        </Typography>
      )}
    </Typography>
  );
};

const DownloadTypeItem = ({ title, bgcolor, selected, onClick }) => {
  return (
    <Stack
      gap={1.5}
      alignItems={"center"}
      onClick={onClick}
      sx={{
        cursor: "pointer",
        padding: "6px",
        position: "relative",
        ":hover": {
          borderRadius: "4px",
          bgcolor: "rgba(0, 0, 0, 0.07)",
        },
      }}
    >
      {selected && (
        <img
          src={"/assets/selected.svg"}
          alt="selected"
          style={{ position: "absolute", top: 0, left: 0 }}
        />
      )}
      <Box
        sx={{
          width: "48px",
          height: "48px",
          borderRadius: "63px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor,
        }}
      >
        <img src={`/assets/${title.toLowerCase()}.svg`} alt={"type"} />
      </Box>
      <Typography fontSize={14} fontWeight={700}>
        {title}
      </Typography>
    </Stack>
  );
};

const NewList = ({ filters }) => {
  const [triggerScan, setTriggerScan] = useState(false);

  const [downloadModal, setDownloadModal] = useState(false);
  const [downloadType, setDownloadType] = useState("PDF");
  const [saveSearchModal, setSaveSearchModal] = useState(false);
  const [saveSearchName, setSaveSearchName] = useState("");

  const [filtersApplied, setFiltersApplied] = useState({});
  const [count, setCount] = useState(0);
  const [noData, setNoData] = useState(false);
  const [error, setError] = useState("");
  const [properties, setProperties] = useState([]);
  const [propertiesPage, setPropertiesPage] = useState(1);
  const [propertiesPageCount, setPropertiesPageCount] = useState(0);
  const [purchasedPages, setPurchasedPages] = useState([]);
  const [status, setStatus] = useState("");

  const [searchLoading, setSearchLoading] = useState(false);
  const [saveSearchLoading, setSaveSearchLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [isPurchased, setIsPurchased] = useState(false);
  const [purchasedID, setPurchasedID] = useState(null);
  const [downloadError, setDownloadError] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [noPropModal, setNoPropModal] = useState(false);
  const [resultsModal, setResultsModal] = useState(false);
  const [sortField, setSortField] = useState("list_date");
  const [sortDirection, setSortDirection] = useState("desc");

  const handleSaveSearchOpen = () => setSaveSearchModal(true);
  const handleSaveSearchClose = () => setSaveSearchModal(false);

  const handleDownloadOpen = () => setDownloadModal(true);
  const handleDownloadClose = () => setDownloadModal(false);
  const get_properties = async (page, first_time) => {
    const newValues = values.propertyStatus === "Sold"
      ? omit(values, ["dom", "priceFlexibilityScore"])
      : omit(values, ["from", "to"]);

    let filteredValues = Object.entries(newValues).reduce(
      (acc, [key, val]) => {
        const currKey = camelToUnderscore(key);
        if (typeof val === "string" && val)
          acc = { ...acc, [currKey]: val.toLowerCase() };
        if (typeof val === "number" && val) acc = { ...acc, [currKey]: val };
        if (
          isArray(val) &&
          val.length &&
          val.every((val) => typeof val !== "string")
        )
          acc = { ...acc, [currKey]: JSON.stringify(val) };
        if (
          isArray(val) &&
          val.every((val) => typeof val === "string") &&
          val.length
        ){
          if (key === "investmentPotential") {
            val = val.map((v) => (v === "N/A" ? "NP" : v));
          }
          acc = { ...acc, [currKey]: val.join("|") };
        }
        if (
          !isArray(val) &&
          typeof val === "object" &&
          Object.values(val).some(Boolean)
        ) {
          if (key === "priceRange") {
            for (let keyInVal in val) {
              acc = {
                ...acc,
                [`price_${keyInVal}`]: val[keyInVal],
              };
            }
          } else if (key === "priceFlexibilityScore") {
            for (let keyInVal in val) {
              acc = {
                ...acc,
                [`pfs_${keyInVal}`]: val[keyInVal],
              };
            }
          } else if (key === "lpfp") {
            for (let keyInVal in val) {
              acc = {
                ...acc,
                [`lpfp_${keyInVal}`]: val[keyInVal],
              };
            }
          } else {
            for (let keyInVal in val) {
              acc = {
                ...acc,
                [`gap_${keyInVal}`]: val[keyInVal],
              };
            }
          }
        }
        return acc;
      },
      {}
    );
    if(first_time){
      filteredValues = {...filteredValues, is_purchased:false, purchased_id:null, page: page};
    }
    else{
      filteredValues = {...filteredValues, is_purchased:isPurchased, purchased_id:purchasedID, page: page};
    }
    if(sortField && sortDirection){
      filteredValues = {...filteredValues, sort_by:sortField, sort_direction:sortDirection};
    }
    
    console.log(filteredValues);
    const response = await SearchProperties(filteredValues);
    setProperties(response.data?.data);
    setPropertiesPageCount(response.data?.pages_count);
    setPropertiesPage(response.data?.page);
    setStatus(response.data?.status);
    if(response.data?.purchase_id){
      setIsPurchased(true);
      setPurchasedID(response.data?.purchase_id);
    }
    //check if page not in purchased pages add it
    if(!purchasedPages.includes(response.data?.page)){
      setPurchasedPages([...purchasedPages,response.data?.page]);
    }
    
    setResultsModal(true);
  };
  const handleSearch = async (values) => {
    setIsPurchased(false);
    setPurchasedID(null);
    const newValues =
      values.propertyStatus === "Sold"
        ? omit(values, ["dom", "priceFlexibilityScore"])
        : omit(values, ["from", "to"]);

    const filteredValues = Object.entries(newValues).reduce(
      (acc, [key, val]) => {
        const currKey = camelToUnderscore(key);
        if (typeof val === "string" && val)
          acc = { ...acc, [currKey]: val.toLowerCase() };
        if (typeof val === "number" && val) acc = { ...acc, [currKey]: val };
        if (
          isArray(val) &&
          val.length &&
          val.every((val) => typeof val !== "string")
        )
          acc = { ...acc, [currKey]: JSON.stringify(val) };
        if (
          isArray(val) &&
          val.every((val) => typeof val === "string") &&
          val.length
        ){
          if (key === "investmentPotential") {
            val = val.map((v) => (v === "N/A" ? "NP" : v));
          }
          acc = { ...acc, [currKey]: val.join("|") };
        }
        if (
          !isArray(val) &&
          typeof val === "object" &&
          Object.values(val).some(Boolean)
        ) {
          if (key === "priceRange") {
            for (let keyInVal in val) {
              acc = {
                ...acc,
                [`price_${keyInVal}`]: val[keyInVal],
              };
            }
          } else if (key === "priceFlexibilityScore") {
            for (let keyInVal in val) {
              acc = {
                ...acc,
                [`pfs_${keyInVal}`]: val[keyInVal],
              };
            }
          } else if (key === "lpfp") {
            for (let keyInVal in val) {
              acc = {
                ...acc,
                [`lpfp_${keyInVal}`]: val[keyInVal],
              };
            }
          } else {
            for (let keyInVal in val) {
              acc = {
                ...acc,
                [`gap_${keyInVal}`]: val[keyInVal],
              };
            }
          }
        }
        return acc;
      },
      {}
    );
    
    setSearchLoading(true);
    try {
      setError("");
      const response = await GetTargetedMarketingListCount(filteredValues);
      setFiltersApplied(filteredValues);
      setNoData(false);
      if (response.data?.results_count) {
        setProperties([]);
        setPurchasedPages([]);
        setCount(response.data?.results_count);
        setIsPurchased(false);
        setPurchasedID(null);
        get_properties(1, true);
      } else {
        setCount(0);
        setNoData(true);
        setPropertiesPage(1);
        setPurchasedPages([]);
        setProperties([]);
        setIsPurchased(false);
        setPurchasedID(null);
        setNoPropModal(true);
      }
    } catch (error) {
      setError(error?.response?.data?.error);
      setErrorModal(true);
    } finally {
      setSearchLoading(false);
    }
  };

  const handleSaveSearch = async () => {
    setSaveSearchLoading(true);
    try {
      const formData = new FormData();
      Object.entries({
        ...filtersApplied,
        name: saveSearchName,
      }).map(([key, val]) => formData.append(key, val));
      await SaveTargetedMarketingList(formData);
      //resetForm();
      //setNoData(false);
      //setCount(0);
      handleSaveSearchClose();
      setSaveSearchName("");
    } catch (error) {
    } finally {
      setSaveSearchLoading(false);
    }
  };

  const handleDownload = async () => {
    setDownloadLoading(true);
    try {
      const response = await DownloadTargetedMarketingList({
        ...filtersApplied,
        file_type: downloadType.toLowerCase(),
        count,
        is_purchased:isPurchased,
        purchased_id:purchasedID,
      });
      if(response.data?.purchase_id){
        setIsPurchased(true);
        setPurchasedID(response.data?.purchase_id);
      }
      window.open(response.data.download_url);
      setDownloadType("PDF");
      handleDownloadClose();
    } catch (error) {
      setDownloadError(error.response.data.error);
    } finally {
      setDownloadLoading(false);
    }
  };

  const {
    values,
    errors,
    setValues,
    setFieldValue,
    handleSubmit,
    submitForm,
  } = useFormik({
    initialValues: {
      location: [],
      propertyStatus: "Active",
      investmentPotential: [],
      dom: 1,
      from: "",
      to: "",
      propertyCondition: [],
      propertyType: [],
      priceRange: {
        min: undefined,
        max: undefined,
      },
      priceFlexibilityScore: {
        min: undefined,
        max: undefined,
      },
      valueGap: {
        min: undefined,
        max: undefined,
      },
      lpfp:{
        min: undefined,
        max: undefined,
      }
    },
    validationSchema: yup.object({
      location: yup
        .array()
        .min(1, "Select at least one area.")
        .required()
        .label("Location"),
      propertyStatus: yup.string().required().label("Property Status"),
      from: yup
        .date()
        .when("propertyStatus", ([propertyStatus], schema) =>
          propertyStatus === "Sold"
            ? yup.date().required().label("From")
            : schema
        ),
      to: yup
        .date()
        .when("propertyStatus", ([propertyStatus], schema) =>
          propertyStatus === "Sold" ? yup.date().required().label("To") : schema
        ),
      investmentPotential: yup
        .array()
        .label("Deal Value"),
      dom: yup.number().required().label("DOM"),
      priceRange: yup
        .object({
          min: yup
            .number()
            .min(0)
            .max(
              999999999,
              `The minimum value must be greater or equal to ${numberWithCommas(
                999999999
              )}`
            )
            .label("Min")
            .nullable(),
          max: yup
            .number()
            .when("min", ([min], schema, opts) => {
              return min && !isNaN(opts.value) && opts.value <= 999999999
                ? min >= 999999999
                  ? schema
                      .min(
                        999999999,
                        `The maximum value can't be less than 999,999,999.`
                      )
                      .max(
                        999999999,
                        `The maximum value can't be higher than 999,999,999.`
                      )
                      .nullable()
                      .notRequired()
                  : schema
                      .min(1, `The maximum value must be greater or equal to 2`)
                      .max(
                        999999999,
                        `The maximum value can't be higher than 999,999,999.`
                      )
                      .moreThan(yup.ref("min"), ({ more }) =>
                        more >= 999999999
                          ? `The maximum value can't be higher than 999,999,999.`
                          : `The maximum value must be greater or equal to ${numberWithCommas(
                              more + 1
                            )}`
                      )
                      .nullable()
                      .notRequired()
                : schema
                    .max(
                      999999999,
                      `The maximum value can't be higher than 999,999,999.`
                    )
                    .nullable()
                    .notRequired();
            })
            .nullable()
            .notRequired(),
        })
        .label("Price Range"),
      priceFlexibilityScore: yup
        .object()
        .when("propertyStatus", ([propertyStatus], schema) =>
          propertyStatus === "Sold"
            ? schema
            : schema.shape({
                min: yup
                  .number()
                  .min(1, `The minimum value must be greater or equal to 1`)
                  .max(100, `The maximum value can't be higher than 100.`)
                  .nullable()
                  .notRequired()
                  .label("Min"),
                max: yup
                  .number()
                  .when("min", ([min], schema, opts) => {
                    return min && !isNaN(opts.value) && opts.value <= 100
                      ? min >= 100
                        ? schema
                            .min(
                              100,
                              `The maximum value can't be less than 100.`
                            )
                            .max(
                              100,
                              `The maximum value can't be higher than 100.`
                            )
                            .nullable()
                            .notRequired()
                        : schema
                            .min(
                              1,
                              `The maximum value must be greater or equal to 2`
                            )
                            .max(
                              100,
                              `The maximum value can't be higher than 100.`
                            )
                            .moreThan(yup.ref("min"), ({ more }) =>
                              more >= 100
                                ? `The maximum value can't be higher than 100.`
                                : `The maximum value must be greater or equal to ${
                                    more + 1
                                  }`
                            )
                            .nullable()
                            .notRequired()
                      : schema
                          .max(
                            100,
                            `The maximum value can't be higher than 100.`
                          )
                          .nullable()
                          .notRequired();
                  })
                  .nullable()
                  .notRequired(),
              })
        )
        .label("Price Flexibility Score"),
      valueGap: yup
        .object({
          min: yup
            .number()
            .min(1, `The minimum value must be greater or equal to 1`)
            .max(100, `The maximum value can't be higher than 100.`)
            .nullable()
            .notRequired()
            .label("Min"),
          max: yup
            .number()
            .when("min", ([min], schema, opts) => {
              return min && !isNaN(opts.value) && opts.value <= 100
                ? min >= 100
                  ? schema
                      .min(100, `The maximum value can't be less than 100.`)
                      .max(100, `The maximum value can't be higher than 100.`)
                      .nullable()
                      .notRequired()
                  : schema
                      .min(1, `The maximum value must be greater or equal to 2`)
                      .max(100, `The maximum value can't be higher than 100.`)
                      .moreThan(yup.ref("min"), ({ more }) =>
                        more >= 100
                          ? `The maximum value can't be higher than 100.`
                          : `The maximum value must be greater or equal to ${
                              more + 1
                            }`
                      )
                      .nullable()
                      .notRequired()
                : schema
                    .max(100, `The maximum value can't be higher than 100.`)
                    .nullable()
                    .notRequired();
            })
            .nullable()
            .notRequired(),
        })
        .label("Value Gap"),
      lpfp: yup
        .object({
          min: yup
            .number()
            .min(1, `The minimum value must be greater or equal to 1`)
            .max(100, `The maximum value can't be higher than 100.`)
            .nullable()
            .notRequired()
            .label("Min"),
          max: yup
            .number()
            .when("min", ([min], schema, opts) => {
              return min && !isNaN(opts.value) && opts.value <= 100
                ? min >= 100
                  ? schema
                      .min(100, `The maximum value can't be less than 100.`)
                      .max(100, `The maximum value can't be higher than 100.`)
                      .nullable()
                      .notRequired()
                  : schema
                      .min(1, `The maximum value must be greater or equal to 1`)
                      .max(100, `The maximum value can't be higher than 100.`)
                      .moreThan(yup.ref("min"), ({ more }) =>
                        more >= 100
                          ? `The maximum value can't be higher than 100.`
                          : `The maximum value must be greater or equal to ${
                              more + 1
                            }`
                      )
                      .nullable()
                      .notRequired()
                : schema
                    .max(100, `The maximum value can't be higher than 100.`)
                    .nullable()
                    .notRequired();
            })
            .nullable()
            .notRequired(),
        })
        .label("LP/FP"),
    }),
    onSubmit: handleSearch,
  });
  const getInvestmentPotential = (investment_potential) => {
    if (!investment_potential) return [];
    let potentials = investment_potential.split("|") ?? [];
    // if NP in potentials replace it with N/A
    if (potentials.includes("NP")) {
      potentials = potentials.map((potential) =>
        potential === "NP" ? "N/A" : potential
      );
    }
    return potentials;
  };
  useEffect(() => {
    if (filters) {
      setValues({
        location: JSON.parse(filters?.location) ?? [],
        propertyStatus:
          capitalizeFirstLetter(filters?.property_status) ?? "Active",
        investmentPotential: getInvestmentPotential(filters?.investment_potential),
        dom: Number(filters?.dom ?? 0),
        from: filters?.from_date ?? "",
        to: filters?.to_date ?? "",
        propertyCondition: filters?.property_condition?.split("|") ?? [],
        propertyType: filters?.property_type?.split("|") ?? [],
        priceRange: {
          min: filters?.price_min ?? null,
          max: filters?.price_max ?? null,
        },
        priceFlexibilityScore: {
          min: filters?.pfs_min ?? null,
          max: filters?.pfs_max ?? null,
        },
        valueGap: {
          min: filters.gap_min ?? null,
          max: filters.gap_max ?? null,
        },
        lpfp:{
          min: filters.lpfp_min ?? null,
          max: filters.lpfp_max ?? null,
        }
      });
      setTriggerScan(true);
    }
    
    if(count){
      get_properties(propertiesPage, false);
    }
  }, [filters, propertiesPage, sortField, sortDirection]);

  useEffect(() => {
    if (triggerScan) {
      setSearchLoading(true);
      setTimeout(() => submitForm(), 1000);
    }
  }, [triggerScan]);

  return (
    <AuthenticationGuard title={"to start using Targeting Marketing List"}>
      <div  className="padding-sides" style={{paddingBottom:"5rem"}}>
        <Stack gap={2}>
          <Typography fontSize={24} fontWeight={600}>
            Select Filters
          </Typography>

          <Filters
            filters={values}
            onChange={setFieldValue}
            errors={errors}
            handleSubmit={handleSubmit}
          />

          <Box
            display={"flex"}
            mt={2}
            flexDirection={{ xs: "column", md: "row" }}
            alignItems={{ md: "center" }}
            gap={1.5}
          >
            <Grid container>
              <Grid item xs={12} md={4}>
                <LoadingButton
                  loading={searchLoading}
                  variant={"contained"}
                  sx={{
                    height: 48,
                    bgcolor: "#5177FC",
                    ":hover": {
                      bgcolor: alpha("#5177FC", 0.9),
                    },
                    fontSize: 16,
                    fontWeight: 500,
                    padding: "8px 16px",
                    borderRadius: "8px",
                    textTransform: "capitalize",
                  }}
                  onClick={(e) => handleSubmit(e)}
                >
                  Search Properties
                </LoadingButton>
                <Button
                  variant={"text"}
                  sx={{
                    height: 48,
                    bgcolor: "#F0F0F0",
                    ":hover": {
                      bgcolor: alpha("#F0F0F0", 0.9),
                    },
                    fontSize: 16,
                    fontWeight: 500,
                    padding: "8px 16px",
                    borderRadius: "8px",
                    textTransform: "capitalize",
                    color: "#2C3D51",
                    marginLeft: "1rem",
                  }}
                  onClick={handleSaveSearchOpen}
                  disabled={Boolean(noData || !count)}
                >
                  Save Search
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                  {!!count && (
                    <Box
                      display={"flex"}
                      flexDirection={{ xs: "column", md: "row" }}
                      alignItems={"center"}
                      justifyContent={"center"}
                      gap={1.5}
                    >
                      <Typography fontSize={20} fontWeight={700} sx={{padding:"8px 16px",display:"inline-flex",gap:"8px",justifyContent: "center"}}>
                        {numberWithCommas(count)} Results
                      </Typography>
                      <Button
                        variant={"text"}
                        sx={{
                          bgcolor: "#3FCD7E",
                          ":hover": {
                            bgcolor: alpha("#3FCD7E", 0.9),
                          },
                          fontSize: 16,
                          fontWeight: 500,
                          padding: "8px 16px",
                          borderRadius: "40px",
                          textTransform: "capitalize",
                          color: "#fff",
                          alignItems: "center",
                          gap: 1,
                          lineHeight: "1.5",
                          marginRight: "1rem"
                        }}
                        onClick={() => setResultsModal(true)}
                      >
                        <Visibility sx={{width: "0.7em",fontWeight: 200}} /> View
                      </Button>
                      {!!count && (
                        <Button
                          variant={"text"}
                          sx={{
                            bgcolor: "#F0F0F0",
                            ":hover": {
                              bgcolor: alpha("#F0F0F0", 0.9),
                            },
                            fontSize: 16,
                            fontWeight: 500,
                            padding: "8px 16px",
                            borderRadius: "40px",
                            textTransform: "capitalize",
                            color: "#2C3D51",
                            alignItems: "center",
                            gap: 1,
                            lineHeight: "1.5",
                          }}
                          onClick={handleDownloadOpen}
                        >
                          <img src={"/assets/download.svg"} alt="download" /> Download
                        </Button>
                      )}
                    </Box>
                  )}
              </Grid>

            </Grid>
          </Box>

          <AppModal
            open={resultsModal}
            title={" "}
            handleClose={()=>setResultsModal(false)}
            maxWidth="xxl"
            closeIcon
          >
            <Stack gap={3}>
              <Stack gap={1.5}>
                
                <>
                  <Box
                    display={"flex"}
                    flexDirection={{ xs: "column", md: "row" }}
                    alignItems={"center"}
                    justifyContent={"center"}
                    gap={1.5}
                  >
                    <Typography fontSize={24}>
                      {numberWithCommas(count)} Properties Found.
                    </Typography>
                    <Button
                      variant={"text"}
                      sx={{
                        height: 48,
                        bgcolor: "#F0F0F0",
                        ":hover": {
                          bgcolor: alpha("#F0F0F0", 0.9),
                        },
                        fontSize: 16,
                        fontWeight: 500,
                        padding: "8px 16px",
                        borderRadius: "40px",
                        textTransform: "capitalize",
                        color: "#2C3D51",
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                      onClick={handleDownloadOpen}
                    >
                      <img src={"/assets/download.svg"} alt="download" /> Download
                    </Button>
                  </Box>
                  {(properties.length !== 0) && <PropertiesTable
                    rows={properties}
                    page={propertiesPage}
                    setPage={setPropertiesPage}
                    status = {status}
                    pageCount={propertiesPageCount}
                    height={"calc(100vh - 300px)"}
                    sortField={sortField}
                    setSortField={setSortField}
                    sortDirection={sortDirection}
                    setSortDirection={setSortDirection}
                  />}
                </>
              
              </Stack>
            </Stack>
          </AppModal>
          <AppModal
            open={noPropModal}
            title={" "}
            handleClose={()=>setNoPropModal(false)}
            maxWidth="xs"
            closeIcon
          >
            <Stack gap={3}>
              <Stack gap={1.5}>
                <span style={{fontSize:"14px",marginTop:"2rem",marginBottom:"2rem",textAlign:"center"}}>
                  No Properties found.
                </span>
              </Stack>
            </Stack>
          </AppModal>
          <AppModal
            open={errorModal}
            title={" "}
            handleClose={()=>setErrorModal(false)}
            maxWidth="xs"
            closeIcon
          >
            <Stack gap={3}>
              <Stack gap={1.5}>
                {error==="You need to subscribe to a plan to access this feature" ? (<span style={{fontSize:"14px",marginTop:"2rem",marginBottom:"2rem",textAlign:"center"}}>You need to <a href='../../subscription' style={{color:"#5177FC"}}>subscribe to a plan</a> to access this feature</span>) : <span style={{fontSize:"14px",marginTop:"2rem",marginBottom:"2rem",textAlign:"center"}}>error</span>}
              </Stack>
            </Stack>
          </AppModal>
          <AppModal
            open={saveSearchModal}
            title={"Save Search"}
            handleClose={handleSaveSearchClose}
            maxWidth="xs"
            closeIcon
          >
            <Stack gap={3}>
              <Stack gap={1.5}>
                <Label label={"Name"} required />
                <TextField
                  fullWidth
                  label={"Name"}
                  title={"Name"}
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  type={"text"}
                  value={saveSearchName}
                  onChange={(e) => setSaveSearchName(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      fontSize: 14,
                      border: 0,
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "14px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                      bgcolor: "#F7F7F7",
                    },
                  }}
                />
              </Stack>
              <LoadingButton
                loading={saveSearchLoading}
                variant={"contained"}
                sx={{
                  alignSelf: "flex-end",
                  height: 48,
                  bgcolor: "#5177FC",
                  ":hover": {
                    bgcolor: alpha("#5177FC", 0.9),
                  },
                  fontSize: 16,
                  fontWeight: 500,
                  padding: "8px 16px",
                  borderRadius: "8px",
                  textTransform: "capitalize",
                }}
                onClick={handleSaveSearch}
              >
                Done
              </LoadingButton>
            </Stack>
          </AppModal>
          <AppModal
            open={downloadModal}
            title={"Download"}
            handleClose={handleDownloadClose}
            maxWidth="xs"
            closeIcon
          >
            <Stack gap={3}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1.5}
              >
                <DownloadTypeItem
                  title={"Excel"}
                  bgcolor={"#00733B"}
                  onClick={() => setDownloadType("Excel")}
                  selected={downloadType === "Excel"}
                />
                <DownloadTypeItem
                  title={"PDF"}
                  bgcolor={"#E5252A"}
                  onClick={() => setDownloadType("PDF")}
                  selected={downloadType === "PDF"}
                />
                <DownloadTypeItem
                  title={"JSON"}
                  bgcolor={"#9777A8"}
                  onClick={() => setDownloadType("JSON")}
                  selected={downloadType === "JSON"}
                />
                <DownloadTypeItem
                  title={"CSV"}
                  bgcolor={"#00733B"}
                  onClick={() => setDownloadType("CSV")}
                  selected={downloadType === "CSV"}
                />
              </Box>

              <Typography color={"red"} textAlign={"center"} fontSize={14}>
                {downloadError}
              </Typography>

              <LoadingButton
                loading={downloadLoading}
                variant={"contained"}
                sx={{
                  alignSelf: "flex-end",
                  height: 48,
                  bgcolor: "#5177FC",
                  ":hover": {
                    bgcolor: alpha("#5177FC", 0.9),
                  },
                  fontSize: 16,
                  fontWeight: 500,
                  padding: "8px 16px",
                  borderRadius: "8px",
                  textTransform: "capitalize",
                }}
                onClick={handleDownload}
              >
                Download
              </LoadingButton>
            </Stack>
          </AppModal>
        </Stack>
      </div>
    </AuthenticationGuard>
  );
};

export default NewList;
