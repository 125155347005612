import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

const AppModal = ({
  title,
  open,
  handleClose,
  children,
  closeIcon, // works only if title is passed
  maxWidth = "sm",
  headerDivider = false,
  headerCenter = false,
  sx,
}) => {
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth={maxWidth}
      sx={{
        "& .MuiDialogContent-root": {
          p: { xs: "10px 20px", sm: "24px" },
        },
        ...sx,
      }}
    >
      {title && (
        <DialogTitle
          fontWeight={700}
          textTransform="capitalize"
          textAlign={headerCenter ? "center" : "start"}
        >
          {title}
          {closeIcon ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: "16px",
                top: "16px",
                p: 0,
                m: 1,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <img
                src={"/assets/remove-circle.svg"}
                alt="remove"
                width={18}
                height={18}
              />
            </IconButton>
          ) : null}
        </DialogTitle>
      )}
      <DialogContent dividers={headerDivider}>
        <Box py={1} width="100%">
          {children}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AppModal;
