import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Plot from 'react-plotly.js';
import Grid from '@mui/material/Grid';
import CreditCardIcon from '@mui/icons-material/CreditCard'; // Import specific icons
import CallIcon from '@mui/icons-material/Call';
import AssessmentIcon from '@mui/icons-material/Assessment';
import moment from 'moment'; // For handling dates
import { AuthenticationGuard } from "../../authentication-guard";
//import { min } from 'lodash';
const CognitoDashboard = ({ data, showSubscrbe}) => {
  const AreaUnderCurveChart = (data) => {
    if (data.activities_chart_data){
      const [dates, counts, tooltips] = data.activities_chart_data;
      // Calculate min and max dates
      const minDate = moment.min(dates.map(date => moment(date)));
      const maxDate = moment.max(dates.map(date => moment(date)));

      // Determine the range
      let startDate, endDate;
      let adjustedTooltips = [...tooltips]; // Copy original tooltips
      if (maxDate.diff(minDate, 'days') >= 7) {
        // If the difference is at least 7 days
        startDate = minDate.format('YYYY-MM-DD');
        endDate = maxDate.format('YYYY-MM-DD');
      } else {
        // Extend the range by 2 days on each side and adjust tooltips
        startDate = minDate.subtract(2, 'days').format('YYYY-MM-DD');
        endDate = maxDate.add(4, 'days').format('YYYY-MM-DD'); // Add 4 because 2 days were subtracted before
        // Add placeholder tooltips for the extra days
        adjustedTooltips.unshift('', ''); // Two empty tooltips at the start
        adjustedTooltips.push('', ''); // Two empty tooltips at the end
        console.log(adjustedTooltips);
      }
      // Create a list of all dates in the range
      let allDates = [];
      let currentDate = moment(startDate);
      while (currentDate <= moment(endDate)) {
        allDates.push(currentDate.format('YYYY-MM-DD'));
        currentDate = currentDate.add(1, 'days');
      }

      // Fill missing dates with zero values
      let filledCounts = allDates.map(date => {
        const index = dates.indexOf(date);
        return index !== -1 ? counts[index] : 0;
      });

      adjustedTooltips = allDates.map(date => {
        const index = dates.indexOf(date);
        return index !== -1 ? tooltips[index] : ''; // Return original tooltip if date is present
      });

      // Adjust marker sizes
      let markerSizes = filledCounts.map(count => (count === 0 ? 0 : 5)); // Set marker size to 0 for counts that are zero

      const trace = {
        x: allDates,
        y: filledCounts,
        type: 'scatter',
        mode: 'lines+markers',
        marker: {
          size: markerSizes,
          color: '#093EFB', // Points color
        },
        line: {
          color: '#5177FC', // Line color
          shape: 'spline'
        },
        fill: 'tozeroy',
        fillcolor: '#819CFD', // Shaded area color
        text: adjustedTooltips,
        hoverinfo: 'text+y',
      };

      const layout = {
        title: 'Activities Chart',
        xaxis: {
          type: 'date',
          range: [startDate, endDate],
        },
        yaxis: {
          title: 'Count',
        },
        width: 600,
      };
      //const config={modeBarButtonsToRemove: ['select2d','lasso2d','drawclosedpath']}
      const config = {
        displayModeBar: true,
        modeBarButtonsToRemove: ["pan2d", "select2d", "lasso2d", "autoScale2d", "resetScale2d", "hoverClosestCartesian", "hoverCompareCartesian", "zoom3d", "pan3d", "resetCameraDefault3d", "resetCameraLastSave3d", "hoverClosest3d", "orbitRotation", "tableRotation", "zoomInGeo", "zoomOutGeo", "resetGeo", "hoverClosestGeo", "toImage", "sendDataToCloud", "hoverClosestGl2d", "hoverClosestPie", "toggleHover", "resetViews", "toggleSpikelines", "resetViewMapbox"],
          // Other configurations
      };
      if(dates.length > 0){
        return <Plot data={[trace]} layout={layout} config={config} />;
      }else{ 
        const noDataLayout = {
          title: 'Activities Chart',
          xaxis: {
            type: 'date',
            range: [startDate, endDate],
          },
          yaxis: {
            title: 'Count',
          },
          width: 600,
          annotations: [
            {
              text: 'No data to visualize',
              xref: 'paper',
              yref: 'paper',
              showarrow: false,
              font: {
                size: 20,
                weight: 'bold',
              },
              x: 0.6,
              y: 0.7,
            },
          ],
        };
        return <Plot data={[{ type: 'scatter' }]} layout={noDataLayout} config={config} />;
      }
    }
    
  };

  const BarChart = (data) => {
    console.log(data.credits_chart_data);
      const [api_dates, api_creditsUsed] = data.apis_credits_chart_data;
      const [report_dates, report_creditsUsed] = data.reports_credits_chart_data;
      const [list_dates, list_creditsUsed] = data.searches_credits_chart_data;
      const isEmpty = api_dates.length === 0 && report_dates.length === 0 && list_dates.length === 0;
      const minDate = moment.min(api_dates.map(date => moment(date)));
      const maxDate = moment.max(api_dates.map(date => moment(date)));
      let startDate, endDate;
      if (maxDate.diff(minDate, 'days') >= 7) {
        // If the difference is at least 7 days
        startDate = minDate.format('YYYY-MM-DD');
        endDate = maxDate.format('YYYY-MM-DD');
      } else {
        // Extend the range by 2 days on each side and adjust tooltips
        startDate = minDate.subtract(2, 'days').format('YYYY-MM-DD');
        endDate = maxDate.add(4, 'days').format('YYYY-MM-DD'); // Add 4 because 2 days were subtracted before
      }
      // Calculate min and max dates
      //const allDates = api_dates.concat(report_dates).concat(list_dates);
      //const minDate = moment.min(allDates.map(date => moment(date)));
      //const maxDate = moment.max(allDates.map(date => moment(date)));
      // Separate data based on activity type
      let apiData = {
        x: api_dates,
        y: api_creditsUsed,
        name: 'APIs',
        type: 'bar',
        marker: {
          color: '#5177FC', // APIs color
        },
        hovertemplate: 'Date:%{x}<br>Credits Spent:%{y}<br>Activity:API calls<extra></extra>',
      };
      let reportData = {
        x: report_dates,
        y: report_creditsUsed,
        name: 'Reports',
        type: 'bar',
        marker: {
          color: '#7592FD', // Reports color
        },
        hovertemplate: 'Date:%{x}<br>Credits Spent:%{y}<br>Activity:Reports<extra></extra>',
      };
      let listData = {
        x: list_dates,
        y: list_creditsUsed,
        name: 'Lists',
        type: 'bar',
        marker: {
          color: '#9BB0FD', // Lists color
        },
        hovertemplate: 'Date:%{x}<br>Credits Spent:%{y}<br>Activity:Lists<extra></extra>',
      };
      
      const layout = {
        title: 'Credits Usage',
        barmode: 'stack',
        xaxis: {
          type: 'date',
          title: 'Date',
          tickformat: '%d %b', // Format for day and abbreviated month
        },
        yaxis: {
          title: 'Credits Used',
        },
        width: 600,
      };
    
      // Width of each bar
      const traces = [apiData, reportData, listData];

      const config = {
          displayModeBar: true,
          modeBarButtonsToRemove:  ["pan2d", "select2d", "lasso2d", "autoScale2d", "resetScale2d", "hoverClosestCartesian", "hoverCompareCartesian", "zoom3d", "pan3d", "resetCameraDefault3d", "resetCameraLastSave3d", "hoverClosest3d", "orbitRotation", "tableRotation", "zoomInGeo", "zoomOutGeo", "resetGeo", "hoverClosestGeo", "toImage", "sendDataToCloud", "hoverClosestGl2d", "hoverClosestPie", "toggleHover", "resetViews", "toggleSpikelines", "resetViewMapbox"],
      };
      if(!isEmpty){
        return <Plot data={traces} layout={layout} config={config} />;
      }else{ 
        const noDataLayout = {
          title: 'Credits Usage',
          xaxis: {
            type: 'date',
            range: [startDate, endDate],
          },
          yaxis: {
            title: 'Count',
          },
          width: 600,
          annotations: [
            {
              text: 'No data to visualize',
              xref: 'paper',
              yref: 'paper',
              showarrow: false,
              font: {
                size: 20,
                weight: 'bold',
              },
              x: 0.6,
              y: 0.7,
            },
          ],
        };
        return <Plot data={[{ type: 'scatter' }]} layout={noDataLayout} config={config} />;
      }
  };
  return (
    <AuthenticationGuard title={"to start using Dashboard"} showSubscrbe={showSubscrbe}>
      <div  className="padding-sides">
        <Grid container >
          <Grid item xs={12} sm={12} md={3} lg={3} style={{marginTop: "1rem"}}>
            <Card>
              <CardContent>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={6} sm={6} md={8} lg={10}>
                  <Typography color="textSecondary" gutterBottom>
                    Credits Balance
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {data.available_credits.toLocaleString()}/{data.total_credits.toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2}><CreditCardIcon style={{ fontSize: 40 }} /></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} style={{marginTop: "1rem"}}>
            <Card>
              <CardContent>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={6} sm={6} md={8} lg={10}>
                <Typography color="textSecondary" gutterBottom>
                  Total API Calls Made
                </Typography>
                <Typography variant="h5" component="h2">
                  {data.total_api_calls.toLocaleString()}
                </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2}><CallIcon style={{ fontSize: 40 }} /></Grid>
              </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} style={{marginTop: "1rem"}}>
            <Card>
              <CardContent>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={6} sm={6} md={8} lg={10}>
                <Typography color="textSecondary" gutterBottom>
                  Total Reports Generated
                </Typography>
                <Typography variant="h5" component="h2">
                  {data.total_reports_generated.toLocaleString()}
                </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2}><AssessmentIcon style={{ fontSize: 40 }} /></Grid>
              </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container  style={{marginTop: "1rem", marginBottom:"2rem"}}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {AreaUnderCurveChart(data)};
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {BarChart(data)};
          </Grid>
        </Grid>
      </div>
  </AuthenticationGuard>
  );
};

export default CognitoDashboard;