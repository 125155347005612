import React from "react";
import { useNavigate } from "react-router-dom";


const LoginButtonWithCognito = () => {
  const navigate = useNavigate();

  const handleLogin = async () => {
    navigate("/login");
  };

  return (
    <button className="button__login" onClick={handleLogin}>
      Log In
    </button>
  );
};

export const LoginButton = () => {
  return <LoginButtonWithCognito />;
};
