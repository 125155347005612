import React from "react";
import { NavLink } from "react-router-dom";
import ReportBugModal from "../../reportBug/modal"
import { useCognitoUser } from "../../../CognitoProvider";
export const NavBarBrand = () => {
  const { isAuthenticated } = useCognitoUser();
  const [reportModal, setReportModal] = React.useState(false);
  return (
    <div className="nav-bar__brand">
      <NavLink to="https://homesage.ai/">
        <img
          src="/logo.png"
          alt="Logo"
          width={150}
          height={20}
        />
      </NavLink>
      {isAuthenticated && 
        <button
          onClick={() => setReportModal(true)}
          style={{
            whiteSpace: "nowrap",
            lineHeight: "1px",
            backgroundColor: "#5177FC",
            height: "20px",
            borderRadius: "20px",
            color: "#fff",
            fontSize: "10px",
            padding: "10px",
            border: "none",
            marginLeft: "2rem",
            cursor: "pointer",
          }}
        >
          Report a bug
        </button>
      }
      
        <ReportBugModal
          show={reportModal}
          handleClose={() => setReportModal(false)}
        />
    </div>
  );
};
