import { Box, Stack, Tooltip, Typography } from "@mui/material";

const PropertyValueField = ({
  title,
  value,
  valueColor,
  tooltip,
  component,
}) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      gap={"8px"}
      padding={"16px"}
      width={"100%"}
      margin={"auto"}
    >
      <Stack alignItems={"start"} spacing={1}>
        <Box display="flex" alignItems="center" gap="6px">
          <Typography fontSize={12} fontWeight={500} sx={{ color: "#4E4E4E" }}>
            {title}
          </Typography>
          {tooltip && (
            <Tooltip
              title={<Typography fontSize={14}>{tooltip}</Typography>}
              placement="top"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#5177FC",
                    textAlign: 'center'
                  }
                }
              }}
            >
              <img
                src={"assets/tooltip.svg"}
                alt="tooltip"
                width={12}
                height={12}
                style={{ cursor: "help" }}
              />
            </Tooltip>
          )}
        </Box>
        <Box display="flex" alignItems="center" gap="22px">
          <Typography
            fontSize={"32px"}
            fontWeight={700}
            sx={{ color: valueColor }}
          >
            {value}
          </Typography>
          {component}
        </Box>
      </Stack>
    </Box>
  );
};

export default PropertyValueField;
