import { useState } from "react";
import { useCognitoUser } from "../../../CognitoProvider";
import Modal from "react-modal";

const SubscriptionPlanCard = (props) => {
  const { currentPlan } = useCognitoUser();
  const [modalIsOpen, setIsOpen] = useState(false);

  function handleConfirm() {
    props.onClick(props.plan.id);
    closeModal();
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  
  return (
    <div className="subscriptionCard">
      <div className="subscriptionCardContent">
        <span className="cardTitle">{props.plan.title}</span>
        {!props.plan.contact_us && (
          <span className="cardPrice">
            <span className="cardDollarSign">$</span>
            {props.plan.price}
          </span>
        )}

        <div className="cardItemsWrapper">
          {!props.plan.contact_us &&
            props.plan.items?.map((item, idx) => {
              return (
                <span className="cardItem" key={item}>
                  <img
                    src="assets/true.svg"
                    height={16}
                    width={16}
                    alt="mark"
                  />
                  {item}
                </span>
              );
            })}
        </div>
      </div>
      {props.plan.contact_us && <span className="contactUs">Contact Us</span>}
      {props.plan.contact_us &&
      <button
        className="cardBtn"
        onClick={() =>
          window.open("https://homesage.ai/contact/", "_self")
        }
      >
        Contact Us
      </button>}
      {!props.plan.contact_us &&
      <button
        className="cardBtn"
        onClick={() =>
          !!currentPlan?.plan_info?.id
            ? currentPlan?.plan_info?.id !== props.plan.id && openModal()
            : props.onClick(props.plan.id)
        }
      >
        {props.plan.contact_us
          ? "Contact Us"
          : currentPlan?.plan_info?.id === props.plan.id
          ? "Subscribed"
          : "Subscribe"}
        {currentPlan?.plan_info?.id !== props.plan.id && (
          <img
            src={"assets/right-arrow.svg"}
            alt="subscribe"
            width={14}
            height={14}
          />
        )}
      </button>
      }

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Confirmation"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            minWidth: "50%",
            maxWidth: "50%",
            maxHeight: "50%",
            overflow: "auto",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <span className="modalTitle">
          Please confirm the change of your subscription plan.
        </span>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 12,
          }}
        >
          <button className="button__close" onClick={handleConfirm}>
            Confirm
          </button>
          <button className="button__close" onClick={closeModal}>
            Close
          </button>
        </div>
      </Modal>
      
    </div>
  );
};

export default SubscriptionPlanCard;
