import React from "react";
import { MobileNavBarTab } from "./mobile-nav-bar-tab";
import { useCognitoUser } from "../../../CognitoProvider";

export const MobileNavBarTabs = ({ handleClick }) => {
  const { isAuthenticated: IsCognitoUserAuthenticated } =
    useCognitoUser();

  let isAuthenticated;

  isAuthenticated = IsCognitoUserAuthenticated;

  return (
    <div className="mobile-nav-bar__tabs">
      {isAuthenticated ? (
        <>
          <MobileNavBarTab
            path="/products"
            label="Products"
            handleClick={handleClick}
          />
          <MobileNavBarTab
            path="/settings"
            label="Settings"
            handleClick={handleClick}
          />
          <MobileNavBarTab
            path="/subscription"
            label="Plans"
            handleClick={handleClick}
          />
        </>
      ) : (
        <>
          <MobileNavBarTab
            path="/products"
            label="Products"
            handleClick={handleClick}
          />
          <MobileNavBarTab
            path="/subscription"
            label="Plans"
            handleClick={handleClick}
          />
        </>
      )}
    </div>
  );
};
