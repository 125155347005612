import { Box } from "@mui/material";
import AppTabs from "../../AppTabs";
import Dashboard from "../dashboard/Dashboard";
import Keys from "../keys/Keys";

const APIs = () => {
  return (
    <Box>
      <AppTabs
        sx={{
          "& .MuiTabs-flexContainer": {
            borderBottom: 0,
          },
        }}
        tabs={[
          { label: "Dashboard", component: <Dashboard /> },
          { label: "Keys", component: <Keys /> },
        ]}
      />
    </Box>
  );
};

export default APIs;
