import { Box, Typography } from "@mui/material";
const Field = ({ title, value }) => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Typography
        fontSize={14}
        fontWeight={500}
        sx={{ color: "#4E4E4E", width: "50%" }}
      >
        {title}
      </Typography>
      <Typography
        fontSize={16}
        fontWeight={700}
        sx={{ color: "#202124", wordBreak: 'break-all' }}
    
        title={value}
      >
        {value ? value : "N/A"}
      </Typography>
    </Box>
  );
};

export default Field;
