import { Box, Grid, Stack, Typography,Button,alpha } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PageLoader } from "../../page-loader";
import LoadingButton from "@mui/lab/LoadingButton";
import AppModal from "../../AppModal";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import PropertiesTable from "../../table/PropertiesTable";

const DownloadTypeItem = ({ title, bgcolor, selected, onClick }) => {
    return (
      <Stack
        gap={1.5}
        alignItems={"center"}
        onClick={onClick}
        sx={{
          cursor: "pointer",
          padding: "6px",
          position: "relative",
          ":hover": {
            borderRadius: "4px",
            bgcolor: "rgba(0, 0, 0, 0.07)",
          },
        }}
      >
        {selected && (
          <img
            src={"/assets/selected.svg"}
            alt="selected"
            style={{ position: "absolute", top: 0, left: 0 }}
          />
        )}
        <Box
          sx={{
            width: "48px",
            height: "48px",
            borderRadius: "63px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor,
          }}
        >
          <img src={`/assets/${title.toLowerCase()}.svg`} alt={"type"} />
        </Box>
        <Typography fontSize={14} fontWeight={700}>
          {title}
        </Typography>
      </Stack>
    );
};


export const numberWithCommas = (x) => {
  if (typeof x === "object" || !x) return "";
  if (x === 0) return "N/A";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatPriceToNumber = (price) => {
  return +price?.replace(/,/g, "");
};

export const formatPrice = (price = 0) => {
  const convertedPrice =
    typeof price === "string" ? parseInt(price, 10) : price;
  return convertedPrice
    ? `$${numberWithCommas(+convertedPrice.toFixed())}`
    : "N/A";
};

const Propeties = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [date, setDate] = useState("");
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [pagesCount, setPagesCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [status, setStatus] = useState("active");
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [downloadType, setDownloadType] = useState("Excel");
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [downloadError, setDownloadError] = useState("");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const checkOlderProperties = () => {
    window.open(`/properties/older?token=${searchParams.get("token")}`,"_self")
  };
  const handleDownload = async () => {
    setDownloadLoading(true);
    try {
        const token = searchParams.get("token");
        const response = await axios.get(`${process.env.REACT_APP_DEV_PORTAL_API_SERVER}/automation/emails/download/?file_type=${downloadType}`,
        { headers: { Authorization: `Bearer ${token}` } });
        window.open(response.data.link);
        setDownloadType("pdf");
        handleDownloadClose();
    } catch (error) {
        setDownloadError(error.response.data.error);
    } finally {
        setDownloadLoading(false);
    }
  };
  const handleDownloadOpen = () => {
        setDownloadError("");
        setDownloadType("pdf");
        setDownloadOpen(true);
  };
  const handleDownloadClose = () => {
        setDownloadOpen(false);
  };
  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      (async () => {
        try {
          setLoading(true);
          let url = `${process.env.REACT_APP_DEV_PORTAL_API_SERVER}/automation/emails/properties/?page=${page}`;
          if (sortField) {
            url += `&sort_by=${sortField}&sort_direction=${sortDirection}`;
          }
          const response = await axios.get(
            url,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          if (response.status === 200) {
            setRows(response.data.properties);
            setPagesCount(response.data.total_pages);
            setPage(response.data.page);
            setTotalCount(response.data.total_count);
            setDate(response.data.date);
            setStatus(response.data.status);
          } else {
            setError(true);
          }
        } catch (error) {
          setError(true);
        } finally {
          setLoading(false);
        }
      })();
    } else {
      setError(true);
    }
  }, [searchParams, page, sortField, sortDirection]);

  if (loading) {
    return <PageLoader />;
  }

  if (error)
    return (
      <Typography
        width={"100vw"}
        height={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        fontSize={24}
      >
        Invalid Token
      </Typography>
    );

  return (
    <Box bgcolor={"#F5F6FA"} height={"100vh"} overflow={"auto"} pb={10}>
      <Stack gap={3} maxWidth={"95%"} mx={"auto"} pt={3}>
        <Typography fontSize={24} fontWeight={700} textAlign={"center"} mb={1}>
        {status === "active" ? "New Listed Properties - "+date : "Recently Sold Properties - "+date} 
        </Typography>
        <Grid container>
            <Grid item xs={12} md={3}>
                <Typography fontSize={14} fontWeight={500} textAlign={"center"}>
                Results Count : {totalCount}  Properties
                </Typography>
            </Grid>
            <Grid item sx={12} md={6}></Grid>
            <Grid item sx={12} md={2}>
                <Button 
                variant={"text"}
                sx={{
                  float: "right",
                  height: 48,
                  fontSize: 16,
                  fontWeight: 500,
                  padding: "8px 16px",
                  borderRadius: "40px",
                  textTransform: "capitalize",
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
                onClick={checkOlderProperties} >
                    Check Older Properties
                </Button>
            </Grid>
            <Grid item xs={12} md={1} >
            <Button
            
                  variant={"text"}
                  sx={{
                    float: "right",
                    height: 48,
                    bgcolor: "#F0F0F0",
                    ":hover": {
                      bgcolor: alpha("#F0F0F0", 0.9),
                    },
                    fontSize: 16,
                    fontWeight: 500,
                    padding: "8px 16px",
                    borderRadius: "40px",
                    textTransform: "capitalize",
                    color: "#2C3D51",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                  onClick={handleDownloadOpen}
                >
                  <img src={"/assets/download.svg"} alt="download" /> Download
                </Button>
            </Grid>
        </Grid>
        <PropertiesTable 
            rows={rows} 
            pageCount={pagesCount} 
            totalCount={totalCount} 
            page={page} 
            setPage={setPage}
            status = {status} 
            height={"calc(100vh - 200px)"}
            sortField={sortField}
            setSortField={setSortField}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
        />
      </Stack>
      <AppModal
        open={downloadOpen}
        title={"Download"}
        handleClose={handleDownloadClose}
        maxWidth="xs"
        closeIcon
        >
        <Stack gap={3}>
            <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={1.5}
            >
            <DownloadTypeItem
                title={"Excel"}
                bgcolor={"#00733B"}
                onClick={() => setDownloadType("excel")}
                selected={downloadType === "excel"}
            />
            <DownloadTypeItem
                title={"PDF"}
                bgcolor={"#E5252A"}
                onClick={() => setDownloadType("pdf")}
                selected={downloadType === "pdf"}
            />
            <DownloadTypeItem
                title={"JSON"}
                bgcolor={"#9777A8"}
                onClick={() => setDownloadType("json")}
                selected={downloadType === "json"}
            />
            <DownloadTypeItem
                title={"CSV"}
                bgcolor={"#00733B"}
                onClick={() => setDownloadType("csv")}
                selected={downloadType === "csv"}
            />
            </Box>

            <Typography color={"red"} textAlign={"center"} fontSize={14}>
            {downloadError}
            </Typography>

            <LoadingButton
            loading={downloadLoading}
            variant={"contained"}
            sx={{
                alignSelf: "flex-end",
                height: 48,
                bgcolor: "#5177FC",
                ":hover": {
                bgcolor: alpha("#5177FC", 0.9),
                },
                fontSize: 16,
                fontWeight: 500,
                padding: "8px 16px",
                borderRadius: "8px",
                textTransform: "capitalize",
            }}
            onClick={handleDownload}
            >
            Download
            </LoadingButton>
        </Stack>
        </AppModal>
    </Box>
  );
};

export default Propeties;
