import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect } from "react";

const TabPanel = (props) => {
  const { children, value, index, sx, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ width: "100%" }}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: { xs: 1, sm: 2 }, ...sx }}>{children}</Box>
      )}
    </div>
  );
};

const a11yProps = (index, _active) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    sx: {
      textTransform: "capitalize",
      fontWeight: "500",
      fontSize: { xs: "12px", sm: "14px" },
    },
  };
};

const AppTabs = ({ tabs, tabValue, tabPanelStyle, sx, onChange }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (onChange) onChange(event, newValue);
  };

  useEffect(() => {
    if (tabValue) setValue(tabValue);
  }, [tabValue]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          "& .MuiTabs-flexContainer": {
            borderBottom: 1.5,
            borderColor: "divider",
          },
          "& .MuiTabs-indicator": {
            borderRadius: 2,
            overflow: "visible",
          },
          "& .MuiTab-root": { minWidth: 45 },
          "& .Mui-selected": {
            color: "#5177FC !important",
            fontWeight: 700
          }
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          scrollButtons
          sx={sx}
        >
          {tabs.map((tab, index) => (
            <Tab
              label={tab.label}
              onClick={tab.onClick}
              {...a11yProps(index, value === index)}
              key={index}
            />
          ))}
        </Tabs>
      </Box>
      <>
        {tabs.map((tab, index) => (
          <TabPanel value={value} sx={tabPanelStyle} index={index} key={index}>
            {tab.component}
          </TabPanel>
        ))}
      </>
    </Box>
  );
};

export default AppTabs;
