import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box } from "@mui/material";
import dayjs from "dayjs";
const DateRange = ({ values, onChange, errors }) => {
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);

  React.useEffect(() => {
    if (values.from) {
      setFrom(dayjs(values.from));
    }
    if (values.to) {
      setTo(dayjs(values.to));
    }
  }, [values.from, values.to]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent={"space-between"}
        gap={1}
      >
        <DatePicker
          disableFuture
          onAccept={(val) => onChange("from", val.format("YYYY-MM-DD"))}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-notchedOutline": {
              fontSize: 14,
              border: 0,
            },
            "& .MuiOutlinedInput-input": {
              padding: "14px",
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              bgcolor: "#F7F7F7",
            },
          }}
          slotProps={{
            textField: { error: !!errors?.from, helperText: errors?.from },
          }}
          label="From"
          value={from}
          onChange={(newValue) => {
            setFrom(newValue);
            if (newValue?.isValid())
              onChange("from", newValue.format("YYYY-MM-DD"));
          }}
        />
        <DatePicker
          disableFuture
          onAccept={(val) => onChange("to", val.format("YYYY-MM-DD"))}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-notchedOutline": {
              fontSize: 14,
              border: 0,
            },
            "& .MuiOutlinedInput-input": {
              padding: "14px",
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              bgcolor: "#F7F7F7",
            },
          }}
          slotProps={{
            textField: { error: !!errors?.to, helperText: errors?.to },
          }}
          label="To"
          value={to}
          onChange={(newValue) => {
            setTo(newValue);
            if (newValue?.isValid())
              onChange("to", newValue.format("YYYY-MM-DD"));
          }}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default DateRange;
