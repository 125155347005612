import {
  Autocomplete,
  Box,
  CircularProgress,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { debounce } from "lodash";
import { axiosInstance } from "../../services/api";

const CustomAutocomplete = ({ value, onChange, error }) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [selectedOption, setSelectedOption] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("");

  const onChangeAutocompleteOptions = (items) => {
    onChange && onChange(items);
    setSelectedOption(items);
  };

  React.useEffect(() => {
    if (text) {
      (async () => {
        setLoading(true);
        try {
          const response = await axiosInstance.get(
            `/area/autocomplete/?input=${text}`
          );
          setOptions(response.data?.data);
        } catch (error) {}
        setLoading(false);
      })();
    }
  }, [text]);

  React.useEffect(() => {
    if (value) {
      setSelectedOption(value);
    }
  }, [value]);

  return (
    <Box sx={{ width: "100%" }}>
      <Autocomplete
        open={open}
        sx={{
          "& .MuiAutocomplete-tag": {
            fontSize: 12,
          },
        }}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        multiple
        isOptionEqualToValue={(option, value) =>
          option?.text ? option.text === value?.text : option?.id === value?.id
        }
        getOptionLabel={(option) => option.text}
        options={options}
        loading={loading}
        value={selectedOption}
        onChange={(_, v, reason) => onChangeAutocompleteOptions(v, reason)}
        noOptionsText={
          <Typography
            display={"flex"}
            textAlign={"center"}
            gap={1}
            fontSize={14}
            justifyContent={"center"}
            alignItems={"center"}
          >
            No Data found
          </Typography>
        }
        loadingText={
          <Typography
            display={"flex"}
            textAlign={"center"}
            gap={1}
            fontSize={14}
            justifyContent={"center"}
            alignItems={"center"}
          >
            Loading...
          </Typography>
        }
        onInputChange={debounce((_, v) => setText(v), 500)}
        renderOption={(props, option, { selected }) => {
          const newProps = {
            ...props,
            key: option?.text ? option.text : option?.id,
          };
          return (
            <li
              {...newProps}
              style={{
                fontSize: 14,
              }}
            >
              {option.text}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select City, Zip Code, County or State"
            title="Select City, Zip Code, County or State"
            InputProps={{
              ...params.InputProps,
              style: { fontSize: 14 },
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            InputLabelProps={{
              style: {
                fontSize: 14,
                color: 400,
              },
            }}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: 0,
                fontSize: 14,
                fontWeight: 700,
              },
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#F7F7F7",
                borderRadius: "8px",
                padding: "8px 4px",
              },
            }}
          />
        )}
      />
      <FormHelperText variant="outlined">
        <Typography
          component={"span"}
          variant="caption"
          sx={{
            opacity: error ? 1 : 0,
            color: "red",
            transition: "all ease-out 200ms",
          }}
        >
          {error}
        </Typography>
      </FormHelperText>
    </Box>
  );
};

export default CustomAutocomplete;
