import React from "react";
import { NavLink } from "react-router-dom";

export const MobileNavBarBrand = ({ handleClick }) => {
  return (
    <div onClick={handleClick} className="mobile-nav-bar__brand">
      <NavLink to="/">
        <img
          src="/logo.png"
          alt="Logo"
          width={48}
          height={48}
        />
      </NavLink>
    </div>
  );
};
