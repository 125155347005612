import { useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import styled from "@emotion/styled";
import {
  Box,
  ClickAwayListener,
  Grid,
  Popper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Iframe from "../iframe";
import { ChromePicker as ColorPicker } from "react-color";
import { omit } from "lodash";
import { useCognitoUser } from "../../../CognitoProvider";
import { AuthenticationGuard } from "../../authentication-guard";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#5177FC",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#5177FC",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const CustomField = ({
  title,
  value,
  onChange,
  boldTitle = true,
  startAdorn,
  type,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      width={"100%"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      m={0}
      p={0}
    >
      <Typography fontWeight={boldTitle ? 700 : 400} fontSize={12}>
        {title}
      </Typography>
      <TextField
        type={type}
        size="small"
        value={value}
        onChange={(e) =>
          type === "text"
            ? onChange(e.target.value)
            : onChange(Number(e.target.value))
        }
        inputProps={{ style: { fontSize: 12 } }}
        InputLabelProps={{ style: { fontSize: 12 } }}
        InputProps={{
          startAdornment: startAdorn && (
            <Stack position={"relative"}>
              <Box
                sx={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "2px",
                  bgcolor: value,
                  cursor: "pointer",
                  boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25)",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick(e);
                }}
              />
              <ClickAwayListener onClickAway={() => handleClick()}>
                <Popper
                  open={open}
                  anchorEl={anchorEl}
                  placement="bottom-start"
                >
                  <ColorPicker
                    disableAlpha
                    color={value}
                    onChange={(color) => onChange(color.hex)}
                  />
                </Popper>
              </ClickAwayListener>
            </Stack>
          ),
        }}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": { fontSize: 12 },
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            bgcolor: "#F7F7F7",
            width: 110,
          },
          "& .MuiOutlinedInput-input": {
            textIndent: startAdorn ? 5 : 0,
          },
        }}
      />
    </Box>
  );
};

const FullPropertyReport = () => {
  const [urlCopied, setUrlCopied] = useState(false);
  const { isAuthenticated } = useCognitoUser();

  const [url, setUrl] = useState("Generated URL");

  const [data, setData] = useState({
    backendUrl: "",
    customToken: false,
    token: "",
    tokenKey: "",
    tokenValue: "",
  });

  const [settings, setSettings] = useState({
    backgroundBgcolor: "#FFFFFF",
    autocompleteHeight: 48,
    autocompleteWidth: 410,
    autocompleteFontSize: 14,
    autocompleteFontWeight: 400,
    autocompleteFontColor: "#202124",
    autocompleteBgcolor: "#F7F7F7",
    buttonHeight: 48,
    buttonWidth: 127,
    buttonFontSize: 14,
    buttonFontWeight: 500,
    buttonFontColor: "#FFFFFF",
    buttonBgcolor: "#5177FC",
  });

  const handleCopied = () => {
    setUrlCopied(true);
    navigator.clipboard.writeText(url);
    setTimeout(() => {
      setUrlCopied(false);
    }, 2000);
  };

  const onHandleChangeSetting = (key, value) => {
    setSettings((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    setUrl(
      `${process.env.REACT_APP_DEV_PORTAL}/iframe?${new URLSearchParams({
        ...settings,
        ...omit(
          data,
          data.customToken
            ? ["customToken", "token"]
            : ["customToken", "tokenKey", "tokenValue"]
        ),
      }).toString()}`
    );
  }, [data, settings]);

  return (
    <AuthenticationGuard title={"to start using Full Property Report"} >
      <div  className="padding-sides" >
        <span className="propertyReportTitle">Full Property Report</span>
        <p className="propertyReportDescription">
          In this section you can customize the appearance of this element on your website.
        </p>

        <span className="iframeURL">Iframe URL</span>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 8,
            marginTop: 12,
            marginBottom: 24,
          }}
        >
          <p
            className="urlWrapper"
            style={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}
          >
            {url}
          </p>
          <div className="tooltip">
            {urlCopied && (
              <span className="tooltiptext" style={{ left: -11 }}>
                Copied to clipboard
              </span>
            )}
            <button
              className="button__primary"
              style={{
                width: "100px",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 16,
                fontWeight: 500,
                height: 48,
                gap: 8,
              }}
              onClick={handleCopied}
              disabled={!isAuthenticated}
            >
              Copy
              <img
                src={"/assets/copy.svg"}
                alt="copy"
                width={16}
                height={16}
                style={{ filter: "brightness(0) invert(1)" }}
              />
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 24,
            padding: "24px 16px",
            borderRadius: "16px",
            backgroundColor: "#F7F7F7",
          }}
        >
          <p className="iframeCustomTitle">Customizing Iframe</p>
          <Box
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent={"center"}
            gap={3}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              width={{ xs: "100%", md: "50%" }}
            >
              {data.customToken ? (
                <Box display={"flex"} justifyContent={"center"} gap={2}>
                  <TextField
                    fullWidth
                    size="small"
                    label={"Token Key"}
                    title={"Token Key"}
                    value={data.tokenKey}
                    onChange={(e) =>
                      setData((prev) => ({
                        ...prev,
                        tokenKey: e.target.value,
                      }))
                    }
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": { fontSize: 14 },
                      "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                    }}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    label={"Token Value"}
                    title={"Token Value"}
                    value={data.tokenValue}
                    onChange={(e) =>
                      setData((prev) => ({
                        ...prev,
                        tokenValue: e.target.value,
                      }))
                    }
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": { fontSize: 14 },
                      "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                    }}
                  />
                </Box>
              ) : (
                <TextField
                  fullWidth
                  label={"Backend Token"}
                  title={"Backend Token"}
                  size="small"
                  value={data.token}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      token: e.target.value,
                    }))
                  }
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": { fontSize: 14 },
                    "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                  }}
                />
              )}
              <FormControlLabel
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: 0,
                  ml: 0,
                }}
                onChange={(_, c) =>
                  setData((prev) => ({ ...prev, customToken: c }))
                }
                control={<IOSSwitch sx={{ m: 1 }} checked={data.customToken} />}
                label={
                  <Typography fontSize={14} fontWeight={500} color={"#808183"}>
                    Custom Token
                  </Typography>
                }
                labelPlacement="start"
              />
            </Box>
            <Box width={{ xs: "100%", md: "50%" }}>
              <TextField
                fullWidth
                label={"Backend URL"}
                title={"Backend URL"}
                size="small"
                value={data.backendUrl}
                onChange={(e) =>
                  setData((prev) => ({ ...prev, backendUrl: e.target.value }))
                }
                inputProps={{ style: { fontSize: 14 } }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": { fontSize: 14 },
                  "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                }}
              />
            </Box>
          </Box>

          <Box
            borderRadius={"12px"}
            bgcolor={"rgba(0, 0, 0, 0.02)"}
            width={"100%"}
            height={"100%"}
            overflow={"auto"}
            display={"flex"}
            flexDirection={"column"}
            gap={"26px"}
            px={2}
            py={2}
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              width={"50%"}
              mx={"auto"}
            >
              <Iframe
                backgroundBgcolor={settings.backgroundBgcolor}
                autocompleteBgcolor={settings.autocompleteBgcolor}
                autocompleteFontColor={settings.autocompleteFontColor}
                autocompleteFontSize={settings.autocompleteFontSize}
                autocompleteFontWeight={settings.autocompleteFontWeight}
                autocompleteHeight={settings.autocompleteHeight}
                autocompleteWidth={settings.autocompleteWidth}
                buttonBgcolor={settings.buttonBgcolor}
                buttonFontColor={settings.buttonFontColor}
                buttonFontSize={settings.buttonFontSize}
                buttonFontWeight={settings.buttonFontWeight}
                buttonHeight={settings.buttonHeight}
                buttonWidth={settings.buttonWidth}
                isCustomize
              />
            </Box>
            <Stack width={"100%"} height={"100%"} spacing={2}>
              <Stack width={{ xs: "calc(100% - 16px)", md: "calc(50% - 24px)" }}>
                <Typography fontWeight={700} color={"#5177FC"} fontSize={16}>
                  Customize
                </Typography>
                <CustomField
                  type="text"
                  title={"Iframe Background color"}
                  value={settings.backgroundBgcolor}
                  onChange={(v) => onHandleChangeSetting("backgroundBgcolor", v)}
                  startAdorn
                />
              </Stack>
              <Box
                display={"flex"}
                flexDirection={{ xs: "column", md: "row" }}
                alignItems="center"
                gap={2}
              >
                <Stack width={{ xs: "100%", md: "50%" }}>
                  <Typography fontWeight={700} fontSize={12} mb={2}>
                    Autocomplete Styling
                  </Typography>
                  <Grid container spacing={2} width={"100%"}>
                    <Grid item xs={12} sm={6} width={"100%"}>
                      <CustomField
                        type="number"
                        title={"Height"}
                        value={settings.autocompleteHeight}
                        onChange={(v) =>
                          onHandleChangeSetting("autocompleteHeight", v)
                        }
                        boldTitle={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} width={"100%"}>
                      <CustomField
                        type="text"
                        title={"Font Color"}
                        value={settings.autocompleteFontColor}
                        onChange={(v) =>
                          onHandleChangeSetting("autocompleteFontColor", v)
                        }
                        boldTitle={false}
                        startAdorn
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} width={"100%"}>
                      <CustomField
                        type="number"
                        title={"Width"}
                        value={settings.autocompleteWidth}
                        onChange={(v) =>
                          onHandleChangeSetting("autocompleteWidth", v)
                        }
                        boldTitle={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} width={"100%"}>
                      <CustomField
                        type="text"
                        title={"Bg Color"}
                        value={settings.autocompleteBgcolor}
                        onChange={(v) =>
                          onHandleChangeSetting("autocompleteBgcolor", v)
                        }
                        boldTitle={false}
                        startAdorn
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} width={"100%"}>
                      <CustomField
                        type="number"
                        title={"Font Size"}
                        value={settings.autocompleteFontSize}
                        onChange={(v) =>
                          onHandleChangeSetting("autocompleteFontSize", v)
                        }
                        boldTitle={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} width={"100%"}></Grid>
                    <Grid item xs={12} sm={6} width={"100%"}>
                      <CustomField
                        type="number"
                        title={"Font Weight"}
                        value={settings.autocompleteFontWeight}
                        onChange={(v) =>
                          onHandleChangeSetting("autocompleteFontWeight", v)
                        }
                        boldTitle={false}
                      />
                    </Grid>
                  </Grid>
                </Stack>
                <Stack width={{ xs: "100%", md: "50%" }}>
                  <Typography fontWeight={700} fontSize={12} mb={2}>
                    Button Styling
                  </Typography>
                  <Grid container spacing={2} width={"100%"}>
                    <Grid item xs={12} sm={6} width={"100%"}>
                      <CustomField
                        type="number"
                        title={"Height"}
                        value={settings.buttonHeight}
                        onChange={(v) => onHandleChangeSetting("buttonHeight", v)}
                        boldTitle={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} width={"100%"}>
                      <CustomField
                        type="text"
                        title={"Font Color"}
                        value={settings.buttonFontColor}
                        onChange={(v) =>
                          onHandleChangeSetting("buttonFontColor", v)
                        }
                        boldTitle={false}
                        startAdorn
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} width={"100%"}>
                      <CustomField
                        type="number"
                        title={"Width"}
                        value={settings.buttonWidth}
                        onChange={(v) => onHandleChangeSetting("buttonWidth", v)}
                        boldTitle={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} width={"100%"}>
                      <CustomField
                        type="text"
                        title={"Bg Color"}
                        value={settings.buttonBgcolor}
                        onChange={(v) =>
                          onHandleChangeSetting("buttonBgcolor", v)
                        }
                        boldTitle={false}
                        startAdorn
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} width={"100%"}>
                      <CustomField
                        type="number"
                        title={"Font Size"}
                        value={settings.buttonFontSize}
                        onChange={(v) =>
                          onHandleChangeSetting("buttonFontSize", v)
                        }
                        boldTitle={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} width={"100%"}></Grid>
                    <Grid item xs={12} sm={6} width={"100%"}>
                      <CustomField
                        type="number"
                        title={"Font Weight"}
                        value={settings.buttonFontWeight}
                        onChange={(v) =>
                          onHandleChangeSetting("buttonFontWeight", v)
                        }
                        boldTitle={false}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </div>
      </div>
    </AuthenticationGuard>
  );
};

export default FullPropertyReport;
