import LoadingButton from "@mui/lab/LoadingButton";
import AppModal from "../../../AppModal";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  alpha,
  FormControl,
  FormHelperText
} from "@mui/material";
import * as React from "react";
import SearchField from "../components/SearchField";
import AddToGroupModal from "./AddToGroupModal";
import { AddContact, RemoveContacts, AddToGroup, UpdateContact, GetGroupsSimplified, SearchContacts } from "../../../../services/api";
import Grid from '@mui/material/Grid';
import ContactsTable from "./ContactsTable";

const ContactsContent = ({ rows, page, pageCount, setPage, refetch, setRows,keyword,setKeyword }) => {
  const [OpenAddContactModal, setOpenAddContactModal] = React.useState(false);
  const [firstName, setfirstName] = React.useState("");
  const [firstNameRequired, setfirstNameRequired] = React.useState(false);
  const [lastName, setlastName] = React.useState("");
  const [phone, setphone] = React.useState("");
  const [email, setemail] = React.useState("");
  const [emailRequired, setemailRequired] = React.useState(false);
  const [address, setaddress] = React.useState("");
  const [notes, setnotes] = React.useState("");
  const [addContactLoading, setAddContactLoading] = React.useState(false);
  const [selectedIDs, setSelectedIDs] = React.useState([]);
  const [updateContactLoading, setUpdateContactLoading] = React.useState(false);
  const [OpenAddToGroupModal, setOpenAddToGroupModal] = React.useState(false);
  const [openRemoveContactsConfirmModal, setOpenRemoveContactsConfirmModal] = React.useState(false);
  const [isUpdateContactModalOpen, setIsUpdateContactModalOpen] = React.useState(false);
  const [currentContact, setCurrentContact] = React.useState(null);
  const [groups, setGroups] = React.useState([]);
  const formatPhoneNumber = (phoneNumberString) => {
    let formattedPhoneNumber = phoneNumberString.replace(/\D/g, ''); // Remove all non-numeric characters
    // Format the numbers as US phone number in XXX-XXX-XXXX format
    if (formattedPhoneNumber.length > 3 && formattedPhoneNumber.length <= 6) {
      formattedPhoneNumber = formattedPhoneNumber.replace(/^(\d{3})(\d+)/, '$1-$2');
    } else if (formattedPhoneNumber.length > 6) {
      formattedPhoneNumber = formattedPhoneNumber.replace(/^(\d{3})(\d{3})(\d+)/, '$1-$2-$3');
    }
    return formattedPhoneNumber;
  };
  const handlePhoneChange = (e) => {
    let val = e.target.value;
    let originalVal = val.replace(/\D/g, '');
    if (originalVal.length <= 10) {
      setphone(formatPhoneNumber(e.target.value))
    }
  }
  const executeSearch = async (keyword) => {
    if (keyword === "") {
      refetch();
      return;
    }
    const response = await SearchContacts(keyword,page);
    setRows(response?.data?.contacts)
  };
  const fetchGroups = async () => {
    const response = await GetGroupsSimplified();
    setGroups(response?.data);
  };


  const handleOpenUpdateContactModal = (contact) => {
    setfirstNameRequired(false);
    setemailRequired(false);
    setfirstName(contact.first_name);
    setlastName(contact.last_name);
    let val = formatPhoneNumber(contact.phone)
    setphone(val);
    setemail(contact.email);
    setaddress(contact.address);
    setnotes(contact.notes);
    setCurrentContact(contact);
    setIsUpdateContactModalOpen(true);
  };

  const handleCloseUpdateContactModal = () => setIsUpdateContactModalOpen(false);
  const resetForm = () => {
    setfirstName("");
    setlastName("");
    setphone("");
    setemail("");
    setaddress("");
    setnotes("");
  };
  const handleAddContact = async () => {
    setAddContactLoading(true);
    //create formdata
    let errors_happened = false;
    if (firstName === "") {
      setfirstNameRequired(true);
      setAddContactLoading(false);
      errors_happened = true;
    }
    if (email === "") {
      setemailRequired(true);
      setAddContactLoading(false);
      errors_happened = true;
    }
    if (errors_happened) return;
    const formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("address", address);
    formData.append("notes", notes);
    const response = await AddContact(formData);
    if (response.status === 200) {
      setAddContactLoading(false);
      handleCloseAddContactModal();
      resetForm();
      refetch();
    }
  };

  const handleOpenAddContactModal = () => {
    resetForm();
    setemailRequired(false);
    setfirstNameRequired(false);
    setOpenAddContactModal(true)
  };
  const handleCloseAddContactModal = () => setOpenAddContactModal(false);
  
  const handleOpenAddToGroupModal = () => {
    fetchGroups();
    setOpenAddToGroupModal(true);
    console.log("selectedIDs", selectedIDs);
    console.log(groups);
  }
  const handleOpenRemoveContactsConfirmModal = () => setOpenRemoveContactsConfirmModal(true);
  const handleCloseAddToGroupModal = () => setOpenAddToGroupModal(false);
  const handleCloseRemoveContactsConfirmModal = () => setOpenRemoveContactsConfirmModal(false);

  
  const handleAddToGroup = async (selectedGroup) => {
    //create formdata
    const formData = new FormData();
    formData.append("contact_ids", JSON.stringify(selectedIDs));
    const response = await AddToGroup(formData, selectedGroup);
    if (response.status === 200) {
      handleCloseAddToGroupModal();
      resetForm();
      refetch();
      return true;
    }
    return false;
  };

  const handleRemoveContacts = async () => {
    const contact_ids = JSON.stringify(selectedIDs);
    const response = await RemoveContacts(contact_ids);
    if (response.status === 200) {
      handleCloseRemoveContactsConfirmModal();
      resetForm();
      refetch();
    }
  };

  const handleUpdateContact = async () => {
    setUpdateContactLoading(true);
    let errors_happened = false;
    if (firstName === "") {
      setfirstNameRequired(true);
      setUpdateContactLoading(false);
      errors_happened = true;
    }
    if (email === "") {
      setemailRequired(true);
      setUpdateContactLoading(false);
      errors_happened = true;
    }
    if (errors_happened) return;
    //create formdata
    const formData = new FormData();
    formData.append("id", currentContact.id);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("address", address);
    formData.append("notes", notes);
    const response = await UpdateContact(formData);
    if (response.status === 200) {
      setUpdateContactLoading(false);
      handleCloseUpdateContactModal();
      resetForm();
      refetch();
    }
  };

  return (
    <>
      {rows.length ? (
        <>
        <>
          <Grid container>
            <Grid item md={6}>
              <Button  
                  disabled={selectedIDs.length === 0}
                  sx={{
                    height: 30,
                    bgcolor: "#fff",
                    ":hover": {
                      bgcolor: alpha("#fff", 0.9),
                    },
                    fontSize: 16,
                    fontWeight: 500,
                    padding: "2px 18px",
                    margin: "1rem",
                    borderRadius: "8px",
                    borderColor:"#5177FC",
                    border:"1px solid",
                    textTransform: "capitalize",
                    color:"#5177FC"
                  }}
                  onClick={(e) => handleOpenAddToGroupModal()}
                
                >
                Add to Group
              </Button>
              <Button 
                disabled={selectedIDs.length === 0}
                sx={{
                  height: 30,
                  bgcolor: "#fff",
                  ":hover": {
                    bgcolor: alpha("#fff", 0.9),
                  },
                  fontSize: 16,
                  fontWeight: 500,
                  padding: "2px 18px",
                  margin: "1rem",
                  borderRadius: "8px",
                  borderColor:"#EA5455",
                  border:"1px solid",
                  textTransform: "capitalize",
                  color:"#EA5455"
                }}
                onClick={(e) => handleOpenRemoveContactsConfirmModal()}
              
              >
                Remove
              </Button>
              <Button 
                  sx={{
                    height: 30,
                    bgcolor: "#5177FC",
                    ":hover": {
                      bgcolor: alpha("#5177FC", 0.9),
                    },
                    fontSize: 16,
                    fontWeight: 500,
                    padding: "2px 18px",
                    margin: "1rem",
                    borderRadius: "8px",
                    textTransform: "capitalize",
                    color:"#fff"
                  }}
                  onClick={(e) => handleOpenAddContactModal()}
                
                >
                  + Add New
              </Button>
            </Grid>
            <Grid item md={3}></Grid>
            <Grid item md={3}>
              <SearchField executeSearch={executeSearch} keyword={keyword}
setKeyword={setKeyword} ></SearchField>
            </Grid>
          </Grid>
        </>
        <ContactsTable
            rows={rows}
            page={page}
            setPage={setPage}
            pageCount={pageCount}
            selectedIDs={selectedIDs}
            setSelectedIDs={setSelectedIDs}
            ShowUpdate={true}
            handleOpenUpdateContactModal={handleOpenUpdateContactModal}
            removeBoxPadding={false}
        />
          <AppModal
        open={isUpdateContactModalOpen}
        title={"Edit Client"}
        handleClose={handleCloseUpdateContactModal}
        maxWidth="md"
        closeIcon
      >
        <Stack gap={3}>
          <Grid container>
            <Grid item md={5} >
              <Stack gap={2} paddingTop={"2rem"} paddingBottom={"2rem"}>
                <FormControl
                  sx={{
                    "& .MuiInputLabel-root": {
                      fontSize: 14,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                  }}
                >
                  <TextField
                    fullWidth
                    label={"First Name"}
                    title={"First Name"}
                    required
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    type={"text"}
                    value={firstName}
                    onChange={(e) => {
                      setfirstName(e.target.value);
                      if (firstName !== "") setfirstNameRequired(false);
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        fontSize: 14,
                        border: 0,
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "14px",
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "12px",
                        bgcolor: "#F7F7F7",
                      },
                    }}
                  />
                  <FormHelperText variant="outlined">
                    <Typography
                      component={"span"}
                      variant="caption"
                      textTransform={"capitalize"}
                      sx={{
                        opacity: firstNameRequired ? 1 : 0,
                        color: "red",
                        transition: "all ease-out 200ms",
                      }}
                    >
                      First Name is Required.
                    </Typography>
                  </FormHelperText>
                </FormControl>
              </Stack>
              <Stack gap={2} paddingTop={"2rem"} paddingBottom={"2rem"}>
                <FormControl
                  sx={{
                    "& .MuiInputLabel-root": {
                      fontSize: 14,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                  }}
                >
                  <TextField
                    fullWidth
                    label={"Phone"}
                    title={"Phone"}
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    type={"text"}
                    value={phone}
                    onChange={handlePhoneChange}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        fontSize: 14,
                        border: 0,
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "14px",
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "12px",
                        bgcolor: "#F7F7F7",
                      },
                    }}
                  />
                  <FormHelperText variant="outlined">
                    <Typography
                      component={"span"}
                      variant="caption"
                      textTransform={"capitalize"}
                      sx={{
                        opacity: 0,
                        color: "red",
                        transition: "all ease-out 200ms",
                      }}
                    >
                      Phone is Required.
                    </Typography>
                  </FormHelperText>
                </FormControl>
              </Stack>
              <Stack gap={2} paddingTop={"2rem"} paddingBottom={"2rem"}>
                  <FormControl
                    sx={{
                      "& .MuiInputLabel-root": {
                        fontSize: 14,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                    }}
                  >
                    <TextField
                      fullWidth
                      label={"Address"}
                      title={"Address"}
                      inputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{ style: { fontSize: 14 } }}
                      type={"text"}
                      value={address}
                      onChange={(e) => setaddress(e.target.value)}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          fontSize: 14,
                          border: 0,
                        },
                        "& .MuiOutlinedInput-input": {
                          padding: "14px",
                        },
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "12px",
                          bgcolor: "#F7F7F7",
                        },
                      }}
                    />
                    <FormHelperText variant="outlined">
                      <Typography
                        component={"span"}
                        variant="caption"
                        textTransform={"capitalize"}
                        sx={{
                          opacity: 0,
                          color: "red",
                          transition: "all ease-out 200ms",
                        }}
                      >
                      </Typography>
                    </FormHelperText>
                  </FormControl>
              </Stack>
            </Grid>
            <Grid item md={2}></Grid>
            <Grid item md={5} >
              <Stack gap={2} paddingTop={"2rem"} paddingBottom={"2rem"}>
                  <FormControl
                    sx={{
                      "& .MuiInputLabel-root": {
                        fontSize: 14,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                    }}
                  >
                    <TextField
                      fullWidth
                      label={"Last Name"}
                      title={"Last Name"}
                      inputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{ style: { fontSize: 14 } }}
                      type={"text"}
                      value={lastName}
                      onChange={(e) => setlastName(e.target.value)}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          fontSize: 14,
                          border: 0,
                        },
                        "& .MuiOutlinedInput-input": {
                          padding: "14px",
                        },
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "12px",
                          bgcolor: "#F7F7F7",
                        },
                      }}
                    />
                    <FormHelperText variant="outlined">
                      <Typography
                        component={"span"}
                        variant="caption"
                        textTransform={"capitalize"}
                        sx={{
                          opacity: 0,
                          color: "red",
                          transition: "all ease-out 200ms",
                        }}
                      >
                        Last Name is Required.
                      </Typography>
                    </FormHelperText>
                  </FormControl>
              </Stack>
              <Stack gap={2} paddingTop={"2rem"} paddingBottom={"2rem"}>
                <FormControl
                  sx={{
                    "& .MuiInputLabel-root": {
                      fontSize: 14,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                  }}
                >
                  <TextField
                    fullWidth
                    label={"Email"}
                    title={"Email"}
                    required
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    type={"email"}
                    value={email}
                    onChange={(e) => {
                      setemail(e.target.value);
                      if (email !== "") setemailRequired(false);
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        fontSize: 14,
                        border: 0,
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "14px",
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "12px",
                        bgcolor: "#F7F7F7",
                      },
                    }}
                  />
                  <FormHelperText variant="outlined">
                    <Typography
                      component={"span"}
                      variant="caption"
                      textTransform={"capitalize"}
                      sx={{
                        opacity: emailRequired  ? 1 : 0,
                        color: "red",
                        transition: "all ease-out 200ms",
                      }}
                    >
                      Email is Required.
                    </Typography>
                  </FormHelperText>
                </FormControl>
              </Stack>
              <Stack gap={2} paddingTop={"2rem"} paddingBottom={"2rem"}>
                  <FormControl
                    sx={{
                      "& .MuiInputLabel-root": {
                        fontSize: 14,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                    }}
                  >
                      <TextField
                        fullWidth
                        label={"Notes"}
                        title={"Notes"}
                        inputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        type={"text"}
                        value={notes}
                        onChange={(e) => setnotes(e.target.value)}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            fontSize: 14,
                            border: 0,
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: "14px",
                          },
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "12px",
                            bgcolor: "#F7F7F7",
                          },
                        }}
                      />
                    <FormHelperText variant="outlined">
                      <Typography
                        component={"span"}
                        variant="caption"
                        textTransform={"capitalize"}
                        sx={{
                          opacity: 0,
                          color: "red",
                          transition: "all ease-out 200ms",
                        }}
                      >
                      </Typography>
                    </FormHelperText>
                  </FormControl>
              </Stack>
            </Grid>
          </Grid>
          
          
          
          
          
          
          <LoadingButton
            loading={updateContactLoading}
            variant={"contained"}
            sx={{
              alignSelf: "flex-end",
              height: 48,
              bgcolor: "#5177FC",
              ":hover": {
                bgcolor: alpha("#5177FC", 0.9),
              },
              fontSize: 16,
              fontWeight: 500,
              padding: "8px 16px",
              borderRadius: "8px",
              textTransform: "capitalize",
            }}
            onClick={handleUpdateContact}
          >
            Save
          </LoadingButton>
        </Stack>
          </AppModal>
          <AppModal
            open= {openRemoveContactsConfirmModal}
            onClose={handleCloseRemoveContactsConfirmModal}
            aria-labelledby="delete-confirmation-title"
            aria-describedby="delete-confirmation-description"
          >
            <Box >
              <Typography id="delete-confirmation-title" variant="h6" component="h2">
                Confirm Delete Clients
              </Typography>
              <Typography id="delete-confirmation-description" sx={{ mt: 2 }}>
                Are you sure you want to delete the selected clients?
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                <Button 
                  sx={{ mr: 1 }}
                  onClick={handleCloseRemoveContactsConfirmModal}
                >
                  Cancel
                </Button>
                <Button 
                  variant="contained" 
                  color="error" 
                  onClick={handleRemoveContacts}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          </AppModal>
          <AddToGroupModal
            open={OpenAddToGroupModal}
            onClose={() => handleCloseAddToGroupModal()}
            onSave={handleAddToGroup}
            groups={groups}
          />
        </>
      ) : (
        <div className="centered-container">
          <img src="/assets/contacts.png" alt="Descriptive Text" />
          <h5>You don’t have any Clients.</h5>
          <Button
            variant={"contained"}
            sx={{
              height: 30,
              bgcolor: "#5177FC",
              ":hover": {
                bgcolor: alpha("#5177FC", 0.9),
              },
              fontSize: 16,
              fontWeight: 500,
              padding: "2px 18px",
              marginTop: "1rem",
              borderRadius: "8px",
              textTransform: "capitalize",
            }}
            onClick={(e) => handleOpenAddContactModal()}
          >
            + Create New
          </Button>
        </div>
      )}
      <AppModal
        open={OpenAddContactModal}
        title={"Create New Client"}
        handleClose={handleCloseAddContactModal}
        maxWidth="md"
        closeIcon
      >
        <Stack gap={3}>
          <Grid container>
            <Grid item md={5} >
              <Stack gap={2} paddingTop={"2rem"} paddingBottom={"2rem"}>
              <FormControl
                sx={{
                  "& .MuiInputLabel-root": {
                    fontSize: 14,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
              >
                <TextField
                  fullWidth
                  label={"First Name"}
                  title={"First Name"}
                  required
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  type={"text"}
                  value={firstName}
                  onChange={(e) => {
                    setfirstName(e.target.value);
                    if (firstName !== "") setfirstNameRequired(false);
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      fontSize: 14,
                      border: 0,
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "14px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                      bgcolor: "#F7F7F7",
                    },
                  }}
                />
                <FormHelperText variant="outlined">
                  <Typography
                    component={"span"}
                    variant="caption"
                    textTransform={"capitalize"}
                    sx={{
                      opacity: firstNameRequired ? 1 : 0,
                      color: "red",
                      transition: "all ease-out 200ms",
                    }}
                  >
                    First Name is Required.
                  </Typography>
                </FormHelperText>
              </FormControl>
                
              </Stack>
              <Stack gap={2} paddingTop={"2rem"} paddingBottom={"2rem"}>
              <FormControl
                sx={{
                  "& .MuiInputLabel-root": {
                    fontSize: 14,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
              >
                <TextField
                  fullWidth
                  label={"Phone"}
                  title={"Phone"}
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  type={"text"}
                  value={phone}
                  onChange={handlePhoneChange }
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      fontSize: 14,
                      border: 0,
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "14px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                      bgcolor: "#F7F7F7",
                    },
                  }}
                />
                <FormHelperText variant="outlined">
                  <Typography
                    component={"span"}
                    variant="caption"
                    textTransform={"capitalize"}
                    sx={{
                      opacity: 0,
                      color: "red",
                      transition: "all ease-out 200ms",
                    }}
                  >
                    Phone is Required.
                  </Typography>
                </FormHelperText>
              </FormControl>
                
              </Stack>
              <Stack gap={2} paddingTop={"2rem"} paddingBottom={"2rem"}>
                
                <FormControl
                sx={{
                  "& .MuiInputLabel-root": {
                    fontSize: 14,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
              >
                <TextField
                  fullWidth
                  label={"Address"}
                  title={"Address"}
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  type={"text"}
                  value={address}
                  onChange={(e) => setaddress(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      fontSize: 14,
                      border: 0,
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "14px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                      bgcolor: "#F7F7F7",
                    },
                  }}
                />
                <FormHelperText variant="outlined">
                  <Typography
                    component={"span"}
                    variant="caption"
                    textTransform={"capitalize"}
                    sx={{
                      opacity: 0,
                      color: "red",
                      transition: "all ease-out 200ms",
                    }}
                  >
                  </Typography>
                </FormHelperText>
              </FormControl>
              </Stack>
            </Grid>
            <Grid item md={2}></Grid>
            <Grid item md={5} >
              <Stack gap={2} paddingTop={"2rem"} paddingBottom={"2rem"}>
                
                <FormControl
                sx={{
                  "& .MuiInputLabel-root": {
                    fontSize: 14,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
              >
                <TextField
                  fullWidth
                  label={"Last Name"}
                  title={"Last Name"}
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  type={"text"}
                  value={lastName}
                  onChange={(e) => setlastName(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      fontSize: 14,
                      border: 0,
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "14px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                      bgcolor: "#F7F7F7",
                    },
                  }}
                />
                <FormHelperText variant="outlined">
                  <Typography
                    component={"span"}
                    variant="caption"
                    textTransform={"capitalize"}
                    sx={{
                      opacity: 0,
                      color: "red",
                      transition: "all ease-out 200ms",
                    }}
                  >
                    Last Name is Required.
                  </Typography>
                </FormHelperText>
              </FormControl>
              </Stack>
              <Stack gap={2} paddingTop={"2rem"} paddingBottom={"2rem"}>
              <FormControl
                sx={{
                  "& .MuiInputLabel-root": {
                    fontSize: 14,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
              >
                <TextField
                  fullWidth
                  label={"Email"}
                  title={"Email"}
                  required
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  type={"email"}
                  value={email}
                  onChange={(e) => {
                    setemail(e.target.value);
                    if (email !== "") setemailRequired(false);
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      fontSize: 14,
                      border: 0,
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "14px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                      bgcolor: "#F7F7F7",
                    },
                  }}
                />
                <FormHelperText variant="outlined">
                  <Typography
                    component={"span"}
                    variant="caption"
                    textTransform={"capitalize"}
                    sx={{
                      opacity: emailRequired  ? 1 : 0,
                      color: "red",
                      transition: "all ease-out 200ms",
                    }}
                  >
                    Email is Required.
                  </Typography>
                </FormHelperText>
              </FormControl>
                
              </Stack>
              <Stack gap={2} paddingTop={"2rem"} paddingBottom={"2rem"}>
              <FormControl
                sx={{
                  "& .MuiInputLabel-root": {
                    fontSize: 14,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
              >
                <TextField
                  fullWidth
                  label={"Notes"}
                  title={"Notes"}
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  type={"text"}
                  value={notes}
                  onChange={(e) => setnotes(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      fontSize: 14,
                      border: 0,
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "14px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                      bgcolor: "#F7F7F7",
                    },
                  }}
                />
                <FormHelperText variant="outlined">
                  <Typography
                    component={"span"}
                    variant="caption"
                    textTransform={"capitalize"}
                    sx={{
                      opacity: 0,
                      color: "red",
                      transition: "all ease-out 200ms",
                    }}
                  >
                  </Typography>
                </FormHelperText>
              </FormControl>
                
              </Stack>
            </Grid>
          </Grid>
          
          
          
          
          
          
          <LoadingButton
            loading={addContactLoading}
            variant={"contained"}
            sx={{
              alignSelf: "flex-end",
              height: 48,
              bgcolor: "#5177FC",
              ":hover": {
                bgcolor: alpha("#5177FC", 0.9),
              },
              fontSize: 16,
              fontWeight: 500,
              padding: "8px 16px",
              borderRadius: "8px",
              textTransform: "capitalize",
            }}
            onClick={handleAddContact}
          >
            Save
          </LoadingButton>
        </Stack>
      </AppModal>
    </>
  );
};

export default ContactsContent;
  