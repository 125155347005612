import React from "react";

export const PageLoader = ({text}) => {
  const loadingImg = "/assets/loader.svg";

  return (
    <div style={{textAlign:"center", paddingTop:"5rem"}}>
      <div className="loader">
        <img src={loadingImg} alt="Loading..." />
      </div>
      {text && <p style={{fontSize:"16px", paddingTop:"5rem"}}>{text}</p>}
    </div> 
  );
};
