import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, Typography, alpha } from "@mui/material";
import { axiosInstance } from "../../../services/api";
import { debounce } from "lodash";
import { AuthenticationGuard } from "../../authentication-guard";
import axios from "axios";
import { useCognitoUser } from "../../../CognitoProvider";
import Cookies from "js-cookie";
const Demo = () => {
  const { isAuthenticated } = useCognitoUser();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [text, setText] = React.useState("");
  const [error, setError] = React.useState("");
  const settings = {
    backgroundBgcolor: "#FFF",
    autocompleteHeight: 48,
    autocompleteWidth: 410,
    autocompleteFontSize: 14,
    autocompleteFontWeight: 400,
    autocompleteFontColor: "#202124",
    autocompleteBgcolor: "#F7F7F7",
    buttonHeight: 48,
    buttonWidth: 127,
    buttonFontSize: 14,
    buttonFontWeight: 500,
    buttonFontColor: "#FFFFFF",
    buttonBgcolor: "#5177FC",

    backendUrl: "",
    token: "",
    tokenKey: "",
    tokenValue: "",
  };

  const [loading, setLoading] = React.useState(false);


  React.useEffect(() => {
    if (text) {
      (async () => {
        setLoading(true);
        try {
          const response = await axiosInstance.get(
            `/properties/auto-complete/?input=${text}`
          );
          setOptions(response.data?.data);
        } catch (error) {}
        setLoading(false);
      })();
    }
  }, [text]);

  const handleGenerateReport = async () => {
    if (!selectedOption) return setError("Please select a property first");
    setError("");
    const id_token = Cookies.get('id_token');
    if (!isAuthenticated) return;

    try {
      const baseURL = process.env.REACT_APP_DEV_PORTAL_API_SERVER;
      const response = await axios.post(
        baseURL+'/report/generate/token/',
        { address: JSON.stringify(selectedOption) },
        {
          headers: { Authorization: `${id_token}` },
        }
      );
      window.open(
        `${process.env.REACT_APP_DEV_PORTAL}/report?token=${response.data.token}`
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
     <AuthenticationGuard title={"to start using Full Property Report"}>
        <div className="padding-sides">
          <div className="centered-container" style={{
            marginTop: "5rem",
            height: "unset"
          }}>
            <img src="/assets/full_report.png" alt="Descriptive Text" />
            <Autocomplete
              sx={{ width: Number(settings.autocompleteWidth),marginTop: "5rem" }}
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              componentsProps={{
                paper: {
                  sx: { bgcolor: alpha(settings.autocompleteBgcolor, 1) },
                },
              }}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option.text}
              options={options}
              loading={loading}
              onChange={(_, v) => setSelectedOption(v)}
              noOptionsText={
                <Typography
                  display={"flex"}
                  textAlign={"center"}
                  gap={1}
                  fontSize={14}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  No Data found
                </Typography>
              }
              loadingText={
                <Typography
                  display={"flex"}
                  textAlign={"center"}
                  gap={1}
                  fontSize={14}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  Loading...
                </Typography>
              }
              onInputChange={debounce((_, v) => setText(v), 500)}
              renderOption={(props, option, { selected }) => {
                const newProps = { ...props, key: option.id };
                return (
                  <li
                    {...newProps}
                    style={{
                      fontSize: 14,
                      color: settings.autocompleteFontColor,
                    }}
                  >
                    {option.text}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Property Address..."
                  title="Property Address"
                  helperText={error}
                  InputProps={{
                    ...params.InputProps,
                    style: { fontSize: Number(settings.autocompleteFontSize) },
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: Number(settings.autocompleteFontSize),
                      color: settings.autocompleteFontColor,
                      fontWeight: Number(settings.autocompleteFontWeight),
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: alpha(settings.autocompleteBgcolor, 0.9),
                      fontSize: Number(settings.autocompleteFontSize),
                      fontWeight: 700,
                    },

                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${alpha(
                        settings.autocompleteBgcolor,
                        0.9
                      )} !important`,
                    },

                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      bgcolor: settings.autocompleteBgcolor,
                    },
                  }}
                />
              )}
            />
            <Button
              variant={"contained"}
              sx={{
                height: 30,
                bgcolor: "#5177FC",
                ":hover": {
                  bgcolor: alpha("#5177FC", 0.9),
                },
                fontSize: 16,
                fontWeight: 500,
                padding: "2px 18px",
                borderRadius: "8px",
                textTransform: "capitalize",
                marginTop: "5rem",
                marginBottom: "5rem",
              }}
              onClick={(e) => handleGenerateReport()}
            >
              Generate
            </Button>
          </div>
        </div>
    </AuthenticationGuard>
  );
};

export default Demo;
