import React from "react";
import { NavBarTab } from "./nav-bar-tab";
import { useCognitoUser } from "../../../CognitoProvider";

function CognitoNavBarTabs() {
  const { isAuthenticated } = useCognitoUser();

  return (
    <div className="nav-bar__tabs">
      {isAuthenticated ? (
        <>
          <NavBarTab path="/products" label="Products" />
          <NavBarTab path="/settings" label="Settings" />
          <NavBarTab path="/subscription" label="Plans" />
        </>
      ) : (
        <>
          <NavBarTab path="/products" label="Products" />
          <NavBarTab path="/subscription" label="Plans" />
        </>
      )}
    </div>
  );
}

export default CognitoNavBarTabs;
