import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import SearchIcon from '@mui/icons-material/Search';

function SearchField({executeSearch,bgColor,keyword, setKeyword}) {
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            executeSearch(keyword);
        }
        };
  return (
    <div className="search-container" style={{margin:"1rem"}}>
      <Input
        type="text"
        placeholder="Search..."
        onChange={(e) => setKeyword(e.target.value)}
        onKeyDown={handleKeyDown}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        className="search-input"
        sx={{
          backgroundColor: (bgColor)?bgColor:"#F5F5F5",
        }}
      />
    </div>
  );
}

export default SearchField;
