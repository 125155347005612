import { Button, Stack, Typography, alpha } from "@mui/material";
import { useEffect, useState } from "react";
import NewList from "./newList";
import { useLocation } from "react-router-dom";
import { AuthenticationGuard } from "../../authentication-guard";

const TargetedLists = () => {
  const { state } = useLocation();

  const [newList, setNewList] = useState(false);
  const handleNewList = () => setNewList(true);

  useEffect(() => {
    if (state?.filters) {
      setNewList(true);
    }
  }, [state]);

  return newList ? (
    <NewList filters={state?.filters} />
  ) : (
    <AuthenticationGuard title={"to start using Targeting Marketing List"}>
      <div  className="padding-sides">
        <Stack  p={3} gap={3}>
          <Stack gap={2}>
            <Typography fontSize={16} fontWeight={700}>
              Our models scan all new listings in the US on a daily basis,
              providing fast, unique and actionable information for your marketing
              purposes.
            </Typography>
          </Stack>

          <Button
            variant={"contained"}
            sx={{
              my: 2,
              height: 34,
              width: "130px",
              mx: "auto",
              bgcolor: "#5177FC",
              ":hover": {
                bgcolor: alpha("#5177FC", 0.9),
              },
              fontSize: 16,
              fontWeight: 500,
              padding: "8px 16px",
              borderRadius: "8px",
              textTransform: "capitalize",
            }}
            onClick={handleNewList}
          >
            + New List
          </Button>

          <Stack gap={2.5} mt={3}>
            <Typography fontSize={20} fontWeight={700}>
              How it works:
            </Typography>

            <div style={{ display: "flex", alignItems: "center", gap: 18 }}>
              <span className="bullet-number">1</span>
              <span className="primary-text">Click on “New List”.</span>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: 18 }}>
              <span className="bullet-number">2</span>
              <span className="primary-text">Select filters for your list.</span>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: 18 }}>
              <span className="bullet-number">3</span>
              <span className="primary-text">
                Save Search.
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: 18 }}>
              <span className="bullet-number">4</span>
              <span className="primary-text">
              Create Auto Emails and assign desired Clients or Groups.
              </span>
            </div>
          </Stack>
        </Stack>
      </div>
    </AuthenticationGuard>
  );
};

export default TargetedLists;
