import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PageLoader } from "../../page-loader";
import PropertyValueField from "./PropertyValueField";
import Field from "./Field";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import Card from "./Card";
import ProgressBar from "../../charts/ProgressBar";
import PropertyFeatureField from "./PropertyFeatureField";
import LineChart from "../../charts/LineChart";
import PropertyHistoryTable from "../../table/PropertyHistoryTable";
import AppTabs from "../../AppTabs";
import SchoolTable from "../../table/SchoolTable";
import LinearProgressBar from "../../charts/LinearProgressBar";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { set } from "lodash";
const getBgcolor = (status) => {
  switch (status) {
    case "Off Market":
      return "#DDDDDD";
    case "Sold":
      return "#FFE0E0";
    case "Active":
      return "#D6F4E4";
    case "Pending/Under Contract":
      return "#FFEDE6 ";
    default:
      return "#DDDDDD";
  }
};

const getColor = (status) => {
  switch (status) {
    case "N/A":
      return "#9A9A9A";
    case "Unlivable":
      return "#EA5455";
    case "Poor":
      return "#FF692E";
    case "Outdated":
      return "#EC9E7F";
    case "Good":
      return "#3FCD7E";
    case "Off Market":
      return "#606060";
    case "Sold":
      return "#FF797A";
    case "Active":
      return "#3FCD7E";
    case "Pending/Under Contract":
      return "#CD876C ";
    case "Spread":
      return "#5CB58F";
    default:
      return "#202124";
  }
};

export const numberWithCommas = (x) => {
  if (typeof x === "object" || !x) return "";
  if (x === 0) return "N/A";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatPriceToNumber = (price) => {
  return +price?.replace(/,/g, "");
};

export const formatPrice = (price = 0) => {
  const convertedPrice =
    typeof price === "string" ? parseInt(price, 10) : price;
  return convertedPrice
    ? `$${numberWithCommas(+convertedPrice.toFixed())}`
    : "N/A";
};

const PropertyReport = () => {
  const [searchParams] = useSearchParams();
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [schoolTab, setSchoolTab] = useState(0);
  const [cashOrFinanced, setCashOrFinanced] = useState("cash");
  const [downPayment, setDownPayment] = useState(0);
  const [downPaymentInput, setDownPaymentInput] = useState(0);
  const [interestRate, setInterestRate] = useState(0);
  const [interestRateInput, setInterestRateInput] = useState(0);
  const [loanTerm, setLoanTerm] = useState(0);
  const [loanTermInput, setLoanTermInput] = useState(0);
  const [showFinanced, setShowFinanced] = useState(false);
  const [sqft, setSqft] = useState('');
  const [sqftInput, setSqftInput] = useState('');
  const graph_labels =
    report?.home_value_graph.home_value_graph
      ?.slice(0, 40)
      ?.map(({ month, year }) => `${year}-${month}-01`)
      ?.reverse() ?? [];
  const graph_values =
    report?.home_value_graph.home_value_graph
      ?.slice(0, 40)
      ?.map(({ estimate }) => estimate.toFixed(0))
      ?.reverse() ?? [];

  const schoolData = useMemo(() => {
    const schoolType = ["elementary", "middle", "high", "private"];
    if (schoolType[schoolTab] === "private") {
      return report?.schools?.schools?.reduce((acc, cur) => {
        if (cur.funding_type.includes(schoolType[schoolTab])) {
          acc.push(cur);
        }
        return acc;
      }, []);
    } else {
      return report?.schools?.schools?.reduce((acc, cur) => {
        if (cur.education_levels.includes(schoolType[schoolTab])) {
          acc.push(cur);
        }
        return acc;
      }, []);
    }
  }, [schoolTab, report?.schools?.schools]);

  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      (async () => {
        try {
          setLoading(true);
          const validateResponse = await axios.get(
            `${process.env.REACT_APP_DEV_PORTAL_API_SERVER}/report/validate/token`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          if (validateResponse.data?.valid === true) {
            let sqft_input = sqft !== '' ? sqft : 0;
            const reportReponse = await axios.get(
              `${process.env.REACT_APP_DEV_PORTAL_API_SERVER}/report/get/data?cash_or_financed=${cashOrFinanced}&downpayment_value=${downPayment}&interest_rate=${interestRate}&loan_term=${loanTerm}&sqft_input=${sqft_input}`,
              { headers: { Authorization: `Bearer ${token}` } }
            );
            setReport(reportReponse.data?.report);
          } else {
            setError(true);
          }
        } catch (error) {
          setError(true);
        } finally {
          setLoading(false);
        }
      })();
    } else {
      setError(true);
    }
  }, [searchParams,cashOrFinanced,downPayment,interestRate,loanTerm,sqft]);

  if (loading) {
    return <PageLoader />;
  }

  console.log(report);

  if (error)
    return (
      <Typography
        width={"100vw"}
        height={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        fontSize={24}
      >
        Invalid Token
      </Typography>
    );
  
  const handlecashOrFinancedChange = (event, newcashOrFinanced) => {
    if (newcashOrFinanced !== null) {
      if (newcashOrFinanced === "cash") {
        setCashOrFinanced(newcashOrFinanced);
        setShowFinanced(false);
        setDownPayment(0);
        setInterestRate(0);
        setLoanTerm(0);
      }
      else{
        setCashOrFinanced(newcashOrFinanced);
        setDownPayment(20);
        setInterestRate(4.5);
        setLoanTerm(30);
        setDownPaymentInput(20);
        setInterestRateInput(4.5);
        setLoanTermInput(30);
        setShowFinanced(true);
      }
      
    }
  };

  return (
    <Box bgcolor={"#F5F6FA"} height={"100vh"} overflow={"auto"} pb={10}>
      <Stack gap={3} maxWidth={"70%"} mx={"auto"} pt={3}>
        <Typography fontSize={24} fontWeight={700} textAlign={"center"} mb={1}>
          Full Property Report
        </Typography>
        
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={0.5}
        >
          <Typography fontSize={36} fontWeight={700} textAlign={"center"}>
            {report?.property_info?.address}
          </Typography>
          <Typography
            sx={{
              color: getColor(report?.property_info?.status),
              fontWeight: 700,
              fontSize: 16,
              textAlign: "center",
              borderRadius: "7px",
              bgcolor: getBgcolor(report?.property_info?.status),
              p: "6px 12px",
            }}
          >
            {report?.property_info?.status}
          </Typography>
        </Box>

        <Grid
          container
          sx={{ padding: 3, borderRadius: "12px", background: "#FFF" }}
        >
          <Grid item xs={4}>
            <PropertyValueField
              title={report?.property_info?.status === "Sold" ? report?.property_value?.sold_price ==="N/A" ? "Property Value" : "Sold Price" : "Listing Price"}
              value={formatPrice(report?.property_info?.status === "Sold" ? report?.property_value?.sold_price ==="N/A" ? report?.property_value?.avm : report?.property_value?.sold_price  : report?.property_value?.listing_price)}
              valueColor={getColor()}
              tooltip={report?.property_info?.status === "Sold" ? report?.property_value?.sold_price ==="N/A" ? "Property Value" :  "Sold Price" : "Listing Price"}
            />
          </Grid>
          <Grid item xs={4}>
            <PropertyValueField
              title={"Full Potential(ARV)"}
              value={formatPrice(report?.property_value?.full_potential)}
              valueColor={getColor()}
              tooltip={
                "An estimated value after full-home renovation and landscaping work."
              }
            />
          </Grid>
          <Grid item xs={4}>
            <PropertyValueField
              title={"Spread"}
              value={formatPrice(report?.property_value?.spread)}
              valueColor={getColor("Spread")}
              tooltip={
                "The difference between full potential and listing price."
              }
            />
          </Grid>
        </Grid>

        <Grid
          container
          sx={{ padding: 3, borderRadius: "12px", background: "#FFF" }}
        >
          <Grid item xs={4}>
            <PropertyValueField
              title={"Value Gap"}
              value={`${Math.round(report?.property_value?.value_gap)}%`}
              valueColor={getColor()}
              tooltip={
                "Spread divided by Listing Price. A higher value indicates better opportunity."
              }
              component={
                <LinearProgressBar
                  value={Math.round(report?.property_value?.value_gap)}
                />
              }
            />
          </Grid>

          <Grid item xs={4}>
            <PropertyValueField
              title={"Price Flexibility Score"}
              value={report?.pfs?.pfs==="N/A"?"N/A":`${Math.round(report?.pfs?.pfs)}%`}
              valueColor={getColor()}
              tooltip={
                "Predicts how likely the property owner is willing to negotiate the price. A higher score indicates higher price flexibility."
              }
              component={
                <LinearProgressBar value={report?.pfs?.pfs==="N/A" ? 0 : Math.round(report?.pfs?.pfs)} />
              }
            />
          </Grid>

          <Grid item xs={4}>
            <PropertyValueField
              title={"Property Condition"}
              value={report?.property_info?.property_condition}
              valueColor={getColor(report?.property_info?.property_condition)}
            />
          </Grid>
        </Grid>
        
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={0.5}
          sx={{marginBottom: "1rem"}}
        >
          <ToggleButtonGroup
            color="primary"
            value={cashOrFinanced}
            exclusive
            onChange={handlecashOrFinancedChange}
            aria-label="Platform"
          >
            <ToggleButton value="cash">Cash</ToggleButton>
            <ToggleButton value="finance">Finance</ToggleButton>
          </ToggleButtonGroup>
          {showFinanced && (
            <Stack spacing={2} direction={"row"} alignItems={"center"}>
              <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                <OutlinedInput
                  id="outlined-adornment-weight"
                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                  aria-describedby="outlined-weight-helper-text"
                  value={downPaymentInput}
                  onChange={(e) => setDownPaymentInput(e.target.value)}
                  onBlur={(e) => setDownPayment(e.target.value)}
                  inputProps={{
                    'aria-label': 'Downpayment',
                  }}
                />
                <FormHelperText id="outlined-weight-helper-text">Downpayment</FormHelperText>
              </FormControl>
              <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                <OutlinedInput
                  id="outlined-adornment-weight"
                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                  aria-describedby="outlined-weight-helper-text"
                  value={interestRateInput}
                  onChange={(e) => setInterestRateInput(e.target.value)}
                  onBlur={(e) => setInterestRate(e.target.value)}
                  inputProps={{
                    'aria-label': 'Intereset Rate',
                  }}
                />
                <FormHelperText id="outlined-weight-helper-text">Intereset Rate</FormHelperText>
              </FormControl>
              <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                <OutlinedInput
                  id="outlined-adornment-weight"
                  endAdornment={<InputAdornment position="end">Years</InputAdornment>}
                  aria-describedby="outlined-weight-helper-text"
                  value={loanTermInput}
                  onChange={(e) => setLoanTermInput(e.target.value)}
                  onBlur={(e) => setLoanTerm(e.target.value)}
                  inputProps={{
                    'aria-label': 'Loan Term',
                  }}
                />
                <FormHelperText id="outlined-weight-helper-text">Loan Term</FormHelperText>
              </FormControl>
            </Stack>
          )}
          <Stack spacing={2} direction={"row"} alignItems={"center"} sx={{marginBottom: "1rem",marginTop:"1rem"}}>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
              <OutlinedInput
                id="outlined-adornment-sqft"
                endAdornment={<InputAdornment position="end">sqft</InputAdornment>}
                aria-describedby="outlined-sqft-helper-text"
                value={sqftInput}
                onChange={(e) => setSqftInput(e.target.value)}
                onBlur={(e) => setSqft(e.target.value)}
                placeholder="0"
                inputProps={{
                  'aria-label': 'sqft',
                }}
              />
              <FormHelperText id="outlined-sqft-helper-text">Custom Size</FormHelperText>
            </FormControl>
          </Stack>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card
              title={"Estimated Renovation Cost"}
              value={formatPrice(
                report?.renovation_cost?.estimated_renovation_cost
              )}
              tooltip={
                "Estimated Renovation Cost includes Offer Price, Cost at Purchase, Cost at Sale, Renovation Cost, Contingency and Total Holding Costs."
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Card
              title={"Maximum Recommended Renovation Budget"}
              value={formatPrice(
                report?.renovation_cost?.maximum_recommended_renovation_budget
              )}
              tooltip={
                "The estimated maximum cost of a renovation project before the value of the renovation no longer adds to the value of your home."
              }
            />
          </Grid>
        </Grid>

        <Box display={"flex"} width={"100%"} gap={2}>
          <Stack spacing={1} width={"50%"}>
            <Typography fontSize={18} fontWeight={400}>
              If Purchased{" "}
              <Typography fontSize={18} fontWeight={700} component={"span"}>
                and Sold
              </Typography>
            </Typography>
            <Stack
              spacing={1}
              height={"100%"}
              sx={{ padding: 3, borderRadius: "12px", background: "#FFF" }}
              divider={<Divider sx={{ borderColor: "rgba(0, 0, 0, 0.12)" }} />}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"30%"}
                pt={3}
                pb={5}
              >
                <Stack
                  spacing={2}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Typography fontSize={24} fontWeight={700}>
                    ROI
                  </Typography>
                  <ProgressBar
                    value={Math.round(report?.resale_potential?.roi)}
                  />
                </Stack>

                <Stack
                  spacing={2}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Typography fontSize={24} fontWeight={700}>
                    Profit
                  </Typography>
                  <Typography
                    fontSize={24}
                    fontWeight={700}
                    sx={{
                      borderRadius: "7px",
                      bgcolor:
                        report?.resale_potential?.profit < 0
                          ? "#FFE0E0"
                          : "#DCEFE7",
                      color:
                        report?.resale_potential?.profit < 0
                          ? "#EA5455"
                          : "#5CB58F",
                      px: 1.5,
                      py: 0.5,
                    }}
                  >
                    {formatPrice(report?.resale_potential?.profit)}
                  </Typography>
                </Stack>
              </Box>
              <Field
                title={"Total Project Cost"}
                value={formatPrice(
                  report?.resale_potential?.total_project_cost
                )}
              />
              <Field
                title={"New Equity"}
                value={formatPrice(report?.resale_potential?.new_equity)}
              />
              <Field
                title={"Cash From Sale"}
                value={formatPrice(report?.resale_potential?.cash_from_sale)}
              />
              <Field
                title={"Cash Needed at Purchase"}
                value={formatPrice(
                  report?.resale_potential?.cash_needed_for_purchase
                )}
              />
              <Field
                title={"Closing Cost at Purchase"}
                value={formatPrice(
                  report?.resale_potential?.closing_cost_at_purchase
                )}
              />
              <Field
                title={"Closing Cost at Sale"}
                value={formatPrice(
                  report?.resale_potential?.closing_cost_at_sale
                )}
              />
            </Stack>
          </Stack>

          <Stack spacing={1} width={"50%"}>
            <Typography fontSize={18} fontWeight={400}>
              If Purchased{" "}
              <Typography fontSize={18} fontWeight={700} component={"span"}>
                and Held
              </Typography>
            </Typography>
            <Stack
              spacing={1}
              sx={{ padding: 3, borderRadius: "12px", background: "#FFF" }}
              height={"100%"}
              divider={<Divider sx={{ borderColor: "rgba(0, 0, 0, 0.12)" }} />}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"30%"}
                pt={3}
                pb={5}
              >
                <Stack
                  spacing={2}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Typography fontSize={24} fontWeight={700}>
                    ROI
                  </Typography>
                  <ProgressBar value={report?.purchased_held?.roi} />
                </Stack>

                <Stack
                  spacing={2}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Typography fontSize={24} fontWeight={700}>
                    New Equity
                  </Typography>
                  <Typography
                    fontSize={24}
                    fontWeight={700}
                    sx={{
                      borderRadius: "7px",
                      bgcolor:
                        report?.purchased_held?.new_equity < 0
                          ? "#FFE0E0"
                          : "#DCEFE7",
                      color:
                        report?.purchased_held?.new_equity < 0
                          ? "#EA5455"
                          : "#5CB58F",
                      px: 1.5,
                      py: 0.5,
                    }}
                  >
                    {formatPrice(report?.purchased_held?.new_equity)}
                  </Typography>
                </Stack>
              </Box>
              <Field
                title={"Total Project Cost"}
                value={formatPrice(report?.purchased_held?.total_project_cost)}
              />
              <Field
                title={"Cash Needed at Purchase"}
                value={formatPrice(
                  report?.purchased_held?.cash_needed_at_purchase
                )}
              />
              <Field
                title={"Closing Cost at Purchase"}
                value={formatPrice(
                  report?.purchased_held?.closing_cost_at_purchase
                )}
              />
            </Stack>
          </Stack>
        </Box>

        <Box display={"flex"} gap={2}>
          <Stack spacing={1} width={"50%"}>
            <Typography fontSize={18} fontWeight={700}>
              Long-Term{" "}
              <Typography fontSize={18} fontWeight={400} component={"span"}>
                Rental potential
              </Typography>
            </Typography>
            <Stack
              spacing={1}
              sx={{ padding: 3, borderRadius: "12px", background: "#FFF" }}
              divider={<Divider sx={{ borderColor: "rgba(0, 0, 0, 0.12)" }} />}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"30%"}
                pt={3}
                pb={5}
              >
                <Stack
                  spacing={2}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Typography fontSize={24} fontWeight={700}>
                    Cap Rate
                  </Typography>
                  <ProgressBar
                    value={Math.round(
                      report?.rental_potential?.long_term?.cap_rate
                    )}
                  />
                </Stack>

                <Stack
                  spacing={2}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Typography fontSize={24} fontWeight={700}>
                    Cash Flow
                  </Typography>
                  <Typography
                    fontSize={24}
                    fontWeight={700}
                    sx={{
                      borderRadius: "7px",
                      bgcolor:
                        report?.rental_potential?.long_term?.monthly_cash_flow <
                        0
                          ? "#FFE0E0"
                          : "#DCEFE7",
                      color:
                        report?.rental_potential?.long_term?.monthly_cash_flow <
                        0
                          ? "#EA5455"
                          : "#5CB58F",
                      px: 1.5,
                      py: 0.5,
                    }}
                  >
                    {formatPrice(
                      report?.rental_potential?.long_term?.monthly_cash_flow
                    )}
                  </Typography>
                </Stack>
              </Box>
              <Field
                title={"Monthly Revenue"}
                value={formatPrice(
                  report?.rental_potential?.long_term?.monthly_revenue
                )}
              />
              <Field
                title={"Annual Revenue"}
                value={formatPrice(
                  report?.rental_potential?.long_term?.annual_revenue
                )}
              />
              <Field
                title={"Annual Cash Flow"}
                value={formatPrice(
                  report?.rental_potential?.long_term?.annual_cash_flow
                )}
              />
              <Field
                title={"IRR"}
                value={`${report?.rental_potential?.long_term?.irr}%`}
              />
            </Stack>
          </Stack>
          <Stack spacing={1} width={"50%"}>
            <Typography fontSize={18} fontWeight={700}>
              Short-Term{" "}
              <Typography fontSize={18} fontWeight={400} component={"span"}>
                Rental potential
              </Typography>
            </Typography>
            <Stack
              spacing={1}
              sx={{ padding: 3, borderRadius: "12px", background: "#FFF" }}
              divider={<Divider sx={{ borderColor: "rgba(0, 0, 0, 0.12)" }} />}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"30%"}
                pt={3}
                pb={5}
              >
                <Stack
                  spacing={2}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Typography fontSize={24} fontWeight={700}>
                    Cap Rate
                  </Typography>
                  <ProgressBar
                    value={Math.round(
                      report?.rental_potential?.short_term?.cap_rate
                    )}
                  />
                </Stack>

                <Stack
                  spacing={2}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Typography fontSize={24} fontWeight={700}>
                    Cash Flow
                  </Typography>
                  <Typography
                    fontSize={24}
                    fontWeight={700}
                    sx={{
                      borderRadius: "7px",
                      bgcolor:
                        report?.rental_potential?.short_term
                          ?.monthly_cash_flow < 0
                          ? "#FFE0E0"
                          : "#DCEFE7",
                      color:
                        report?.rental_potential?.short_term
                          ?.monthly_cash_flow < 0
                          ? "#EA5455"
                          : "#5CB58F",
                      px: 1.5,
                      py: 0.5,
                    }}
                  >
                    {formatPrice(
                      report?.rental_potential?.short_term?.monthly_cash_flow
                    )}
                  </Typography>
                </Stack>
              </Box>
              <Field
                title={"Monthly Revenue"}
                value={formatPrice(
                  report?.rental_potential?.short_term?.monthly_revenue
                )}
              />
              <Field
                title={"Annual Revenue"}
                value={formatPrice(
                  report?.rental_potential?.short_term?.annual_revenue
                )}
              />
              <Field
                title={"Annual Cash Flow"}
                value={formatPrice(
                  report?.rental_potential?.short_term?.annual_cash_flow
                )}
              />
              <Field
                title={"IRR"}
                value={`${report?.rental_potential?.short_term?.irr}%`}
              />
            </Stack>
          </Stack>
        </Box>

        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={5}
          sx={{ padding: 3, borderRadius: "12px", background: "#FFF" }}
        >
          <Stack
            spacing={3}
            direction={"row"}
            divider={
              <Divider
                orientation="horizontal"
                sx={{
                  borderWidth: 1,
                  height: "26px",
                  my: "auto !important",
                  borderColor: "rgba(0, 0, 0, 0.12)",
                }}
              />
            }
          >
            <PropertyFeatureField
              title={"Bedrooms"}
              value={report?.property_info?.property_features?.beds}
              icon={"assets/bedrooms.svg"}
            />
            <PropertyFeatureField
              title={"Full Bathrooms"}
              value={report?.property_info?.property_features?.full_baths}
              icon={"assets/full-bathroom.svg"}
            />
            <PropertyFeatureField
              title={"Half Bathrooms"}
              value={report?.property_info?.property_features?.half_baths}
              icon={"assets/half-bathroom.svg"}
            />
            <PropertyFeatureField
              title={"Size"}
              value={`${numberWithCommas(report?.property_info?.sf)} sqft`}
              icon={"assets/size.svg"}
            />
            <PropertyFeatureField
              title={"Lot"}
              value={`${Number(report?.property_info?.lot?.lot_acres)?.toFixed(
                2
              )} acres`}
              icon={"assets/lot.svg"}
            />
          </Stack>
          <Stack
            spacing={3}
            direction={"row"}
            divider={
              <Divider
                orientation="horizontal"
                sx={{
                  borderWidth: 1,
                  height: "26px",
                  my: "auto !important",
                  borderColor: "rgba(0, 0, 0, 0.12)",
                }}
              />
            }
          >
            <PropertyFeatureField
              title={"Stories"}
              value={report?.property_info?.property_features?.stories}
              icon={"assets/stories.svg"}
            />
            <PropertyFeatureField
              title={"Basement"}
              value={
                report?.property_info?.property_features?.basement
                  ? "Yes"
                  : "No"
              }
              icon={"assets/basement.svg"}
            />
            <PropertyFeatureField
              title={"Style"}
              value={report?.property_info?.property_features?.style}
              icon={"assets/style.svg"}
            />
            <PropertyFeatureField
              title={"Year Built"}
              value={report?.property_info?.property_features?.year_built}
              icon={"assets/calendar.svg"}
            />
            <PropertyFeatureField
              title={"Parking"}
              value={
                report?.property_info?.property_features?.parkingn
                  ?.total_parking_spaces
              }
              icon={"assets/parking.svg"}
            />
          </Stack>
        </Box>

        <Box display={"flex"} gap={2}>
          <Stack
            spacing={3}
            width={"33.3%"}
            sx={{ padding: 3, borderRadius: "12px", background: "#FFF" }}
          >
            <Typography fontSize={18} fontWeight={700}>
              Listing Details
            </Typography>
            <Stack
              spacing={1}
              divider={<Divider sx={{ borderColor: "rgba(0, 0, 0, 0.12)" }} />}
            >
              <Field
                title={"Days on Market"}
                value={report?.property_info?.dom}
              />
              <Field
                title={"Origial MLS Name"}
                value={report?.listing_details?.listing_details?.mls_name}
              />
              <Field
                title={"Type of Sale"}
                value={report?.listing_details?.listing_details?.type_of_sale}
              />
              <Field
                title={"MLS"}
                value={report?.listing_details?.listing_details?.mls_id}
              />
            </Stack>
          </Stack>
          <Stack
            spacing={3}
            width={"33.3%"}
            sx={{ padding: 3, borderRadius: "12px", background: "#FFF" }}
          >
            <Typography fontSize={18} fontWeight={700}>
              Property History
            </Typography>

            <Stack spacing={2}>
              <Typography fontSize={16} fontWeight={500}>
                Property Price
              </Typography>
              <PropertyHistoryTable
                rows={report?.listing_details?.property_history}
              />
            </Stack>
          </Stack>
          <Stack
            spacing={3}
            width={"33.3%"}
            sx={{ padding: 3, borderRadius: "12px", background: "#FFF" }}
          >
            <Typography fontSize={18} fontWeight={700}>
              Listing Office
            </Typography>
            <Stack
              spacing={1}
              divider={<Divider sx={{ borderColor: "rgba(0, 0, 0, 0.12)" }} />}
            >
              <Field
                title={"Listing Agent"}
                value={report?.listing_details?.listing_office?.agent_name}
              />
              <Field
                title={"Listing Agent Phone"}
                value={
                  report?.listing_details?.listing_office?.agent_phone_mobile
                }
              />
              <Field
                title={"Listing Agent 2"}
                value={report?.listing_details?.listing_office?.agent_2_name}
              />
              <Field
                title={"Listing Office"}
                value={report?.listing_details?.listing_office?.office_name}
              />
              <Field
                title={"Office Phone"}
                value={report?.listing_details?.listing_office?.office_phone}
              />
              <Field
                title={"Listing Agent E-mail"}
                value={report?.listing_details?.listing_office?.agent_email}
              />
              <Field
                title={"Responsible Broker"}
                value={
                  report?.listing_details?.listing_office?.responsible_broker
                }
              />
            </Stack>
          </Stack>
        </Box>

        <Box display={"flex"} gap={2}>
          <Stack
            spacing={3}
            width={"33.3%"}
            sx={{ padding: 3, borderRadius: "12px", background: "#FFF" }}
          >
            <Typography fontSize={18} fontWeight={700}>
              Property/Community
            </Typography>
            <Stack
              spacing={1}
              divider={<Divider sx={{ borderColor: "rgba(0, 0, 0, 0.12)" }} />}
            >
              <Field
                title={"Property Type"}
                value={report?.property_info?.location_community?.property_type}
              />
              <Field
                title={"Ownership Interest"}
                value={report?.property_info?.location_community?.ownership}
              />
              <Field
                title={"HOA"}
                value={
                  report?.property_info?.location_community?.hoa ? "Yes" : "No"
                }
              />
              <Field
                title={"HOA Fee"}
                value={report?.property_info?.location_community?.hoa_fee}
              />
              <Field
                title={"County"}
                value={report?.property_info?.location_community?.county}
              />
              <Field
                title={"School District"}
                value={
                  report?.property_info?.location_community?.school_district
                }
              />
              <Field
                title={"Structure"}
                value={report?.property_info?.location_community?.structure}
              />
              <Field
                title={"Waterfront"}
                value={report?.property_info?.location_community?.waterfront}
              />
            </Stack>
          </Stack>

          <Stack
            spacing={3}
            width={"33.3%"}
            sx={{ padding: 3, borderRadius: "12px", background: "#FFF" }}
          >
            <Typography fontSize={18} fontWeight={700}>
              Building Info
            </Typography>
            <Stack
              spacing={1}
              divider={<Divider sx={{ borderColor: "rgba(0, 0, 0, 0.12)" }} />}
            >
              <Field
                title={"Above Grade SQFT"}
                value={numberWithCommas(
                  report?.property_info?.building_info?.above_grade_size
                )}
              />
              <Field
                title={"Below Grade SQFT"}
                value={numberWithCommas(
                  report?.property_info?.building_info?.below_grade_size
                )}
              />
              <Field
                title={"Total SQFT"}
                value={report?.property_info?.building_info?.total_size}
              />
              <Field
                title={"Parking"}
                value={report?.property_info?.building_info?.parking}
              />
              <Field
                title={"Elevator"}
                value={report?.property_info?.building_info?.elevator}
              />
              <Field
                title={"Type"}
                value={
                  report?.property_info?.building_info?.building_exterior_type
                }
              />
              <Field
                title={"Construction"}
                value={
                  report?.property_info?.building_info?.construction_materials
                }
              />
              <Field
                title={"Flooring"}
                value={report?.property_info?.building_info?.flooring}
              />
              <Field
                title={"Roof"}
                value={report?.property_info?.building_info?.roof}
              />
            </Stack>
          </Stack>

          <Stack
            spacing={3}
            width={"33.3%"}
            sx={{ padding: 3, borderRadius: "12px", background: "#FFF" }}
          >
            <Typography fontSize={18} fontWeight={700}>
              LOT
            </Typography>
            <Stack
              spacing={1}
              divider={<Divider sx={{ borderColor: "rgba(0, 0, 0, 0.12)" }} />}
            >
              <Field
                title={"LOT Acres / SQFT"}
                value={`${numberWithCommas(
                  report?.property_info?.lot?.lot_acres
                )}/${numberWithCommas(report?.property_info?.lot?.lot_sqft)}`}
              />
              <Field
                title={"Fencing"}
                value={report?.property_info?.lot?.fencing}
              />
              <Field
                title={"Features"}
                value={report?.property_info?.lot?.features}
              />
            </Stack>
          </Stack>
        </Box>

        <Box display={"flex"} gap={2}>
          {graph_values?.length ? (
            <LineChart
              title="Home Value"
              labels={graph_labels}
              series={[
                {
                  name: "Estimate",
                  data: graph_values,
                },
              ]}
              stroke={{
                curve: "straight",
                lineCap: "square",
                width: 2,
              }}
            />
          ) : (
            <Box
              width={"70%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              fontSize={18}
              fontWeight={700}
              sx={{ padding: 3, borderRadius: "12px", background: "#FFF" }}
            >
              No Available Data
            </Box>
          )}
          <Stack
            spacing={3}
            width={"30%"}
            sx={{ padding: 3, borderRadius: "12px", background: "#FFF" }}
          >
            <Typography fontSize={18} fontWeight={700}>
              Schools
            </Typography>

            <AppTabs
              onChange={(_, tab) => setSchoolTab(tab)}
              tabs={[
                {
                  label: "Elementary",
                  component: <SchoolTable rows={schoolData} />,
                },
                {
                  label: "Middle",
                  component: <SchoolTable rows={schoolData} />,
                },
                { label: "High", component: <SchoolTable rows={schoolData} /> },
                {
                  label: "Private",
                  component: <SchoolTable rows={schoolData} />,
                },
              ]}
            />
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default PropertyReport;
