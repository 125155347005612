import {
    Box,
    Pagination,
    Typography,
} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import IconButton from '@mui/material/IconButton';
import SortIcon from '@mui/icons-material/Sort';
const PropertiesTable = ({ rows, page, pageCount, setPage, status, height, sortField, sortDirection, setSortField, setSortDirection }) => {
    const capitalizeWords = (str) => {
        return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    };
    return (
      <>
        <TableContainer
          component={Paper}
          sx={{
            position: "relative",
            maxHeight: height?height:"calc(100vh - 250px)",
            overflow: "auto",
            boxShadow: "none",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            "& .MuiTableHead-root": {
              bgcolor: "rgba(23, 16, 67, 0.02)",
              "& .MuiTableCell-root": { fontWeight: 600, fontSize: 14 },
            },
            "& .MuiTableCell-root": {
              fontWeight: 400,
              fontSize: 12,
              color: "#4E4E4E",
            },
          }}
        >
          <Table stickyHeader >
            <TableHead>
              <TableRow>
                <TableCell sx={{textAlign:"center",padding:"0px"}}>
                    <Tooltip placement="top" title={<div><div style={{textAlign:"center",fontSize:"14px",fontWeight:800}}>Investment Potential</div><br></br><div style={{textAlign:"center",fontSize:"12px"}}>Indicates the investment potential based on multiple property attributes.</div></div>} arrow componentsProps={{
                tooltip: {
                sx: {
                    bgcolor: "#5177FC",
                    textAlign: "center",
                },
                },
            }}>
                <span style={{borderStyle:"dashed",cursor:"help",borderWidth:"0px",borderBottomWidth:"2px"}}  >Investment Potential</span> 
                    </Tooltip>
                    <IconButton
                    onClick={() => {
                        const isAsc = sortField === 'potential' && sortDirection === 'asc';
                        setSortField('potential');
                        setSortDirection(isAsc ? 'desc' : 'asc');
                    }}
                    size="small"
                    >
                    {sortField === 'potential' ? (sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="inherit" fontWeight={700} /> : <ArrowDownwardIcon fontSize="inherit" fontWeight={700} />): (<SortIcon fontSize="inherit" fontWeight={700} />)}
                    </IconButton>
                </TableCell>
                <TableCell sx={{textAlign:"center",padding:"0px"}}>
                    <Tooltip placement="top" title={<div><div style={{textAlign:"center",fontSize:"14px",fontWeight:800}}>Property Condition</div><br></br><div style={{textAlign:"left",fontSize:"12px"}}>In Process - Our AI models are currently determining the condition of this property.</div><div style={{textAlign:"left",fontSize:"12px"}}>I/D - Insufficient Data to determine property condition.</div></div>} arrow componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#5177FC",
                textAlign: "center",
              },
            },
          }}>
                    <span style={{borderStyle:"dashed",cursor:"help",borderWidth:"0px",borderBottomWidth:"2px"}}  >Condition</span>
                </Tooltip>
                <IconButton
                    onClick={() => {
                        const isAsc = sortField === 'overall_predicted_condition' && sortDirection === 'asc';
                        setSortField('overall_predicted_condition');
                        setSortDirection(isAsc ? 'desc' : 'asc');
                    }}
                    size="small"
                    >
                    {sortField === 'overall_predicted_condition' ? (sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="inherit" fontWeight={700} /> : <ArrowDownwardIcon fontSize="inherit" fontWeight={700} />): (<SortIcon fontSize="inherit" fontWeight={700} />)}
                    </IconButton>
                </TableCell>
                {(status !== "sold"&&<TableCell sx={{textAlign:"center",padding:"0px"}}>
                 <Tooltip placement="top" title={<div><div style={{textAlign:"center",fontSize:"14px",fontWeight:800}}>Price Flex Score</div><br></br><div style={{textAlign:"center",fontSize:"12px"}}>Predicts how likely the property owner is willing to negotiate the price.</div><div style={{textAlign:"center",fontSize:"12px"}}>A higher score indicates a higher price flexibility.</div></div>} arrow componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#5177FC",
                textAlign: "center",
              },
            },
          }}>
                    <span style={{borderStyle:"dashed",cursor:"help",borderWidth:"0px",borderBottomWidth:"2px"}}  >Price Flex Score</span>
                </Tooltip>
                <IconButton
                    onClick={() => {
                        const isAsc = sortField === 'weighted_pfs' && sortDirection === 'asc';
                        setSortField('weighted_pfs');
                        setSortDirection(isAsc ? 'desc' : 'asc');
                    }}
                    size="small"
                    >
                    {sortField === 'weighted_pfs' ? (sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="inherit" fontWeight={700} /> : <ArrowDownwardIcon fontSize="inherit" fontWeight={700} />): (<SortIcon fontSize="inherit" fontWeight={700} />)}
                    </IconButton>
              </TableCell>)}
                <TableCell sx={{textAlign:"center",padding:"0px"}}>Address</TableCell>
                <TableCell sx={{textAlign:"center",padding:"0px"}}>Current Est Value <IconButton
                    onClick={() => {
                        const isAsc = sortField === 'default_avm' && sortDirection === 'asc';
                        setSortField('default_avm');
                        setSortDirection(isAsc ? 'desc' : 'asc');
                    }}
                    size="small"
                    >
                    {sortField === 'default_avm' ? (sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="inherit" fontWeight={700} /> : <ArrowDownwardIcon fontSize="inherit" fontWeight={700} />): (<SortIcon fontSize="inherit" fontWeight={700} />)}
                    </IconButton></TableCell>
                <TableCell sx={{textAlign:"center",padding:"0px"}}>List Price<IconButton
                    onClick={() => {
                        const isAsc = sortField === 'list_price' && sortDirection === 'asc';
                        setSortField('list_price');
                        setSortDirection(isAsc ? 'desc' : 'asc');
                    }}
                    size="small"
                    >
                    {sortField === 'list_price' ? (sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="inherit" fontWeight={700} /> : <ArrowDownwardIcon fontSize="inherit" fontWeight={700} />): (<SortIcon fontSize="inherit" fontWeight={700} />)}
                    </IconButton></TableCell>
                {(status === "sold" && <TableCell sx={{textAlign:"center",padding:"0px"}}>Sold Price<IconButton
                    onClick={() => {
                        const isAsc = sortField === 'last_sold_price' && sortDirection === 'asc';
                        setSortField('last_sold_price');
                        setSortDirection(isAsc ? 'desc' : 'asc');
                    }}
                    size="small"
                    >
                    {sortField === 'last_sold_price' ? (sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="inherit" fontWeight={700} /> : <ArrowDownwardIcon fontSize="inherit" fontWeight={700} />): (<SortIcon fontSize="inherit" fontWeight={700} />)}
                    </IconButton></TableCell>)}
                <TableCell sx={{textAlign:"center",padding:"0px"}}>ARV<IconButton
                    onClick={() => {
                        const isAsc = sortField === 'post_renovation_value' && sortDirection === 'asc';
                        setSortField('post_renovation_value');
                        setSortDirection(isAsc ? 'desc' : 'asc');
                    }}
                    size="small"
                    >
                    {sortField === 'post_renovation_value' ? (sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="inherit" fontWeight={700} /> : <ArrowDownwardIcon fontSize="inherit" fontWeight={700} />): (<SortIcon fontSize="inherit" fontWeight={700} />)}
                    </IconButton></TableCell>
                <TableCell sx={{textAlign:"center",padding:"0px"}}>LP/ARV<IconButton
                    onClick={() => {
                        const isAsc = sortField === 'LP/FP' && sortDirection === 'asc';
                        setSortField('LP/FP');
                        setSortDirection(isAsc ? 'desc' : 'asc');
                    }}
                    size="small"
                    >
                    {sortField === 'LP/FP' ? (sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="inherit" fontWeight={700} /> : <ArrowDownwardIcon fontSize="inherit" fontWeight={700} />): (<SortIcon fontSize="inherit" fontWeight={700} />)}
                    </IconButton></TableCell>
                <TableCell sx={{textAlign:"center",padding:"0px"}}>Spread<IconButton
                    onClick={() => {
                        const isAsc = sortField === 'spread' && sortDirection === 'asc';
                        setSortField('spread');
                        setSortDirection(isAsc ? 'desc' : 'asc');
                    }}
                    size="small"
                    >
                    {sortField === 'spread' ? (sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="inherit" fontWeight={700} /> : <ArrowDownwardIcon fontSize="inherit" fontWeight={700} />): (<SortIcon fontSize="inherit" fontWeight={700} />)}
                    </IconButton></TableCell>
                <TableCell sx={{textAlign:"center",padding:"0px"}}>
                    <Tooltip placement="top" title={<div><div style={{textAlign:"center",fontSize:"14px",fontWeight:800}}>Value Gap</div><br></br><div style={{textAlign:"center",fontSize:"12px"}}>Spread divided by Listing Price. </div><div style={{textAlign:"center",fontSize:"12px"}}>A higher value indicates better opportunity.</div></div>} arrow componentsProps={{
                tooltip: {
                sx: {
                    bgcolor: "#5177FC",
                    textAlign: "center",
                },
                },
            }}>
                        <span style={{borderStyle:"dashed",cursor:"help",borderWidth:"0px",borderBottomWidth:"2px"}}  >Value Gap</span>
                    </Tooltip>
                    <IconButton
                    onClick={() => {
                        const isAsc = sortField === 'value_gap' && sortDirection === 'asc';
                        setSortField('value_gap');
                        setSortDirection(isAsc ? 'desc' : 'asc');
                    }}
                    size="small"
                    >
                    {sortField === 'value_gap' ? (sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="inherit" fontWeight={700} /> : <ArrowDownwardIcon fontSize="inherit" fontWeight={700} />): (<SortIcon fontSize="inherit" fontWeight={700} />)}
                    </IconButton>
                </TableCell>
                <TableCell sx={{textAlign:"center",padding:"0px"}}>ROI<IconButton
                    onClick={() => {
                        const isAsc = sortField === 'roi' && sortDirection === 'asc';
                        setSortField('roi');
                        setSortDirection(isAsc ? 'desc' : 'asc');
                    }}
                    size="small"
                    >
                    {sortField === 'roi' ? (sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="inherit" fontWeight={700} /> : <ArrowDownwardIcon fontSize="inherit" fontWeight={700} />): (<SortIcon fontSize="inherit" fontWeight={700} />)}
                    </IconButton></TableCell>
                <TableCell sx={{textAlign:"center",padding:"0px"}}>Renovation Cost<IconButton
                    onClick={() => {
                        const isAsc = sortField === 'renovation_cost' && sortDirection === 'asc';
                        setSortField('renovation_cost');
                        setSortDirection(isAsc ? 'desc' : 'asc');
                    }}
                    size="small"
                    >
                    {sortField === 'renovation_cost' ? (sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="inherit" fontWeight={700} /> : <ArrowDownwardIcon fontSize="inherit" fontWeight={700} />): (<SortIcon fontSize="inherit" fontWeight={700} />)}
                    </IconButton></TableCell>
                <TableCell sx={{textAlign:"center",padding:"0px"}}>Total Project Cost<IconButton
                    onClick={() => {
                        const isAsc = sortField === 'total_project_cost' && sortDirection === 'asc';
                        setSortField('total_project_cost');
                        setSortDirection(isAsc ? 'desc' : 'asc');
                    }}
                    size="small"
                    >
                    {sortField === 'total_project_cost' ? (sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="inherit" fontWeight={700} /> : <ArrowDownwardIcon fontSize="inherit" fontWeight={700} />): (<SortIcon fontSize="inherit" fontWeight={700} />)}
                    </IconButton></TableCell>
                <TableCell sx={{textAlign:"center",padding:"0px"}}>Profit<IconButton
                    onClick={() => {
                        const isAsc = sortField === 'default_avm' && sortDirection === 'asc';
                        setSortField('default_avm');
                        setSortDirection(isAsc ? 'desc' : 'asc');
                    }}
                    size="small"
                    >
                    {sortField === 'profit' ? (sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="inherit" fontWeight={700} /> : <ArrowDownwardIcon fontSize="inherit" fontWeight={700} />): (<SortIcon fontSize="inherit" fontWeight={700} />)}
                    </IconButton></TableCell>
                <TableCell sx={{textAlign:"center",padding:"0px"}}>{status === "sold"?"Sold Date":"DOM"}<IconButton
                    onClick={() => {
                        const isAsc = (sortField === 'list_date'||sortField==="latest_sold_date") && sortDirection === 'asc';
                        status === "sold" ? setSortField('latest_sold_date') : setSortField('list_date');
                        setSortDirection(isAsc ? 'desc' : 'asc');
                    }}
                    size="small"
                    >
                    {(sortField === 'list_date'||sortField==="latest_sold_date") ? (sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="inherit" fontWeight={700} /> : <ArrowDownwardIcon fontSize="inherit" fontWeight={700} />): (<SortIcon fontSize="inherit" fontWeight={700} />)}
                    </IconButton></TableCell>
                <TableCell sx={{textAlign:"center",padding:"0px"}}>Type</TableCell>
                <TableCell sx={{textAlign:"center",padding:"0px"}}>Total SQFT</TableCell>
                <TableCell sx={{textAlign:"center",padding:"0px"}}>Beds</TableCell>
                <TableCell sx={{textAlign:"center",padding:"0px"}}>Baths</TableCell>
                <TableCell sx={{textAlign:"center",padding:"0px"}}>Type of Sale</TableCell>
                <TableCell sx={{textAlign:"center",padding:"0px"}}>Agent Name</TableCell>
                <TableCell sx={{textAlign:"center",padding:"0px"}}>Agent Email</TableCell>
                <TableCell sx={{textAlign:"center",padding:"0px"}}>Agent Phone</TableCell>
                <TableCell sx={{textAlign:"center",padding:"0px"}}>Agent Phone (Office)</TableCell>
              </TableRow>
            </TableHead>
            {rows.length ? (
              <TableBody>
                {rows?.map((row, idx) => (
                  <TableRow key={idx}>
                    <TableCell>
                      <Typography
                        minWidth={150}
                        maxWidth={150}
                        fontSize={14}
                        sx={{ wordBreak: "break-word", textAlign:"center" }}
                      >
                        <span 
                        style={{
                            display: "inline-block",
                            color: row["Investment Potential"]==="Good" || row["Investment Potential"]==="High" || row["Investment Potential"]==="Excellent"  ? "#FFF" : "#000",
                            backgroundColor: row["Investment Potential"]==="Good" ? "#CDD7FE" : row["Investment Potential"]==="High" ? "#9BB0FD" : row["Investment Potential"]==="Excellent" ? "#5177FC" : "#FFF",
                            borderRadius: "7px",
                            padding: row["Investment Potential"]==="Good" || row["Investment Potential"]==="High" || row["Investment Potential"]==="Excellent" ? "5px 0px" : "0px 0px",
                            width:"120px",
                            fontWeight: 600
                        }}
                        >{row["Investment Potential"]}</span>
                      </Typography>
                    </TableCell>
                    <TableCell>
                    <Typography
                        minWidth={80}
                        maxWidth={80}
                        fontSize={14}
                        sx={{ wordBreak: "break-word", textAlign:"center" }}
                      >
                        {row["Property Condition"]}
                      </Typography>
                    </TableCell>
                    {(status !== "sold" && <TableCell>
                        <Typography
                            minWidth={150}
                            maxWidth={150}
                            fontSize={14}
                            sx={{ wordBreak: "break-word", textAlign:"center" }}
                        >
                            {row["PFS"]}
                        </Typography>
                    </TableCell>)}
                    <TableCell>
                        <Typography
                            minWidth={200}
                            maxWidth={200}
                            fontSize={14}
                            sx={{ wordBreak: "break-word", textAlign:"center" }}
                        >
                            {row["Address"]}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            minWidth={150}
                            maxWidth={150}
                            fontSize={14}
                            sx={{ wordBreak: "break-word", textAlign:"center" }}
                        >
                            {row["AVM"]!=="N/A" ? "$"+row["AVM"] : "N/A"}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            minWidth={80}
                            maxWidth={80}
                            fontSize={14}
                            sx={{ wordBreak: "break-word", textAlign:"center" }}
                        >
                            {row["List Price"]!=="N/A" ? "$"+row["List Price"] : "N/A"}
                        </Typography>
                    </TableCell>
                    {(
                        status==="sold"&&<TableCell>
                        <Typography
                            minWidth={80}
                            maxWidth={80}
                            fontSize={14}
                            sx={{ wordBreak: "break-word", textAlign:"center" }}
                        >
                            {row["Sold Price"]!=="N/A" ? "$"+row["Sold Price"] : "N/A"}
                        </Typography>
                    </TableCell>
                    )}
                    <TableCell>
                        <Typography
                            minWidth={90}
                            maxWidth={90}
                            fontSize={14}
                            fontWeight={600}
                            sx={{ wordBreak: "break-word", textAlign:"center" }}
                        >
                            {row["Full Potential"]!== "N/A" ? "$"+row["Full Potential"] : "N/A"}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            minWidth={50}
                            maxWidth={50}
                            fontSize={14}
                            color={row["LP/FP"]!=="N/A" ? (row["LP/FP"]>0 ? "#19BD87" : "red") : "black"}
                            fontWeight={600}
                            sx={{ wordBreak: "break-word", textAlign:"center" }}
                        >
                            {row["LP/FP"]!=="N/A" ? row["LP/FP"]+"%" : "N/A"}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            minWidth={80}
                            maxWidth={80}
                            fontSize={14}
                            fontWeight={600}
                            sx={{ wordBreak: "break-word", textAlign:"center" }}
                        >
                            {row["Spread"]!== "N/A" ? "$"+row["Spread"] : "N/A"}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            minWidth={80}
                            maxWidth={80}
                            fontSize={14}
                            fontWeight={600}
                            sx={{ wordBreak: "break-word", textAlign:"center" }}
                        >
                            {row["Value Gap"]}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            minWidth={50}
                            maxWidth={50}
                            fontSize={14}
                            fontWeight={600}
                            color={row["ROI"]!=="N/A" ? (row["ROI"]>0 ? "#19BD87" : "red") : "black"}
                            sx={{ wordBreak: "break-word", textAlign:"center" }}
                        >
                            {row["ROI"]!== "N/A" ? row["ROI"]+"%" : "N/A"}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            minWidth={150}
                            maxWidth={150}
                            fontSize={14}
                            sx={{ wordBreak: "break-word", textAlign:"center" }}
                        >
                            {row["Renovation Cost"]!== "N/A" ? "$"+row["Renovation Cost"] : "N/A"}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            minWidth={150}
                            maxWidth={150}
                            fontSize={14}
                            sx={{ wordBreak: "break-word", textAlign:"center" }}
                        >
                            {row["Total Project Cost"]!== "N/A" ? "$"+row["Total Project Cost"] : "N/A"}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            minWidth={80}
                            maxWidth={80}
                            fontSize={14}
                            sx={{ wordBreak: "break-word", textAlign:"center" }}
                        >
                            {row["Profit"]!== "N/A" ? "$"+row["Profit"] : "N/A"}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            minWidth={50}
                            maxWidth={50}
                            fontSize={14}
                            sx={{ wordBreak: "break-word", textAlign:"center" }}
                        >
                            {status === "sold"?row["Sold Date"]:row["DOM"]}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            minWidth={80}
                            maxWidth={80}
                            fontSize={14}
                            sx={{ wordBreak: "break-word", textAlign:"center" }}
                        >
                            {row["Type"]}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            minWidth={80}
                            maxWidth={80}
                            fontSize={14}
                            sx={{ wordBreak: "break-word", textAlign:"center" }}
                        >
                            {row["Total SQFT"]}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            minWidth={50}
                            maxWidth={50}
                            fontSize={14}
                            sx={{ wordBreak: "break-word", textAlign:"center" }}
                        >
                            {row["Beds"]}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            minWidth={50}
                            maxWidth={50}
                            fontSize={14}
                            sx={{ wordBreak: "break-word", textAlign:"center" }}
                        >
                            {row["Baths"]}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            minWidth={100}
                            maxWidth={100}
                            fontSize={14}
                            sx={{ wordBreak: "break-word", textAlign:"center" }}
                        >
                            {capitalizeWords(row["Type of Sale"])}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            minWidth={100}
                            maxWidth={100}
                            fontSize={14}
                            sx={{ wordBreak: "break-word", textAlign:"left" }}
                        >
                            {row["Agent Name"]}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            minWidth={200}
                            maxWidth={200}
                            fontSize={14}
                            sx={{ wordBreak: "break-word", textAlign:"left" }}
                        >
                            {row["Agent Email"]}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            minWidth={100}
                            maxWidth={100}
                            fontSize={14}
                            sx={{ wordBreak: "break-word", textAlign:"center" }}
                        >
                            {row["Agent Phone"]}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            minWidth={100}
                            maxWidth={100}
                            fontSize={14}
                            sx={{ wordBreak: "break-word", textAlign:"center" }}
                        >
                            {row["Agent Phone (Office)"]}
                        </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <Typography
                position={"absolute"}
                fontSize={14}
                top={"50%"}
                left={"50%"}
                sx={{ transform: "translate(-50%)" }}
              >
                No Data found!
              </Typography>
            )}
          </Table>
        </TableContainer>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          bgcolor={"#FFF"}
          sx={{
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",
          }}
          py={2}
        >
          <Pagination
            page={page}
            shape="rounded"
            count={pageCount}
            size={"medium"}
            onChange={(_event, value) => setPage(value)}
            sx={{
                '.Mui-selected': {
                    backgroundColor: '#5177FC !important',
                    color: '#fff !important',
                },
              }}
          />
        </Box>
      </>
    );
};
  
export default PropertiesTable;
  