import { Stack, Typography } from "@mui/material";
import AutomatedEmailsContent from "./AutomatedEmailsContent";
import { useEffect, useState, useCallback  } from "react";
import { GetAutomatedEmails } from "../../../../services/api";
import { useCognitoUser } from "../../../../CognitoProvider";
import { AuthenticationGuard } from "../../../authentication-guard";
import { PageLoader } from "../../../page-loader";
const AutomatedEmails = () => {
  const { userLoaded } = useCognitoUser();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try{
    console.log("fetchData");
    const response = await GetAutomatedEmails(page);
    setRows(response.data?.automated_emails);
    setPageCount(response.data?.total_pages);
    setPage(response.data?.page);
    }
    catch{
      setRows([]);
      setPageCount(1);
    }
    finally{
    setLoading(false);
    }
  }, [page]);
  useEffect(() => {
    if (userLoaded) fetchData();
  }, [userLoaded, page, fetchData]);
  if(loading) return <PageLoader />
  return (
    <AuthenticationGuard title={"to start using Automated Emails"}>
      <div className="padding-sides">
        <div style={{backgroundColor:"#F5F5F5",height:"100%",borderRadius:"8px",padding:"10px"}}>
          <AutomatedEmailsContent
            rows={rows}
            page={page}
            setPage={setPage}
            pageCount={pageCount}
            refetch={fetchData}
            setRows={setRows}
  />
        </div>
      </div>
    </AuthenticationGuard>
  );
};

export default AutomatedEmails;
