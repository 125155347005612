import { Stack, Typography } from "@mui/material";
import ContactsContent from "./ContactsContent";
import { useEffect, useState, useCallback  } from "react";
import { GetContacts, SearchContacts } from "../../../../services/api";
import { useCognitoUser } from "../../../../CognitoProvider";
import { AuthenticationGuard } from "../../../authentication-guard";
import { PageLoader } from "../../../page-loader";
const Contacts = () => {
  console.log("Contacts");
  const { userLoaded } = useCognitoUser();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [keyword, setKeyword] = useState("");
  const fetchData = useCallback(async () => {
    setLoading(true);
    try{
      console.log("fetchData");
      if(keyword !== "") {
        const response = await SearchContacts(keyword,page);
        setRows(response.data?.contacts);
        setPageCount(response.data?.total_pages);
        setPage(response.data?.page);
        return;
      }
      else{
        const response = await GetContacts(page);
        setRows(response.data?.contacts);
        setPageCount(response.data?.total_pages);
        setPage(response.data?.page);
      }
    }
    catch{
      setRows([]);
      setPageCount(1);
    }
    finally{
      setLoading(false);
    }
  }, [page, keyword]);
  useEffect(() => {
    if (userLoaded) fetchData();
  }, [userLoaded, page, fetchData]);
  if(loading) return <PageLoader />
  return (
    <AuthenticationGuard title={"to start using Clients"}>
      <div className="padding-sides">
        <div style={{backgroundColor:"#F5F5F5",height:"100%",borderRadius:"8px",padding:"10px"}}>
          <ContactsContent
            rows={rows}
            page={page}
            setPage={setPage}
            pageCount={pageCount}
            refetch={fetchData}
            setRows={setRows}
            keyword={keyword}
            setKeyword={setKeyword}
  />
        </div>
      </div>
    </AuthenticationGuard>
  );
};

export default Contacts;
