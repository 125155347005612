import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PageLoader } from "../../page-loader";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import PropertiesTable from "../../table/PropertiesTable";

const getBgcolor = (status) => {
  switch (status) {
    case "Off Market":
      return "#DDDDDD";
    case "Sold":
      return "#FFE0E0";
    case "Active":
      return "#D6F4E4";
    case "Pending/Under Contract":
      return "#FFEDE6 ";
    default:
      return "#DDDDDD";
  }
};

const getColor = (status) => {
  switch (status) {
    case "N/A":
      return "#9A9A9A";
    case "Unlivable":
      return "#EA5455";
    case "Poor":
      return "#FF692E";
    case "Outdated":
      return "#EC9E7F";
    case "Good":
      return "#3FCD7E";
    case "Off Market":
      return "#606060";
    case "Sold":
      return "#FF797A";
    case "Active":
      return "#3FCD7E";
    case "Pending/Under Contract":
      return "#CD876C ";
    case "Spread":
      return "#5CB58F";
    default:
      return "#202124";
  }
};

export const numberWithCommas = (x) => {
  if (typeof x === "object" || !x) return "";
  if (x === 0) return "N/A";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatPriceToNumber = (price) => {
  return +price?.replace(/,/g, "");
};

export const formatPrice = (price = 0) => {
  const convertedPrice =
    typeof price === "string" ? parseInt(price, 10) : price;
  return convertedPrice
    ? `$${numberWithCommas(+convertedPrice.toFixed())}`
    : "N/A";
};

const OlderPropeties = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [date, setDate] = useState("");
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [pagesCount, setPagesCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [status, setStatus] = useState("active");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const checkNewerProperties = () => {
    window.location.href = "/properties?token=" + searchParams.get("token");
    };
  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      (async () => {
        try {
          setLoading(true);
          let url = `${process.env.REACT_APP_DEV_PORTAL_API_SERVER}/automation/emails/properties/older/?page=${page}`;
          if (sortField) {
            url += `&sort_by=${sortField}&sort_direction=${sortDirection}`;
          }
          const response = await axios.get(
          url,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          if (response.status === 200) {
            setRows(response.data.data);
            setPagesCount(response.data.pages_count);
            setPage(response.data.page);
            setTotalCount(response.data.count);
            setDate(response.data.date);
            setStatus(response.data.status);
          } else {
            setError(true);
          }
        } catch (error) {
          setError(true);
        } finally {
          setLoading(false);
        }
      })();
    } else {
      setError(true);
    }
  }, [searchParams, page, sortField, sortDirection]);

  if (loading) {
    return <PageLoader />;
  }

  if (error)
    return (
      <Typography
        width={"100vw"}
        height={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        fontSize={24}
      >
        Invalid Token
      </Typography>
    );

  return (
    <Box bgcolor={"#F5F6FA"} height={"100vh"} overflow={"auto"} pb={10}>
      <Stack gap={3} maxWidth={"95%"} mx={"auto"} pt={3}>
        <Typography fontSize={24} fontWeight={700} textAlign={"center"} mb={1}>
        {status === "sold" ? "Properties Sold Before "+date :  "Properties Listed Before "+date}
        </Typography>
        <Grid container>
            <Grid item xs={12} md={3}>
                <Typography fontSize={14} fontWeight={500} textAlign={"center"}>
                Results Count : {totalCount}  Properties
                </Typography>
            </Grid>
            <Grid item sx={12} md={7}></Grid>
            <Grid item sx={12} md={2}>
                <Button 
                variant={"text"}
                sx={{
                  float: "right",
                  height: 48,
                  fontSize: 16,
                  fontWeight: 500,
                  padding: "8px 16px",
                  borderRadius: "40px",
                  textTransform: "capitalize",
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
                onClick={checkNewerProperties} >
                    Check Newer Properties
                </Button>
            </Grid>
        </Grid>
        <PropertiesTable 
            rows={rows} 
            pageCount={pagesCount} 
            totalCount={totalCount}
            status = {status} 
            page={page} 
            setPage={setPage}
            height={"calc(100vh - 300px)"}
            sortField={sortField}
            setSortField={setSortField}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
        />
      </Stack>
    </Box>
  );
};

export default OlderPropeties;
