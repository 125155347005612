import React from "react";
import { useNavigate } from "react-router-dom";



const SignupButtonWithCognito = () => {
  const navigate = useNavigate();

  const handleSignUp = async () => {
    navigate("/signup");
  };

  return (
    <button className="button__sign-up" onClick={handleSignUp}>
      Sign Up
    </button>
  );
};

export const SignupButton = () => {
  return <SignupButtonWithCognito />;
};
