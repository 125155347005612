import {
  FormControl,
  Select,
  MenuItem,
  Typography,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { useState } from "react";

const CustomSelect = ({ label, value, onChange, items, error, sx }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <FormControl
      sx={{
        "& .MuiInputLabel-root": {
          fontSize: 14,
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
        ...sx,
      }}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        value={value}
        label={label && <Typography fontSize={10}>{label}</Typography>}
        MenuProps={{ sx: { maxHeight: 400 } }}
        componentsProps={{
          root: {
            style: {
              height: 52,
              borderRadius: 8,
              backgroundColor: "#F7F7F7",
            },
          },
        }}
        onChange={(e) => onChange(e.target.value)}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
      >
        {items?.map((item, i) => (
          <MenuItem key={i} value={item.value}>
            <Typography fontSize={14} fontWeight={500}>
              {item.label}{" "}
            </Typography>
          </MenuItem>
        ))}
      </Select>
      <FormHelperText variant="outlined">
        <Typography
          component={"span"}
          variant="caption"
          textTransform={"capitalize"}
          sx={{
            opacity: error ? 1 : 0,
            color: "red",
            transition: "all ease-out 200ms",
          }}
        >
          {error}
        </Typography>
      </FormHelperText>
    </FormControl>
  );
};

export default CustomSelect;
