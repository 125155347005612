import LoadingButton from "@mui/lab/LoadingButton";
import AppModal from "../../../AppModal";
import {
  Box,
  Pagination,
  Button,
  Stack,
  TextField,
  Typography,
  alpha,
  Link,
  FormControl,
  FormHelperText
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from '@mui/material/Checkbox';
import {useState} from "react";
import { AddGroup,UpdateGroup,DeleteGroup } from "../../../../services/api";
import Grid from '@mui/material/Grid';
import UpdateGroupModal from "./UpdataGroupModal";
import AddContactsModal from "./AddContactsModal";
const GroupsTable = ({ rows, page, pageCount, setPage, refetch }) => {
  const [OpenAddGroupModal, setOpenAddGroupModal] = useState(false);
  const [name, setName] = useState("");
  const [nameRequired, setNameRequired] = useState(false);
  const [addGroupLoading, setAddGroupLoading] = useState(false);
  const [isUpdateGroupModalOpen, setIsUpdateGroupModalOpen] = useState(false);
  const [currentGroupID, setCurrentGroupID] = useState("");
  const [notes, setNotes] = useState("");
  const [selectedGroups, setSelectedGroups] = useState([]);
  const isSelected = (id) => selectedGroups.indexOf(id) !== -1;
  const [currentOpenedModal, setCurrentOpenedModal] = useState(null);
  const [openRemoveGroupsConfirmModal, setOpenRemoveGroupsConfirmModal] = useState(false);
  const [openAddContactsModal, setOpenAddContactsModal] = useState(false);
  const [contactsPage,setContactsPage] = useState(1);
  const [selectedContactsIDs,setSelectedContactsIDs] = useState([]);
  const handleCloseAddContactsModal = () => {
    setCurrentOpenedModal(null);
    setOpenAddContactsModal(false)
  };
  const handleOpenAddContactsToGroupModal = (group) => () => {
      setCurrentGroupID(group.id);
      setName(group.name);
      setContactsPage(1);
      setSelectedContactsIDs([]);
      setCurrentOpenedModal("addContacts");
      setOpenAddContactsModal(true);
  };
  const handleOpenRemoveGroupsConfirmModal = () => setOpenRemoveGroupsConfirmModal(true);
  const handleCloseRemoveGroupsConfirmModal = () => setOpenRemoveGroupsConfirmModal(false);
  const handleOpenAddGroupModal = () => {
    setNameRequired(false);
    resetForm();
    setOpenAddGroupModal(true);
  };
  const handleCloseAddGroupModal = () => {
      setOpenAddGroupModal(false);
  };
  const handleOpenUpdateGroupModal = (group) => {
      setNameRequired(false);
      setCurrentGroupID(group.id);
      setName(group.name);
      setNotes(group.description);
      setContactsPage(1);
      setSelectedContactsIDs([]);
      setCurrentOpenedModal("updateGroup");
      setIsUpdateGroupModalOpen(true);
  };
  const handleCloseUpdateGroupModal = () => {
      setCurrentOpenedModal(null);
      setIsUpdateGroupModalOpen(false);
  };
  const handleRemoveGroups = async () => {
      setOpenRemoveGroupsConfirmModal(false);
      const response = await DeleteGroup(JSON.stringify(selectedGroups));
      if (response.status === 200) {
          refetch();
      }
  };
  const handleAddGroup = async () => {
    setAddGroupLoading(true);
    if (name === "") {
      setNameRequired(true);
      setAddGroupLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", notes);
    const response = await AddGroup(formData);
    if (response.status === 200) {
      setAddGroupLoading(false);
      handleCloseAddGroupModal();
      resetForm();
      refetch();
    }
  };
  const resetForm = () => {
      setName("");
      setNotes("");
  };
  const handleUpdateGroup = async (group_id, group_name, group_notes) => {
      if (name === "") {
          setNameRequired(true);
          return false;
      }
      const formData = new FormData();
      formData.append("id", group_id);
      formData.append("name", name);
      formData.append("description", notes);
    const response = await UpdateGroup(formData);
      if (response.status === 200) {
          handleCloseUpdateGroupModal();
          resetForm();
          refetch();
          return true;
      }
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelectedGroups(newSelecteds);
      return;
    }
    setSelectedGroups([]);
  };
  const handleClick = (event, id) => {
    const selectedIndex = selectedGroups.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedGroups, id);
    } else {
      newSelected = selectedGroups.filter(selectedId => selectedId !== id);
    }

    setSelectedGroups(newSelected);
  };
  return (
    <>
      {rows.length ? (
        <>
          <Stack gap={2}>
            <Grid container>
              <Grid item md={6}>
                <Button 
                  disabled={selectedGroups.length === 0}
                  sx={{
                    height: 30,
                    bgcolor: "#fff",
                    ":hover": {
                      bgcolor: alpha("#fff", 0.9),
                    },
                    fontSize: 16,
                    fontWeight: 500,
                    padding: "2px 18px",
                    margin: "1rem",
                    borderRadius: "8px",
                    borderColor:"#EA5455",
                    border:"1px solid",
                    textTransform: "capitalize",
                    color:"#EA5455"
                  }}
                  onClick={(e) => handleOpenRemoveGroupsConfirmModal()}
                
                >
                  Remove
                </Button>
                <Button 
                    sx={{
                      height: 30,
                      bgcolor: "#5177FC",
                      ":hover": {
                        bgcolor: alpha("#5177FC", 0.9),
                      },
                      fontSize: 16,
                      fontWeight: 500,
                      padding: "2px 18px",
                      margin: "1rem",
                      borderRadius: "8px",
                      textTransform: "capitalize",
                      color:"#fff"
                    }}
                    onClick={(e) => handleOpenAddGroupModal()}
                  
                  >
                    + Add New
                </Button>
              </Grid>
            </Grid>
          </Stack>
          <TableContainer
            component={Paper}
            sx={{
              position: "relative",
              maxHeight: "calc(100vh - 250px)",
              overflow: "auto",
              boxShadow: "none",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
              "& .MuiTableHead-root": {
                bgcolor: "rgba(23, 16, 67, 0.02)",
                "& .MuiTableCell-root": { fontWeight: 600, fontSize: 14 },
              },
              "& .MuiTableCell-root": {
                fontWeight: 400,
                fontSize: 12,
                color: "#4E4E4E",
              },
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={selectedGroups.length > 0 && selectedGroups.length < rows.length}
                      checked={rows.length > 0 && selectedGroups.length === rows.length}
                      onChange={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all clients' }}
                      sx={{
                        '&.Mui-checked': {
                          color: '#5177FC', // replace with your selected color
                        },
                        '&.MuiCheckbox-indeterminate': {
                          color: '#5177FC', // replace with your indeterminate color
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Clients</TableCell>
                  <TableCell>Automated Emails</TableCell>
                  <TableCell>Notes</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
                <TableBody>
                  {rows?.map((row, idx) => {
                    const isItemSelected = isSelected(row.id);
                    return (
                    <TableRow hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onChange={(event) => handleClick(event, row.id)}
                          inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${idx}` }}
                          sx={{
                            '&.Mui-checked': {
                              color: '#5177FC', // replace with your selected color
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Link
                          component="button"
                          variant="body2"
                          onClick={() => handleOpenUpdateGroupModal(row)}
                        >
                          {row?.name}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Typography
                          minWidth={150}
                          maxWidth={150}
                          fontSize={14}
                          sx={{ wordBreak: "break-word" }}
                        >
                          {row?.contacts_length}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          minWidth={150}
                          maxWidth={150}
                          fontSize={14}
                          sx={{ wordBreak: "break-word" }}
                        >
                          {row?.automated_emails_length}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          minWidth={150}
                          maxWidth={150}
                          fontSize={14}
                          sx={{ wordBreak: "break-word" }}
                        >
                          {row?.description}
                        </Typography>
                      </TableCell>
                      <TableCell>
                          <Button onClick={handleOpenAddContactsToGroupModal(row)} >
                              Add Clients
                          </Button>
                      </TableCell>
                    </TableRow>
                  )})}
                </TableBody>
            </Table>
          </TableContainer>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            bgcolor={"#FFF"}
            sx={{
              borderBottomLeftRadius: "12px",
              borderBottomRightRadius: "12px",
            }}
            py={2}
          >
            <Pagination
              page={page}
              shape="rounded"
              count={pageCount}
              size={"medium"}
              onChange={(_event, value) => setPage(value)}
              sx={{
                '.Mui-selected': {
                    backgroundColor: '#5177FC !important',
                    color: '#fff !important',
                },
              }}
            />
          </Box>
          <UpdateGroupModal 
            open={isUpdateGroupModalOpen}
            onClose={handleCloseUpdateGroupModal}
            onSave={handleUpdateGroup}
            refetch={refetch}
            group_id={currentGroupID}
            name={name}
            notes={notes}
            setName={setName}
            setNotes={setNotes}
            currentOpenedModal={currentOpenedModal}
            nameRequired={nameRequired}
            setNameRequired={setNameRequired}
            page={contactsPage}
            setPage={setContactsPage}
            selectedIDs={selectedContactsIDs}
            setSelectedIDs={setSelectedContactsIDs}
          />
          <AddContactsModal
          open = {openAddContactsModal}
          onClose = {handleCloseAddContactsModal}
          refetch = {refetch}
          group_id = {currentGroupID}
          name = {name}
          currentOpenedModal = {currentOpenedModal}
          page={contactsPage}
          setPage={setContactsPage}
          selectedIDs={selectedContactsIDs}
          setSelectedIDs={setSelectedContactsIDs}
          />
          <AppModal
            open= {openRemoveGroupsConfirmModal}
            onClose={handleCloseRemoveGroupsConfirmModal}
            aria-labelledby="delete-confirmation-title"
            aria-describedby="delete-confirmation-description"
          >
            <Box >
              <Typography id="delete-confirmation-title" variant="h6" component="h2">
                Confirm Delete Groups
              </Typography>
              <Typography id="delete-confirmation-description" sx={{ mt: 2 }}>
                Are you sure you want to delete the selected groups?
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                <Button 
                  sx={{ mr: 1 }}
                  onClick={handleCloseRemoveGroupsConfirmModal}
                >
                  Cancel
                </Button>
                <Button 
                  variant="contained" 
                  color="error" 
                  onClick={handleRemoveGroups}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          </AppModal>
        </>
      ) : (
        <div className="centered-container">
          <img src="/assets/groups.png" alt="Descriptive Text" />
          <h5>You don’t have any Groups.</h5>
          <Button
            variant={"contained"}
            sx={{
              height: 30,
              bgcolor: "#5177FC",
              ":hover": {
                bgcolor: alpha("#5177FC", 0.9),
              },
              fontSize: 16,
              fontWeight: 500,
              padding: "2px 18px",
              marginTop: "1rem",
              borderRadius: "8px",
              textTransform: "capitalize",
            }}
            onClick={(e) => handleOpenAddGroupModal()}
          >
            + Create New
          </Button>
        </div>
      )}
      <AppModal
        open={OpenAddGroupModal}
        title={"Create New Group"}
        handleClose={handleCloseAddGroupModal}
        maxWidth="sm"
        closeIcon
      >
        <Stack gap={3}>
          <Grid container>
            <Grid item md={12} >
              <Stack gap={2} paddingTop={"2rem"} paddingBottom={"2rem"}>
                
                <FormControl
                sx={{
                  "& .MuiInputLabel-root": {
                    fontSize: 14,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
              >
                <TextField
                  fullWidth
                  label={"Name"}
                  title={"Name"}
                  required
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  type={"text"}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    if (name !== "") setNameRequired(false);
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      fontSize: 14,
                      border: 0,
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "14px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                      bgcolor: "#F7F7F7",
                    },
                  }}
                />
                <FormHelperText variant="outlined">
                  <Typography
                    component={"span"}
                    variant="caption"
                    textTransform={"capitalize"}
                    sx={{
                      opacity: nameRequired ? 1 : 0,
                      color: "red",
                      transition: "all ease-out 200ms",
                    }}
                  >
                    Group Name is Required.
                  </Typography>
                </FormHelperText>
              </FormControl>
              </Stack>
            </Grid>
            <Grid item md={12} >
              <Stack gap={2} paddingTop={"2rem"} paddingBottom={"2rem"}>
                <TextField
                  fullWidth
                  label={"Notes"}
                  title={"Notes"}
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  type={"text"}
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      fontSize: 14,
                      border: 0,
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "14px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                      bgcolor: "#F7F7F7",
                    },
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
          <LoadingButton
            loading={addGroupLoading}
            variant={"contained"}
            sx={{
              alignSelf: "flex-end",
              height: 48,
              bgcolor: "#5177FC",
              ":hover": {
                bgcolor: alpha("#5177FC", 0.9),
              },
              fontSize: 16,
              fontWeight: 500,
              padding: "8px 16px",
              borderRadius: "8px",
              textTransform: "capitalize",
            }}
            onClick={handleAddGroup}
          >
            Save
          </LoadingButton>
        </Stack>
      </AppModal>
    </>
  );
};
  
export default GroupsTable;
