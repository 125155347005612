import {
    Box,
    Pagination,
    Stack,
    Typography,
  } from "@mui/material";
  import Paper from "@mui/material/Paper";
  import Table from "@mui/material/Table";
  import TableBody from "@mui/material/TableBody";
  import TableCell from "@mui/material/TableCell";
  import TableContainer from "@mui/material/TableContainer";
  import TableHead from "@mui/material/TableHead";
  import TableRow from "@mui/material/TableRow";
  import * as React from "react";
  import moment from "moment";
  import { isArray } from "lodash";
  
  const AutomatedEmailLogsTable = ({ rows, page, pageCount, setPage, refetch }) => {
    return (
      <>
        <TableContainer
          component={Paper}
          sx={{
            position: "relative",
            maxHeight: "calc(100vh - 250px)",
            overflow: "auto",
            boxShadow: "none",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            "& .MuiTableHead-root": {
              bgcolor: "rgba(23, 16, 67, 0.02)",
              "& .MuiTableCell-root": { fontWeight: 600, fontSize: 14 },
            },
            "& .MuiTableCell-root": {
              fontWeight: 400,
              fontSize: 12,
              color: "#4E4E4E",
            },
          }}
        >
          <Table stickyHeader >
            <TableHead>
              <TableRow>
                <TableCell>Automated Email</TableCell>
                <TableCell>Success</TableCell>
                <TableCell>Error</TableCell>
                <TableCell>Time</TableCell>
                <TableCell align="center">Files</TableCell>
              </TableRow>
            </TableHead>
            {rows.length ? (
              <TableBody>
                {rows?.map((row, idx) => (
                  <TableRow key={idx}>
                    <TableCell>
                      <Typography
                        minWidth={200}
                        maxWidth={200}
                        fontSize={14}
                        sx={{ wordBreak: "break-word" }}
                      >
                        {row?.automated_email_name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        minWidth={200}
                        maxWidth={200}
                        fontSize={14}
                        sx={{ wordBreak: "break-word" }}
                      >
                        {row?.success ? "Yes" : "No"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        minWidth={200}
                        maxWidth={200}
                        fontSize={14}
                        sx={{ wordBreak: "break-word" }}
                      >
                        {row?.note ? row?.note : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize={14} noWrap>
                        {moment(row?.created_at).format("DD MMM YYYY HH:mm A")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {(row?.success)&&<Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        gap={2}
                      >
                        <a href={row?.excel_link} target="_blank" rel="noopener noreferrer">Excel</a>
                        <a href={row?.pdf_link} target="_blank" rel="noopener noreferrer">PDF</a>
                        <a href={row?.csv_link} target="_blank" rel="noopener noreferrer">CSV</a>
                        <a href={row?.json_link} target="_blank" rel="noopener noreferrer">JSON</a>
                      </Box>}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <Typography
                position={"absolute"}
                fontSize={14}
                top={"50%"}
                left={"50%"}
                sx={{ transform: "translate(-50%)" }}
              >
                No Data found!
              </Typography>
            )}
          </Table>
        </TableContainer>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          bgcolor={"#FFF"}
          sx={{
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",
          }}
          py={2}
        >
          <Pagination
            page={page}
            shape="rounded"
            count={pageCount}
            size={"medium"}
            onChange={(_event, value) => setPage(value)}
            sx={{
              '.Mui-selected': {
                  backgroundColor: '#5177FC !important',
                  color: '#fff !important',
              },
            }}
          />
        </Box>
      </>
    );
  };
  
  export default AutomatedEmailLogsTable;
  