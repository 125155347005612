import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Box } from "@mui/material";

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 16,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    borderRadius: 10,
    backgroundColor: "#D9D9D9",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: "#5177FC",
  },
}));

const LinearProgressBar = ({ value }) => {
  return (
    <Box sx={{ width: "109px" }}>
      <BorderLinearProgress variant="determinate" value={value} />
    </Box>
  );
};

export default LinearProgressBar;
