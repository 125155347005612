import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Stack, TextField, Typography, alpha } from "@mui/material";
import { useFormik } from "formik";
import { isArray, omit } from "lodash";
import { useEffect, useState } from "react";
import * as yup from "yup";
import {
  GetSingleTargetedMarketingList,
  UpdateSingleSavedTargetedMarketingList,
} from "../../../../services/api";
import AppModal from "../../../AppModal";
import Filters from "../components/Filters";
import { useNavigate, useParams } from "react-router-dom";
import { useCognitoUser } from "../../../../CognitoProvider";
import { numberWithCommas } from "../../propertyReport";

function camelToUnderscore(key) {
  return key.replace(/([A-Z])/g, "_$1").toLowerCase();
}
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Label = ({ label, required }) => {
  return (
    <Typography display={"flex"} fontSize={14} fontWeight={700}>
      {label}
      {required && (
        <Typography
          sx={{ color: "#FD5B5F", fontWeight: 700 }}
          component={"span"}
        >
          *
        </Typography>
      )}
    </Typography>
  );
};

const EditSavedSearch = () => {
  const { id } = useParams();
  const { userLoaded } = useCognitoUser();
  const navigate = useNavigate();

  const [saveSearchModal, setSaveSearchModal] = useState(false);
  const [saveSearchName, setSaveSearchName] = useState("");

  const [filter, setFilter] = useState({});

  const [saveSearchLoading, setSaveSearchLoading] = useState(false);

  const handleSaveSearchOpen = () => setSaveSearchModal(true);
  const handleSaveSearchClose = () => setSaveSearchModal(false);

  const handleUpdate = async (values) => {
    const newValues =
      values.propertyStatus === "Sold"
        ? omit(values, ["dom", "priceFlexibilityScore"])
        : omit(values, ["from", "to"]);

    const filteredValues = Object.entries(newValues).reduce(
      (acc, [key, val]) => {
        const currKey = camelToUnderscore(key);
        if (typeof val === "string" && val)
          acc = { ...acc, [currKey]: val.toLowerCase() };
        if (typeof val === "number" && val) acc = { ...acc, [currKey]: val };
        if (
          isArray(val) &&
          val.length &&
          val.every((val) => typeof val !== "string")
        )
          acc = { ...acc, [currKey]: JSON.stringify(val) };
        if (
          isArray(val) &&
          val.every((val) => typeof val === "string") &&
          val.length
        ){
          if (key === "investmentPotential") {
            val = val.map((v) => (v === "N/A" ? "NP" : v));
          }
          acc = { ...acc, [currKey]: val.join("|") };
        }
          
        if (
          !isArray(val) &&
          typeof val === "object" &&
          Object.values(val).some(Boolean)
        ) {
          if (key === "priceRange") {
            for (let keyInVal in val) {
              acc = {
                ...acc,
                [`price_${keyInVal}`]: val[keyInVal],
              };
            }
          } else if (key === "priceFlexibilityScore") {
            for (let keyInVal in val) {
              acc = {
                ...acc,
                [`pfs_${keyInVal}`]: val[keyInVal],
              };
            }
          } else if (key === "lpfp") {
            for (let keyInVal in val) {
              acc = {
                ...acc,
                [`lpfp_${keyInVal}`]: val[keyInVal],
              };
            }
          } else {
            for (let keyInVal in val) {
              acc = {
                ...acc,
                [`gap_${keyInVal}`]: val[keyInVal],
              };
            }
          }
        }
        if (key === "from" && val) acc = { ...acc, from_date: val };
        if (key === "to" && val) acc = { ...acc, to_date: val };
        return acc;
      },
      {}
    );
    setFilter(filteredValues);
    handleSaveSearchOpen();
  };

  const handleSaveSearch = async () => {
    setSaveSearchLoading(true);
    try {
      const formData = new FormData();
      Object.entries({
        ...filter,
        name: saveSearchName,
      }).map(([key, val]) => formData.append(key, val));
      await UpdateSingleSavedTargetedMarketingList(id, formData);
      //resetForm();
      handleSaveSearchClose();
      //navigate("/products/lists/saved-searches");
    } catch (error) {
    } finally {
      setSaveSearchLoading(false);
    }
  };

  const { values, errors, setFieldValue, setValues, handleSubmit } =
    useFormik({
      initialValues: {
        location: [],
        propertyStatus: "Active",
        investmentPotential: [],
        dom: 1,
        from: "",
        to: "",
        propertyCondition: [],
        propertyType: [],
        priceRange: {
          min: undefined,
          max: undefined,
        },
        priceFlexibilityScore: {
          min: undefined,
          max: undefined,
        },
        valueGap: {
          min: undefined,
          max: undefined,
        },
      },
      validationSchema: yup.object({
        location: yup
          .array()
          .min(1, "Select at least one area.")
          .required()
          .label("Location"),
        propertyStatus: yup.string().required().label("Property Status"),
        from: yup
          .date()
          .when("propertyStatus", ([propertyStatus], schema) =>
            propertyStatus === "Sold"
              ? yup.date().required().label("From")
              : schema
          ),
        to: yup
          .date()
          .when("propertyStatus", ([propertyStatus], schema) =>
            propertyStatus === "Sold"
              ? yup.date().required().label("To")
              : schema
          ),
        investmentPotential: yup
          .array()
          .label("Deal Value"),
        dom: yup.number().required().label("DOM"),
        priceRange: yup
          .object({
            min: yup
              .number()
              .min(0)
              .max(
                999999999,
                `The minimum value must be greater or equal to ${numberWithCommas(
                  999999999
                )}`
              )
              .label("Min")
              .nullable(),
            max: yup
              .number()
              .when("min", ([min], schema, opts) => {
                return min && !isNaN(opts.value) && opts.value <= 999999999
                  ? min >= 999999999
                    ? schema
                        .min(
                          999999999,
                          `The maximum value can't be less than 999,999,999.`
                        )
                        .max(
                          999999999,
                          `The maximum value can't be higher than 999,999,999.`
                        )
                        .nullable()
                        .notRequired()
                    : schema
                        .min(
                          1,
                          `The maximum value must be greater or equal to 2`
                        )
                        .max(
                          999999999,
                          `The maximum value can't be higher than 999,999,999.`
                        )
                        .moreThan(yup.ref("min"), ({ more }) =>
                          more >= 999999999
                            ? `The maximum value can't be higher than 999,999,999.`
                            : `The maximum value must be greater or equal to ${numberWithCommas(
                                more + 1
                              )}`
                        )
                        .nullable()
                        .notRequired()
                  : schema
                      .max(
                        999999999,
                        `The maximum value can't be higher than 999,999,999.`
                      )
                      .nullable()
                      .notRequired();
              })
              .nullable()
              .notRequired(),
          })
          .label("Price Range"),
        priceFlexibilityScore: yup
          .object()
          .when("propertyStatus", ([propertyStatus], schema) =>
            propertyStatus === "Sold"
              ? schema
              : schema.shape({
                  min: yup
                    .number()
                    .min(1, `The minimum value must be greater or equal to 1`)
                    .max(100, `The maximum value can't be higher than 100.`)
                    .nullable()
                    .notRequired()
                    .label("Min"),
                  max: yup
                    .number()
                    .when("min", ([min], schema, opts) => {
                      return min && !isNaN(opts.value) && opts.value <= 100
                        ? min >= 100
                          ? schema
                              .min(
                                100,
                                `The maximum value can't be less than 100.`
                              )
                              .max(
                                100,
                                `The maximum value can't be higher than 100.`
                              )
                              .nullable()
                              .notRequired()
                          : schema
                              .min(
                                1,
                                `The maximum value must be greater or equal to 2`
                              )
                              .max(
                                100,
                                `The maximum value can't be higher than 100.`
                              )
                              .moreThan(yup.ref("min"), ({ more }) =>
                                more >= 100
                                  ? `The maximum value can't be higher than 100.`
                                  : `The maximum value must be greater or equal to ${
                                      more + 1
                                    }`
                              )
                              .nullable()
                              .notRequired()
                        : schema
                            .max(
                              100,
                              `The maximum value can't be higher than 100.`
                            )
                            .nullable()
                            .notRequired();
                    })
                    .nullable()
                    .notRequired(),
                })
          )
          .label("Price Flexibility Score"),
        valueGap: yup
          .object({
            min: yup
              .number()
              .min(1, `The minimum value must be greater or equal to 1`)
              .max(100, `The maximum value can't be higher than 100.`)
              .nullable()
              .notRequired()
              .label("Min"),
            max: yup
              .number()
              .when("min", ([min], schema, opts) => {
                return min && !isNaN(opts.value) && opts.value <= 100
                  ? min >= 100
                    ? schema
                        .min(100, `The maximum value can't be less than 100.`)
                        .max(100, `The maximum value can't be higher than 100.`)
                        .nullable()
                        .notRequired()
                    : schema
                        .min(
                          1,
                          `The maximum value must be greater or equal to 2`
                        )
                        .max(100, `The maximum value can't be higher than 100.`)
                        .moreThan(yup.ref("min"), ({ more }) =>
                          more >= 100
                            ? `The maximum value can't be higher than 100.`
                            : `The maximum value must be greater or equal to ${
                                more + 1
                              }`
                        )
                        .nullable()
                        .notRequired()
                  : schema
                      .max(100, `The maximum value can't be higher than 100.`)
                      .nullable()
                      .notRequired();
              })
              .nullable()
              .notRequired(),
          })
          .label("Value Gap"),
        lpfp: yup
          .object({
            min: yup
              .number()
              .min(1, `The minimum value must be greater or equal to 1`)
              .max(100, `The maximum value can't be higher than 100.`)
              .nullable()
              .notRequired()
              .label("Min"),
            max: yup
              .number()
              .when("min", ([min], schema, opts) => {
                return min && !isNaN(opts.value) && opts.value <= 100
                  ? min >= 100
                    ? schema
                        .min(100, `The maximum value can't be less than 100.`)
                        .max(100, `The maximum value can't be higher than 100.`)
                        .nullable()
                        .notRequired()
                    : schema
                        .min(
                          1,
                          `The maximum value must be greater or equal to 2`
                        )
                        .max(100, `The maximum value can't be higher than 100.`)
                        .moreThan(yup.ref("min"), ({ more }) =>
                          more >= 100
                            ? `The maximum value can't be higher than 100.`
                            : `The maximum value must be greater or equal to ${
                                more + 1
                              }`
                        )
                        .nullable()
                        .notRequired()
                  : schema
                      .max(100, `The maximum value can't be higher than 100.`)
                      .nullable()
                      .notRequired();
              })
              .nullable()
              .notRequired(),
          })
          .label("LP/FP"),
      }),
      onSubmit: handleUpdate,
    });
  const getInvestmentPotential = (investment_potential) => {
    let potentials = investment_potential?.split("|") ?? [];
    // if NP in potentials replace it with N/A
    if (potentials.includes("NP")) {
      potentials = potentials.map((p) => (p === "NP" ? "N/A" : p));
    }
    return potentials;
  };
  useEffect(() => {
    const fetchData = async () => {
      if (userLoaded) {
        try {
          const response = await GetSingleTargetedMarketingList(id);
          const data = response.data;
          setSaveSearchName(data.name);
          setValues({
            investmentPotential: getInvestmentPotential(data.investment_potential),
            location: JSON.parse(data.location),
            propertyStatus: capitalizeFirstLetter(data.property_status),
            dom: Number(data.dom ?? 0),
            from: data.from_date ?? "",
            to: data.to_date ?? "",
            propertyCondition: data.property_condition?.split("|") ?? [],
            propertyType: data.property_type?.split("|") ?? [],
            priceRange: {
              min: data.price_min ?? null,
              max: data.price_max ?? null,
            },
            priceFlexibilityScore: {
              min: data.pfs_min ?? null,
              max: data.pfs_max ?? null,
            },
            valueGap: {
              min: data.gap_min ?? null,
              max: data.gap_max ?? null,
            },
            lpfp:{
              min: data.lpfp_min ?? null,
              max: data.lpfp_max ?? null,
            }
          });
        } catch (error) {
          navigate("/products/lists/saved-searches");
        }
      }
    };
    if (id) fetchData();
    else navigate("/products/lists/saved-searches");
  }, [id, userLoaded, navigate, setValues]);

  return (
    <div  className="padding-sides">
      <Stack gap={2}>
        <Typography fontSize={24} fontWeight={600}>
          Update Filters
        </Typography>

        <Filters filters={values} onChange={setFieldValue} errors={errors} />

        <Button
          variant={"text"}
          sx={{
            width: "max-content",
            height: 48,
            bgcolor: "#5177FC",
            ":hover": {
              bgcolor: alpha("#5177FC", 0.9),
            },
            fontSize: 16,
            fontWeight: 500,
            padding: "8px 16px",
            borderRadius: "8px",
            textTransform: "capitalize",
            color: "#FFF",
            ":disabled": {
              bgcolor: "#F0F0F0",
            },
          }}
          onClick={handleSubmit}
          disabled={
            !Boolean(values.location.length)
          }
        >
          Update Search
        </Button>

        <AppModal
          open={saveSearchModal}
          title={"Save Search"}
          handleClose={handleSaveSearchClose}
          maxWidth="xs"
          closeIcon
        >
          <Stack gap={3}>
            <Stack gap={1.5}>
              <Label label={"Name"} required />
              <TextField
                fullWidth
                label={"Name"}
                title={"Name"}
                inputProps={{ style: { fontSize: 14 } }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                type={"text"}
                value={saveSearchName}
                onChange={(e) => setSaveSearchName(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    fontSize: 14,
                    border: 0,
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "14px",
                  },
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                    bgcolor: "#F7F7F7",
                  },
                }}
              />
            </Stack>
            <LoadingButton
              loading={saveSearchLoading}
              variant={"contained"}
              sx={{
                alignSelf: "flex-end",
                height: 48,
                bgcolor: "#5177FC",
                ":hover": {
                  bgcolor: alpha("#5177FC", 0.9),
                },
                fontSize: 16,
                fontWeight: 500,
                padding: "8px 16px",
                borderRadius: "8px",
                textTransform: "capitalize",
              }}
              onClick={handleSaveSearch}
            >
              Done
            </LoadingButton>
          </Stack>
        </AppModal>
      </Stack>
    </div>
  );
};

export default EditSavedSearch;
