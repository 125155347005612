import CognitoDashboard from "./CognitoDashboard";
import { GetDashboardData } from "../../../services/api";
import React, { useEffect, useState } from 'react';
import { PageLoader } from "../../page-loader";
import { useCognitoUser } from "../../../CognitoProvider";
import { set } from "lodash";
function Dashboard() {
    const { isAuthenticated } = useCognitoUser();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const alt_data = {
            "plan": "Large",
            "used_credits": 2701,
            "available_credits": 17299,
            "total_credits": 20000,
            "total_api_calls": 115,
            "total_reports_generated": 12,
            "total_searches_downloaded": 9,
            "total_records_downloaded": 2033,
            "activities_chart_data": [
                [
                    "2023-12-11",
                    "2023-12-13",
                    "2023-12-12",
                    "2023-12-14",
                    "2023-12-15"
                ],
                [
                    39,
                    77,
                    18,
                    1,
                    1
                ],
                [
                    "APIs<br>Price Flexibility Score (PFS): 5<br>Renovation Return: 2<br>Investment Potential: 4<br>Long Term Rental: 3<br>Short Term Rental: 5<br>Flip Return: 2<br>Home Value Graph: 3<br>Property Information: 7<br>Reports: 4<br>Lists: 4<br>Records: 753<br>",
                    "APIs<br>Price Flexibility Score (PFS): 7<br>Home Value Graph: 9<br>Investment Potential: 2<br>Flip Return: 16<br>Long Term Rental: 5<br>TLC: 6<br>Renovation Cost: 1<br>Renovation Return: 3<br>Property Information: 17<br>Reports: 8<br>Lists: 3<br>Records: 487<br>",
                    "APIs<br>Investment Potential: 1<br>Price Flexibility Score (PFS): 2<br>Flip Return: 3<br>Renovation Cost: 6<br>Short Term Rental: 3<br>Property Information: 3<br>",
                    "Lists: 1<br>Records: 93<br>",
                    "Lists: 1<br>Records: 700<br>"
                ]
            ],
            "apis_credits_chart_data": [
                [
                    "2023-12-11",
                    "2023-12-13",
                    "2023-12-12"
                ],
                [
                    91,
                    156,
                    67
                ]
            ],
            "reports_credits_chart_data": [
                [
                    "2023-12-11",
                    "2023-12-13"
                ],
                [
                    120,
                    240
                ]
            ],
            "searches_credits_chart_data": [
                [
                    "2023-12-11",
                    "2023-12-13",
                    "2023-12-14",
                    "2023-12-15"
                ],
                [
                    753,
                    487,
                    93,
                    700
                ]
            ],
            "billing_cycle_start_date": "2023-12-11",
            "billing_cycle_end_date": "2024-01-11"
        }
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try{
                const {data} = await GetDashboardData();
                console.log(data);
                setData(data);
            }
            catch(err){
                console.log(err);
            }
            finally{
                setLoading(false);
            }
        
        };

        fetchData();
    }, [refresh]);
    console.log(data);
    if (loading) {
        return <PageLoader />;
    }
    if (!isAuthenticated) return <CognitoDashboard data = {alt_data} showSubscrbe={false} />;
    if (!data){
        setRefresh(!refresh);
        setLoading(true);
        return <CognitoDashboard data = {alt_data} showSubscrbe={false} />
    }
    if (data.total_credits === 0) return <CognitoDashboard data = {alt_data} showSubscrbe={true} />;

    return <CognitoDashboard data = {data} />;
}

export default Dashboard;
