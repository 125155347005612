import { Box } from "@mui/material";
import AppTabs from "../../../AppTabs";
import AutomatedEmails from "../automatedEmails";
import AutomatedEmailLogs from "../automatedEmailLogs";

const AutomatedEmailTabs = () => {
  return (
    <Box sx={{marginLeft:"2rem"}}>
      <AppTabs
        sx={{
          "& .MuiTabs-flexContainer": {
            borderBottom: 0,
          },
        }}
        tabs={[
          { label: "Automated Emails", component: <AutomatedEmails /> },
          { label: "Emails History", component: <AutomatedEmailLogs /> },
        ]}
      />
    </Box>
  );
};

export default AutomatedEmailTabs;
