import React from "react";
import { useCognitoUser } from "../../CognitoProvider";


const LogoutButtonWithCognito = () => {
  const { signOut } = useCognitoUser();

  const handleLogout = async () => signOut();

  return (
    <button className="button__logout" onClick={handleLogout}>
      Log Out
    </button>
  );
};

export const LogoutButton = () => {
  return <LogoutButtonWithCognito />;
};
