import AppModal from "../AppModal";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Pagination,
  Typography,
  alpha,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Stack,
} from "@mui/material";
import Recipients from "../pages/targetedLists/automatedEmails/Recipients";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import {AddAutomatedEmail,GetGroups,GetContacts,SearchContacts} from "../../services/api";
const SavedSearchesTable = ({ rows, page, pageCount, setPage, onDelete }) => {
  const navigate = useNavigate();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const [deleteName, setDeleteName] = React.useState(null);
  const [showCreateAutoEmail, setShowCreateAutoEmail] = React.useState(false);
  const [currentSavedSearch, setCurrentSavedSearch] = React.useState(null);
  const [OpenAddAutomatedEmailModal, setOpenAddAutomatedEmailModal] = React.useState(false);
  const [autoEmailName, setAutoEmailName] = React.useState("");
  const [autoEmailNameRequired, setAutoEmailNameRequired] = React.useState(false);
  const [addAutomatedEmailLoading, setAddAutomatedEmailLoading] = React.useState(false);
  const [selectedGroups, setSelectedGroups] = React.useState([]);
  const [selectedContacts, setSelectedContacts] = React.useState([]);
  const [currentAutomatedEmail, setCurrentAutomatedEmail] = React.useState(null);
  const [currentOpenedModal, setCurrentOpenedModal] = React.useState(null);
  const [groups, setGroups] = React.useState([]);
  const [contacts, setContacts] = React.useState([]);
  const [groupsPage, setGroupsPage] = React.useState(1);
  const [groupsPageCount, setGroupsPageCount] = React.useState(1);
  const [contactsPage, setContactsPage] = React.useState(1);
  const [contactsPageCount, setContactsPageCount] = React.useState(1);
  const [purpose, setPurpose] = React.useState("create");
  const [keyword, setKeyword] = React.useState("");
  const resetForm = () => {
    setAutoEmailName("");
    setAutoEmailNameRequired(false);
    setSelectedGroups([]);
    setSelectedContacts([]);
  };
  const handleCloseAddAutomatedEmailModal = () => {
    setOpenAddAutomatedEmailModal(false);
    resetForm();
  };
  const handleAddAutomatedEmail = async () => {
    setAddAutomatedEmailLoading(true);
    //create formdata
    let errors_happened = false;
    if (autoEmailName === "") {
      setAutoEmailNameRequired(true);
      setAddAutomatedEmailLoading(false);
      errors_happened = true;
    }
    if (errors_happened) return;
    const formData = new FormData();
    formData.append("name", autoEmailName);
    formData.append("saved_search", currentSavedSearch.id);
    formData.append("Active", true);
    formData.append("group_ids", JSON.stringify(selectedGroups));
    formData.append("contact_ids", JSON.stringify(selectedContacts));
    const response = await AddAutomatedEmail(formData);
    if (response.status === 200) {
      setAddAutomatedEmailLoading(false);
      handleCloseAddAutomatedEmailModal();
      resetForm();
    }
  };
  const executeSearch = async (search) => {
    if (search === ""){
        fetchData();
        return;
    }
    const response = await SearchContacts(search,page);
    setContacts(response.data?.contacts);
    setContactsPageCount(response.data?.total_pages);
    setContactsPage(response.data?.page);
  };
  const fetchData = React.useCallback(async () => {
    const response = await GetGroups(groupsPage);
    setGroups(response.data?.groups);
    setGroupsPageCount(response.data?.total_pages);
    setGroupsPage(response.data?.page);
    if(keyword === ""){
      const response2 = await GetContacts(contactsPage);
      setContacts(response2.data?.contacts);
      setContactsPageCount(response2.data?.total_pages);
      setContactsPage(response2.data?.page);
    }else{
      const response2 = await SearchContacts(keyword,contactsPage);
      setContacts(response2.data?.contacts);
      setContactsPageCount(response2.data?.total_pages);
      setContactsPage(response2.data?.page);
    }
  }, [groupsPage, contactsPage,keyword]);
  const openDeleteConfirmation = (row) => {
    setShowDeleteConfirmation(true);
    setDeleteId(row.id);
    setDeleteName(row.name);
  }
  const closeDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
    setDeleteId(null);
    setDeleteName(null);
  }
  const deleteSavedSearch = async () => {
    await onDelete(deleteId);
    closeDeleteConfirmation();
  }
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          position: "relative",
          maxHeight: "calc(100vh - 250px)",
          overflow: "auto",
          boxShadow: "none",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          "& .MuiTableHead-root": {
            bgcolor: "rgba(23, 16, 67, 0.02)",
            "& .MuiTableCell-root": { fontWeight: 600, fontSize: 14 },
          },
          "& .MuiTableCell-root": {
            fontWeight: 400,
            fontSize: 12,
            color: "#4E4E4E",
          },
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell></TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          {rows?.length ? (
            <TableBody>
              {rows?.map((row, idx) => (
                <TableRow key={idx}>
                  <TableCell>{row?.name}</TableCell>
                  <TableCell>
                    <span
                      style={{ color: "#5177FC", cursor: "pointer", fontSize: 14, fontWeight: 700}}
                      onClick={() =>{
                        setCurrentSavedSearch(row);
                        setOpenAddAutomatedEmailModal(true);
                      }}
                    >+ Create Auto-Email</span>
                  </TableCell>
                  <TableCell>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      gap={2}
                    >
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        title={"View"}
                        height={32}
                        width={32}
                        onClick={() =>
                          navigate(`/products/lists/new-search`, {
                            state: { filters: row },
                          })
                        }
                        sx={{
                          cursor: "pointer",
                          borderRadius: "6px",
                          border: "1px solid #171043",
                          boxShadow: " 0px 2px 0px 0px rgba(23, 16, 67, 0.02)",
                        }}
                      >
                        <img
                          src={"/assets/action-view.svg"}
                          alt={"view"}
                          style={{
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </Box>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        title={"Edit"}
                        height={32}
                        width={32}
                        onClick={() =>
                          navigate(`/products/lists/saved-searches/${row.id}`)
                        }
                        sx={{
                          cursor: "pointer",
                          borderRadius: "6px",
                          border: "1px solid #171043",
                          boxShadow: " 0px 2px 0px 0px rgba(23, 16, 67, 0.02)",
                        }}
                      >
                        <img
                          src={"/assets/action-edit.svg"}
                          alt={"edit"}
                          style={{
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </Box>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        height={32}
                        width={32}
                        title={"Delete"}
                        onClick={() => openDeleteConfirmation(row)}
                        sx={{
                          cursor: "pointer",
                          borderRadius: "6px",
                          border: "1px solid #EB4646",
                          boxShadow: " 0px 2px 0px 0px rgba(23, 16, 67, 0.02)",
                        }}
                      >
                        <img
                          src={"/assets/action-delete.svg"}
                          alt={"delete"}
                          style={{
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <Typography
              position={"absolute"}
              fontSize={14}
              top={"50%"}
              left={"50%"}
              sx={{ transform: "translate(-50%)" }}
            >
              No Data found!
            </Typography>
          )}
        </Table>
      </TableContainer>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        bgcolor={"#FFF"}
        sx={{
          borderBottomLeftRadius: "12px",
          borderBottomRightRadius: "12px",
        }}
        py={2}
      >
        <Pagination
          page={page}
          shape="rounded"
          count={pageCount}
          size={"medium"}
          onChange={(_event, value) => setPage(value)}
          sx={{
            '.Mui-selected': {
                backgroundColor: '#5177FC !important',
                color: '#fff !important',
            },
          }}
        />
      </Box>
      <AppModal
            open= {showDeleteConfirmation}
            onClose={closeDeleteConfirmation}
            aria-labelledby="delete-confirmation-title"
            aria-describedby="delete-confirmation-description"
          >
            <Box >
              <Typography id="delete-confirmation-title" variant="h6" component="h2">
                Confirm Delete Saved Search
              </Typography>
              <Typography id="delete-confirmation-description" sx={{ mt: 2 }}>
                Are you sure you want to delete "{deleteName}" Saved Search?
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                <Button 
                  sx={{ mr: 1 }}
                  onClick={closeDeleteConfirmation}
                >
                  Cancel
                </Button>
                <Button 
                  variant="contained" 
                  color="error" 
                  onClick={deleteSavedSearch}
                >
                  Delete
                </Button>
              </Box>
            </Box>
      </AppModal>
      <AppModal
        open={OpenAddAutomatedEmailModal}
        title={"Create New Automated Email for "+currentSavedSearch?.name+" saved search"}
        handleClose={handleCloseAddAutomatedEmailModal}
        maxWidth="xl"
        closeIcon
      >
        <Stack gap={1}>
          <Grid container>
            <Grid item md={5} >
            <FormControl
                sx={{
                  "& .MuiInputLabel-root": {
                    fontSize: 14,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                  width:"100%"
                }}
              >
                <TextField
                  fullWidth
                  label={"Name"}
                  title={"Name"}
                  required
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  type={"text"}
                  value={autoEmailName}
                  onChange={(e) => {
                    setAutoEmailName(e.target.value);
                    if (autoEmailName !== "") setAutoEmailNameRequired(false);
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      fontSize: 14,
                      border: 0,
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "14px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                      bgcolor: "#F7F7F7",
                    },
                  }}
                />
                <FormHelperText variant="outlined">
                  <Typography
                    component={"span"}
                    variant="caption"
                    textTransform={"capitalize"}
                    sx={{
                      opacity: autoEmailNameRequired ? 1 : 0,
                      color: "red",
                      transition: "all ease-out 200ms",
                    }}
                  >
                    Name is Required.
                  </Typography>
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
          <Recipients
          selectedGroups={selectedGroups}
          setSelectedGroups={setSelectedGroups}
          selectedContacts={selectedContacts}
          setSelectedContacts={setSelectedContacts}
          currentAutomatedEmail={currentAutomatedEmail}
          currentOpenedModal={currentOpenedModal}
          groups={groups}
          contacts={contacts}
          groupsPage={groupsPage}
          setGroupsPage={setGroupsPage}
          groupsPageCount={groupsPageCount}
          contactsPage={contactsPage}
          setContactsPage={setContactsPage}
          contactsPageCount={contactsPageCount}
          purpose={purpose}
          fetchData={fetchData}
          executeSearch={executeSearch}
          keyword={keyword}
          setKeyword={setKeyword}
          dontshowRemoveRecipients={true}
          />
          <LoadingButton
            loading={addAutomatedEmailLoading}
            variant={"contained"}
            sx={{
              alignSelf: "flex-end",
              height: 30,
              bgcolor: "#5177FC",
              ":hover": {
                bgcolor: alpha("#5177FC", 0.9),
              },
              fontSize: 16,
              fontWeight: 500,
              padding: "8px 16px",
              borderRadius: "8px",
              textTransform: "capitalize",
            }}
            onClick={handleAddAutomatedEmail}
          >
            Save
          </LoadingButton>
        </Stack>
      </AppModal>
    </>
  );
};

export default SavedSearchesTable;
