import { useEffect, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { GetEndpoints, GetTrialInfo } from "../../services/api";
import { Outlet, useNavigate } from "react-router-dom";
import { PageLayout } from "../page-layout";
import TrialBanner from "../Banner/free_trial";
const sidebarItems2 = [
  {
    title:"Dashboard",
    url:"dashboard"
  },
  {
    title: "Lists",
    url: "lists",
    subItems: [
      {
        title: "Saved Searches",
        url: "lists/saved-searches",
      },
      {
        title: "Automated Emails",
        url: "lists/automated-emails",
      },
      {
        title: "Automated Email Logs",
        url: "lists/automated-emails/logs",
      },
      {
        title: "Downloaded Lists",
        url: "lists/downloaded-lists",
      },
      {
        title: "Contacts",
        url: "lists/contacts",
      },
      {
        title: "Groups",
        url: "lists/groups",
      },
    ],
  },
  {
    title: "Full Property Report",
    url: "full-property-report",
    subItems:[
      {
        title: "Demo",
        url: "full-property-report/demo",
      },
      {
        title: "History",
        url: "full-property-report/history",
      },
    ]
  },
];

const sidebarItems = [
  {
    title: "Investment Property Lists",
    url: "lists",
    subItems: [
      {
        'title':'New Search',
        'url':'lists/new-search'
      },
      {
        title: "Saved Searches",
        url: "lists/saved-searches",
      },
      {
        title: "Automated Emails",
        url: "lists/automated-emails",
      },
      {
        title: "Clients and Groups",
        url: "lists/clients-groups",
      },
    ],
  },
  {
    title: "Full Property Reports",
    url: "full-property-report",
    subItems:[
      {
        title: "New Report",
        url: "full-property-report/demo",
      },
      {
        title: "Reports History",
        url: "full-property-report/history",
      },
    ]
  },
];

const downSidebarItems = [
  {
    title: "Usage",
    url: "usage",
    subItems:[
      {
        title: "Dashboard",
        url: "usage/dashboard",
      },
      {
        title: "Download History",
        url: "usage/download-history",
      },
    ]
  },
  {
    title:"Integrations",
    url:"integrations",
    subItems:[
      {
        "title":"Full Property Reports",
        "url":"integrations/full-report"
      },
      {
        "title":"APIs",
        "url":"integrations/apis"
      },
      {
        'title':'Keys',
        'url':'integrations/keys'
      }
    ]
  }
]

const Sidebar = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [trialInfo, setTrialInfo] = useState({
    startDate: new Date(), // Set to the actual start date
    trialPeriodDays: 30, // Assuming a 30-day trial
    is_credit_card_exist: false, // This should be dynamically determined based on user data
    inTrial: false // This should be dynamically determined based on user data
  });
  const [showTrialBanner, setShowTrialBanner] = useState(false); // This should be dynamically determined based on user data
  const [sidebarOpen, setSidebarOpen] = useState(true);

  // Endpoints for APIs
  const [endpoints, setEndpoints] = useState([]);

  useEffect(() => {
    const fetchEndpoints = async () => {
      const response = await GetEndpoints();
      setEndpoints(response.data?.endpoints);
    };
    const getUserTrialInfo = async () => {
      const response = await GetTrialInfo();
      const start_date = new Date(response.data?.trial_start*1000);
      const end_date = new Date(response.data?.trial_end*1000);
      const status = response.data?.status;
      const is_credit_card_exist = response.data?.is_credit_card_exist;
      const trialPeriodDays = Math.ceil((end_date - start_date)/(1000*60*60*24));
      const inTrial = status === "trialing"
      if(inTrial){
        console.log("in trial");
        setShowTrialBanner(true);
        setTrialInfo({
          startDate: start_date,
          trialPeriodDays:trialPeriodDays,
          is_credit_card_exist:is_credit_card_exist,
          inTrial:inTrial
        });
      }
    };
    fetchEndpoints();
    getUserTrialInfo();
  }, []);

  return (
    <div>
      <PageLayout>
      <TrialBanner trialInfo={trialInfo} setTrialInfo={setTrialInfo} setShowTrialBanner={setShowTrialBanner} />
      <div className={showTrialBanner ?"main-container_banner-shown":"main-container"}  >
      <div className="main-sidebar">
      <div
          className={`sidebar ${
            width > 1024
              ? "sidebarOpen"
              : sidebarOpen
              ? "sidebarOpen"
              : "sidebarClose"
          }`}
          style={{
            zIndex: 100,
          }}
        >
          <span className="sidebarTitle"></span>
          <div style={{height:"38vh", overflow:"auto"}}>
            {sidebarItems.map((item, idx) => (
              <div key={idx} className={`sidebarItemContainer ${
                item.title==="Usage"?"marginTop30vh":""
              }`}>
                <span
                  className={`${
                    (window.location.href.includes(item.url)) && "sidebarItemActive"
                  } sidebarItem`}
                  onClick={() => navigate(item.url)}
                >
                  {item.title}
                </span>
                {(window.location.href.includes(item.url)) &&
                  item.subItems &&
                  item?.subItems.map((sItem, idx) => (
                    (sItem.title === "APIs") ? (
                      <>
                      <span
                      key={idx}
                      className={`${
                        window.location.href.includes(sItem.url) &&
                        "sidebarSubItemActive"
                      } sidebarSubItem`}
                      onClick={() => navigate("integrations/apis/documentation/"+endpoints[0]?.id || "")}
                    >
                      {sItem.title}
                    </span>
                    {window.location.href.includes("apis") &&
                          endpoints
                            ?.map((endpoint) => (
                              <span
                                key={endpoint?.id}
                                style={{
                                  paddingLeft: "48px",
                                }}
                                className={`${
                                  window.location.href.split("products")[1] ===
                                    `/integrations/apis/documentation/${endpoint?.id}` &&
                                  "sidebarSubItemActive"
                                } sidebarSubItem`}
                                onClick={() =>
                                  navigate(`integrations/apis/documentation/${endpoint?.id}`)
                                }
                              >
                                {endpoint.name}
                              </span>
                            ))}
                    </>
                      ) : 
                      (
                        <span
                        key={idx}
                        className={`${
                          window.location.href.includes(sItem.url) &&
                          "sidebarSubItemActive"
                        } sidebarSubItem`}
                        onClick={() => navigate(sItem.url)}
                      >
                        {sItem.title}
                      </span>
                      )

                    
                  ))}
              </div>
            ))}
            
          </div>
          <div style={{height:"calc(62vh - 140px)", overflow:"auto"}}>
            {downSidebarItems.map((item, idx) => (
              <div key={idx} className={`sidebarItemContainer ${
                (item.title==="Usage" && !window.location.href.includes('usage') && ! window.location.href.includes('integrations'))?"marginTop30vh":(item.title==="Usage" && window.location.href.includes('api'))? "" :(item.title==="Usage" && (window.location.href.includes('usage')||window.location.href.includes('integrations')))? "marginTop14vh":""
              }`}>
                <span
                  className={`${
                    (window.location.href.includes(item.url)) && "sidebarItemActive"
                  } sidebarItem`}
                  onClick={() => navigate(item.url)}
                >
                  {item.title}
                </span>
                {(window.location.href.includes(item.url)) &&
                  item.subItems &&
                  item?.subItems.map((sItem, idx) => (
                    (sItem.title === "APIs") ? (
                      <>
                      <span
                      key={idx}
                      className={`${
                        window.location.href.includes(sItem.url) &&
                        "sidebarSubItemActive"
                      } sidebarSubItem`}
                      onClick={() => navigate("integrations/apis/documentation/"+endpoints[0]?.id || "")}
                    >
                      {sItem.title}
                    </span>
                    {window.location.href.includes("apis") &&
                          endpoints
                            ?.map((endpoint) => (
                              <span
                                key={endpoint?.id}
                                style={{
                                  paddingLeft: "48px",
                                }}
                                className={`${
                                  window.location.href.split("products")[1] ===
                                    `/integrations/apis/documentation/${endpoint?.id}` &&
                                  "sidebarSubItemActive"
                                } sidebarSubItem`}
                                onClick={() =>
                                  navigate(`integrations/apis/documentation/${endpoint?.id}`)
                                }
                              >
                                {endpoint.name}
                              </span>
                            ))}
                    </>
                      ) : 
                      (
                        <span
                        key={idx}
                        className={`${
                          window.location.href.includes(sItem.url) &&
                          "sidebarSubItemActive"
                        } sidebarSubItem`}
                        onClick={() => navigate(sItem.url)}
                      >
                        {sItem.title}
                      </span>
                      )

                    
                  ))}
              </div>
            ))}
            
          </div>
        </div>
        <img
          className="chevron"
          onClick={() => setSidebarOpen((prev) => !prev)}
          src={
            !sidebarOpen
              ? "/assets/chevron-right.svg"
              : "/assets/chevron-left.svg"
          }
          style={{
            marginTop: -23,
            left: sidebarOpen ? 300 : 0,
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
            zIndex: 100
          }}
          alt="chevron"
          width={24}
          height={24}
        />
      </div>
      <div className="main-content">
      <div
          style={{
            height: "calc(100vh - 100px)",
            overflowY: "auto",
            
          }}
        >
          <Outlet />
        </div>
      </div>
    </div>
    </PageLayout>
    </div>
    
  );
};

export default Sidebar;
