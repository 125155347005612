import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import GroupsTable from './GroupsTable';
import ContactsTable from '../contacts/ContactsTable';
import { useEffect, useState  } from "react";
import SearchField from "../components/SearchField";
import Grid from '@mui/material/Grid';
import LoadingButton from "@mui/lab/LoadingButton";
import { alpha } from "@mui/material";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import AppModal from "../../../AppModal";
import { AddGroup,GetContacts,SearchContacts,AddToGroup,AddContact } from "../../../../services/api";
function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Recipients = ({selectedGroups, setSelectedGroups, selectedContacts, setSelectedContacts, purpose, currentAutomatedEmail, currentOpenedModal, removeRecipientsLoading,groups,groupsPage,groupsPageCount,contacts,contactsPage,contactsPageCount,fetchData,setContactsPage,setGroupsPage, executeSearch,keyword,setKeyword,removeRecipients, dontshowRemoveRecipients})  => {
  const [value, setValue] = useState(0);
  const [addGroupModalOpen, setAddGroupModalOpen] = useState(false);
  const [addGroupLoading, setAddGroupLoading] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupNotes, setGroupNotes] = useState("");
  const [groupNameRequired, setGroupNameRequired] = useState(false);
  const [contactsToAddtoGroup, setContactsToAddtoGroup] = useState([]);
  const [contactsToAddtoGroupPage, setContactsToAddtoGroupPage] = useState(1);
  const [contactsToAddtoGroupPageCount, setContactsToAddtoGroupPageCount] = useState(1);
  const [selectedContactsToAddtoGroup, setSelectedContactsToAddtoGroup] = useState([]);
  const [keywordToAddGroup, setKeywordToAddGroup] = useState("");
  const [OpenAddContactModal, setOpenAddContactModal] = useState(false);
  const [addContactLoading, setAddContactLoading] = useState(false);
  const [firstName, setfirstName] = useState("");
  const [firstNameRequired, setfirstNameRequired] = useState(false);
  const [lastName, setlastName] = useState("");
  const [email, setemail] = useState("");
  const [emailRequired, setemailRequired] = useState(false);
  const [phone, setphone] = useState("");
  const [address, setaddress] = useState("");
  const [notes, setnotes] = useState("");
  const formatPhoneNumber = (phoneNumberString) => {
    let formattedPhoneNumber = phoneNumberString.replace(/\D/g, ''); // Remove all non-numeric characters
    // Format the numbers as US phone number in XXX-XXX-XXXX format
    if (formattedPhoneNumber.length > 3 && formattedPhoneNumber.length <= 6) {
      formattedPhoneNumber = formattedPhoneNumber.replace(/^(\d{3})(\d+)/, '$1-$2');
    } else if (formattedPhoneNumber.length > 6) {
      formattedPhoneNumber = formattedPhoneNumber.replace(/^(\d{3})(\d{3})(\d+)/, '$1-$2-$3');
    }
    return formattedPhoneNumber;
  };
  const handlePhoneChange = (e) => {
    let val = e.target.value;
    let originalVal = val.replace(/\D/g, '');
    if (originalVal.length <= 10) {
      setphone(formatPhoneNumber(e.target.value))
    }
  };
  const resetFormContact = () => {
    setfirstName("");
    setlastName("");
    setphone("");
    setemail("");
    setaddress("");
    setnotes("");
  };
  const handleAddContact = async () => {
    setAddContactLoading(true);
    //create formdata
    let errors_happened = false;
    if (firstName === "") {
      setfirstNameRequired(true);
      setAddContactLoading(false);
      errors_happened = true;
    }
    if (email === "") {
      setemailRequired(true);
      setAddContactLoading(false);
      errors_happened = true;
    }
    if (errors_happened) return;
    const formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("address", address);
    formData.append("notes", notes);
    const response = await AddContact(formData);
    if (response.status === 200) {
      setAddContactLoading(false);
      handleCloseAddContactModal();
      resetFormContact();
      fetchData();
    }
  };
  const handleOpenAddContactModal = () => {
    console.log("handleOpenAddContactModal");
    resetFormContact();
    setemailRequired(false);
    setfirstNameRequired(false);
    setOpenAddContactModal(true)
  };
  const handleCloseAddContactModal = () => setOpenAddContactModal(false);

  const executeSearchToAddGroup = async (search) => {
    if (search === ""){
      fetchContactsToAddtoGroup();
        return;
    }
    const response = await SearchContacts(search,contactsToAddtoGroupPage);
    setContactsToAddtoGroup(response.data?.contacts);
    setContactsToAddtoGroupPageCount(response.data?.total_pages);
    setContactsToAddtoGroupPage(response.data?.page);
};
  const resetGroupForm = () => {
    setGroupName("");
    setGroupNotes("");
    setGroupNameRequired(false);
    setSelectedContactsToAddtoGroup([]);
  };
  const handleCloseAddGroupModal = () => {
    setAddGroupModalOpen(false);
    resetGroupForm();
  };
  const handleAddGroup = async () => {
    if (groupName === "") {
      setGroupNameRequired(true);
      return;
    }
    setAddGroupLoading(true);
    setGroupNameRequired(false);
    const formData = new FormData();
    formData.append("name", groupName);
    formData.append("description", groupNotes);
    const response = await AddGroup(formData);
    if (response.status === 200) {
      let group_id = response.data?.id;
      const formData = new FormData();
      formData.append("contact_ids", JSON.stringify(selectedContactsToAddtoGroup));
      const innerResponse = await AddToGroup(formData, group_id);
      if (innerResponse.status === 200){
        setAddGroupLoading(false);
        handleCloseAddGroupModal();
        resetGroupForm();
        fetchData();
      }
    }
  }
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  const fetchContactsToAddtoGroup = async () => {
    if(keywordToAddGroup === ""){
      const response = await GetContacts(contactsToAddtoGroupPage);
      setContactsToAddtoGroup(response.data?.contacts);
      setContactsToAddtoGroupPageCount(response.data?.total_pages);
    }
    else{
      executeSearchToAddGroup(keywordToAddGroup);
    }
  };

  useEffect(() => {
    fetchContactsToAddtoGroup();
  },[contactsToAddtoGroupPage,keywordToAddGroup]);

  useEffect(() => {
    fetchData();
  }
  , [purpose, currentAutomatedEmail, currentOpenedModal, fetchData, removeRecipientsLoading]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{justifyContent: "center", display: "flex" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Groups" {...a11yProps(0)} />
          <Tab label="Clients" {...a11yProps(1)} />
        </Tabs>
      </Box>
      
      <CustomTabPanel value={value} index={0}>
        {!dontshowRemoveRecipients&&<Grid container>
            <Grid item md={3}>
                <LoadingButton
                loading={removeRecipientsLoading}
                variant={"contained"}
                disabled={(selectedGroups.length === 0)&&(selectedContacts.length === 0)}
                sx={{
                  height: 30,
                  bgcolor: "#fff",
                  ":hover": {
                    bgcolor: alpha("#fff", 0.9),
                  },
                  fontSize: 16,
                  fontWeight: 500,
                  padding: "2px 18px",
                  margin: "1rem",
                  borderRadius: "8px",
                  borderColor:"#EA5455",
                  border:"1px solid",
                  textTransform: "capitalize",
                  color:"#EA5455"
                }}
                onClick={removeRecipients}
                >
                    Remove Recipients
                </LoadingButton>
            </Grid>
        </Grid>}
        <Grid container>
          <Grid item md={3}>
            <LoadingButton
            loading={addGroupLoading}
            variant={"contained"}
            sx={{
              height: 30,
              bgcolor: "#fff",
              ":hover": {
                bgcolor: alpha("#fff", 0.9),
              },
              fontSize: 16,
              fontWeight: 500,
              padding: "2px 18px",
              margin: "1rem",
              borderRadius: "8px",
              borderColor:"#5177FC",
              border:"1px solid",
              textTransform: "capitalize",
              color:"#5177FC"
            }}
            onClick={()=> setAddGroupModalOpen(true)}
            >
              + Add New Group
            </LoadingButton>
          </Grid>
        </Grid>
        <GroupsTable
          rows={groups}
          page={groupsPage}
          setPage={setGroupsPage}
          pageCount={groupsPageCount}
          selectedIDs={selectedGroups}
          setSelectedIDs={setSelectedGroups}
          height={"calc(100vh - 480px)"}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <Grid container>
          <Grid item md={9}>
            <Grid container>
                <Grid item md={3}>
                  <LoadingButton
                  loading={addContactLoading}
                  variant={"contained"}
                  sx={{
                    height: 30,
                    bgcolor: "#fff",
                    ":hover": {
                      bgcolor: alpha("#fff", 0.9),
                    },
                    fontSize: 16,
                    fontWeight: 500,
                    padding: "2px 18px",
                    margin: "1rem",
                    borderRadius: "8px",
                    borderColor:"#5177FC",
                    border:"1px solid",
                    textTransform: "capitalize",
                    color:"#5177FC"
                  }}
                  onClick={handleOpenAddContactModal}
                  >
                    + Add New Client
                  </LoadingButton>
                </Grid>
                <Grid item md={3}>
                    {!dontshowRemoveRecipients&&<LoadingButton
                    loading={removeRecipientsLoading}
                    variant={"contained"}
                    disabled={(selectedGroups.length === 0)&&(selectedContacts.length === 0)}
                    sx={{
                      height: 30,
                      bgcolor: "#fff",
                      ":hover": {
                        bgcolor: alpha("#fff", 0.9),
                      },
                      fontSize: 16,
                      fontWeight: 500,
                      padding: "2px 18px",
                      margin: "1rem",
                      borderRadius: "8px",
                      borderColor:"#EA5455",
                      border:"1px solid",
                      textTransform: "capitalize",
                      color:"#EA5455"
                    }}
                    onClick={removeRecipients}
                    >
                        Remove Recipients
                    </LoadingButton>}
                </Grid>
            </Grid>
          </Grid>
          <Grid item md={3}>
            <SearchField executeSearch={executeSearch} keyword={keyword} setKeyword={setKeyword} bgColor={"#FFF"} ></SearchField>
          </Grid>
      </Grid>
        <ContactsTable
          rows={contacts}
          page={contactsPage}
          setPage={setContactsPage}
          pageCount={contactsPageCount}
          selectedIDs={selectedContacts}
          setSelectedIDs={setSelectedContacts}
          height={"calc(100vh - 480px)"}
        />
      </CustomTabPanel>
      <AppModal
        open={addGroupModalOpen}
        title={"Create New Group"}
        handleClose={handleCloseAddGroupModal}
        maxWidth="xl"
        closeIcon
      >
        <Stack gap={1}>
          <Grid container>
            <Grid item md={5} >
              <Stack gap={1}>
                
                <FormControl
                sx={{
                  "& .MuiInputLabel-root": {
                    fontSize: 14,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
              >
                <TextField
                  fullWidth
                  label={"Name"}
                  title={"Name"}
                  required
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  type={"text"}
                  value={groupName}
                  onChange={(e) => {
                    setGroupName(e.target.value);
                    if (groupName !== "") setGroupNameRequired(false);
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      fontSize: 14,
                      border: 0,
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "14px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                      bgcolor: "#F7F7F7",
                    },
                  }}
                />
                <FormHelperText variant="outlined">
                  <Typography
                    component={"span"}
                    variant="caption"
                    textTransform={"capitalize"}
                    sx={{
                      opacity: groupNameRequired ? 1 : 0,
                      color: "red",
                      transition: "all ease-out 200ms",
                    }}
                  >
                    Group Name is Required.
                  </Typography>
                </FormHelperText>
              </FormControl>
              </Stack>
            </Grid>
            <Grid item md={2}></Grid>
            <Grid item md={5} >
              <Stack gap={1}>
                <TextField
                  fullWidth
                  label={"Notes"}
                  title={"Notes"}
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  type={"text"}
                  value={groupNotes}
                  onChange={(e) => setGroupNotes(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      fontSize: 14,
                      border: 0,
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "14px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                      bgcolor: "#F7F7F7",
                    },
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={9}>
            </Grid>
            <Grid item md={3}>
            <SearchField executeSearch={executeSearchToAddGroup} keyword={keywordToAddGroup} setKeyword={setKeywordToAddGroup} bgColor={"#FFF"} ></SearchField>
            </Grid>
          </Grid>
          <ContactsTable
              rows = {contactsToAddtoGroup}
              page = {contactsToAddtoGroupPage}
              setPage = {setContactsToAddtoGroupPage}
              pageCount = {contactsToAddtoGroupPageCount}
              ShowUpdate = {false}
              selectedIDs = {selectedContactsToAddtoGroup}
              setSelectedIDs = {setSelectedContactsToAddtoGroup}
              height={"calc(100vh - 470px)"}
            />
          <LoadingButton
            loading={addGroupLoading}
            variant={"contained"}
            sx={{
              alignSelf: "flex-end",
              height: 48,
              bgcolor: "#5177FC",
              ":hover": {
                bgcolor: alpha("#5177FC", 0.9),
              },
              fontSize: 16,
              fontWeight: 500,
              padding: "8px 16px",
              borderRadius: "8px",
              textTransform: "capitalize",
            }}
            onClick={handleAddGroup}
          >
            Save
          </LoadingButton>
        </Stack>
      </AppModal>
      <AppModal
        open={OpenAddContactModal}
        title={"Create New Client"}
        handleClose={handleCloseAddContactModal}
        maxWidth="md"
        closeIcon
      >
        <Stack gap={3}>
          <Grid container>
            <Grid item md={5} >
              <Stack gap={2} paddingTop={"2rem"} paddingBottom={"2rem"}>
              <FormControl
                sx={{
                  "& .MuiInputLabel-root": {
                    fontSize: 14,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
              >
                <TextField
                  fullWidth
                  label={"First Name"}
                  title={"First Name"}
                  required
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  type={"text"}
                  value={firstName}
                  onChange={(e) => {
                    setfirstName(e.target.value);
                    if (firstName !== "") setfirstNameRequired(false);
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      fontSize: 14,
                      border: 0,
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "14px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                      bgcolor: "#F7F7F7",
                    },
                  }}
                />
                <FormHelperText variant="outlined">
                  <Typography
                    component={"span"}
                    variant="caption"
                    textTransform={"capitalize"}
                    sx={{
                      opacity: firstNameRequired ? 1 : 0,
                      color: "red",
                      transition: "all ease-out 200ms",
                    }}
                  >
                    First Name is Required.
                  </Typography>
                </FormHelperText>
              </FormControl>
                
              </Stack>
              <Stack gap={2} paddingTop={"2rem"} paddingBottom={"2rem"}>
              <FormControl
                sx={{
                  "& .MuiInputLabel-root": {
                    fontSize: 14,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
              >
                <TextField
                  fullWidth
                  label={"Phone"}
                  title={"Phone"}
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  type={"text"}
                  value={phone}
                  onChange={handlePhoneChange }
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      fontSize: 14,
                      border: 0,
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "14px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                      bgcolor: "#F7F7F7",
                    },
                  }}
                />
                <FormHelperText variant="outlined">
                  <Typography
                    component={"span"}
                    variant="caption"
                    textTransform={"capitalize"}
                    sx={{
                      opacity: 0,
                      color: "red",
                      transition: "all ease-out 200ms",
                    }}
                  >
                    Phone is Required.
                  </Typography>
                </FormHelperText>
              </FormControl>
                
              </Stack>
              <Stack gap={2} paddingTop={"2rem"} paddingBottom={"2rem"}>
                
                <FormControl
                sx={{
                  "& .MuiInputLabel-root": {
                    fontSize: 14,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
              >
                <TextField
                  fullWidth
                  label={"Address"}
                  title={"Address"}
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  type={"text"}
                  value={address}
                  onChange={(e) => setaddress(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      fontSize: 14,
                      border: 0,
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "14px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                      bgcolor: "#F7F7F7",
                    },
                  }}
                />
                <FormHelperText variant="outlined">
                  <Typography
                    component={"span"}
                    variant="caption"
                    textTransform={"capitalize"}
                    sx={{
                      opacity: 0,
                      color: "red",
                      transition: "all ease-out 200ms",
                    }}
                  >
                  </Typography>
                </FormHelperText>
              </FormControl>
              </Stack>
            </Grid>
            <Grid item md={2}></Grid>
            <Grid item md={5} >
              <Stack gap={2} paddingTop={"2rem"} paddingBottom={"2rem"}>
                
                <FormControl
                sx={{
                  "& .MuiInputLabel-root": {
                    fontSize: 14,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
              >
                <TextField
                  fullWidth
                  label={"Last Name"}
                  title={"Last Name"}
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  type={"text"}
                  value={lastName}
                  onChange={(e) => setlastName(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      fontSize: 14,
                      border: 0,
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "14px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                      bgcolor: "#F7F7F7",
                    },
                  }}
                />
                <FormHelperText variant="outlined">
                  <Typography
                    component={"span"}
                    variant="caption"
                    textTransform={"capitalize"}
                    sx={{
                      opacity: 0,
                      color: "red",
                      transition: "all ease-out 200ms",
                    }}
                  >
                    Last Name is Required.
                  </Typography>
                </FormHelperText>
              </FormControl>
              </Stack>
              <Stack gap={2} paddingTop={"2rem"} paddingBottom={"2rem"}>
              <FormControl
                sx={{
                  "& .MuiInputLabel-root": {
                    fontSize: 14,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
              >
                <TextField
                  fullWidth
                  label={"Email"}
                  title={"Email"}
                  required
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  type={"email"}
                  value={email}
                  onChange={(e) => {
                    setemail(e.target.value);
                    if (email !== "") setemailRequired(false);
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      fontSize: 14,
                      border: 0,
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "14px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                      bgcolor: "#F7F7F7",
                    },
                  }}
                />
                <FormHelperText variant="outlined">
                  <Typography
                    component={"span"}
                    variant="caption"
                    textTransform={"capitalize"}
                    sx={{
                      opacity: emailRequired  ? 1 : 0,
                      color: "red",
                      transition: "all ease-out 200ms",
                    }}
                  >
                    Email is Required.
                  </Typography>
                </FormHelperText>
              </FormControl>
                
              </Stack>
              <Stack gap={2} paddingTop={"2rem"} paddingBottom={"2rem"}>
              <FormControl
                sx={{
                  "& .MuiInputLabel-root": {
                    fontSize: 14,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
              >
                <TextField
                  fullWidth
                  label={"Notes"}
                  title={"Notes"}
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  type={"text"}
                  value={notes}
                  onChange={(e) => setnotes(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      fontSize: 14,
                      border: 0,
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "14px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                      bgcolor: "#F7F7F7",
                    },
                  }}
                />
                <FormHelperText variant="outlined">
                  <Typography
                    component={"span"}
                    variant="caption"
                    textTransform={"capitalize"}
                    sx={{
                      opacity: 0,
                      color: "red",
                      transition: "all ease-out 200ms",
                    }}
                  >
                  </Typography>
                </FormHelperText>
              </FormControl>
                
              </Stack>
            </Grid>
          </Grid>
          
          
          
          
          
          
          <LoadingButton
            loading={addContactLoading}
            variant={"contained"}
            sx={{
              alignSelf: "flex-end",
              height: 48,
              bgcolor: "#5177FC",
              ":hover": {
                bgcolor: alpha("#5177FC", 0.9),
              },
              fontSize: 16,
              fontWeight: 500,
              padding: "8px 16px",
              borderRadius: "8px",
              textTransform: "capitalize",
            }}
            onClick={handleAddContact}
          >
            Save
          </LoadingButton>
        </Stack>
      </AppModal>
    </Box>
  );
}

export default Recipients;