import { useEffect, useState } from "react";
import { PageLayout } from "../../page-layout";
import Modal from "react-modal";
import {
  CheckoutPlan,
  GetSubscriptions,
  UpdateSubscription,
  ManageBilling
} from "../../../services/api";
import SubscriptionPlanCard from "./SubscriptionPlanCard";
import { useCognitoUser } from "../../../CognitoProvider";
import { useNavigate } from "react-router-dom";

const SubscriptionPlans = () => {
  const navigate = useNavigate();
  const { currentPlan, getUserSubscription, isAuthenticated } =
    useCognitoUser();

  const [plans, setPlans] = useState([]);
  const [planCategory, setPlanCategory] = useState("Monthly"); // Monthly or Annual
  const [showErrorMessageModal, setShowErrorMessageModal] = useState(false); // Monthly or Annual
  const [errorMessage, setErrorMessage] = useState(""); // Monthly or Annual
  const showErrorMessage = (message) => {
    setErrorMessage(message);
    setShowErrorMessageModal(true);
  };
  const closeErrorMessageModal = () => {
    setShowErrorMessageModal(false);
  };
  const openAddPaymentMethodPage = async () => {
    const response = await ManageBilling(window.location.href)
    const url = response.data.url;
    window.open(url, "_self");
  };
  const confirmSubscriptionChanged = (plan) => {
    const planName = plan.name.toLowerCase();
    const planType = plan.type.toLowerCase();
    const planId = plan.id;
    const planInfo = { planName, planType, planId };
    localStorage.setItem("plan_info", JSON.stringify(planInfo));
    // Show popup with confirmation message with Ok button only
    // On Ok button click, redirect to dashboard
    // no need to show cancel button

  }
  const [notificationModalIsOpen, setNotificationModalIsOpen] = useState(false);
  function closeNotificationModal(){
      setNotificationModalIsOpen(false);
  }
  function openNotificationModal(){
    setNotificationModalIsOpen(true);
  }
  const selectPlan = async (plan) => {
    if (currentPlan?.plan_info?.id) {
      const res = await UpdateSubscription(plan);
      console.log("res", res);
      if (res?.data?.outcome === "success"){
        setTimeout(async () => {
          await getUserSubscription();
        }, 1000);
        openNotificationModal();
        confirmSubscriptionChanged(plan);
      }
      else{
        console.log("Error in updating subscription");
        const errorMessage = res?.data?.error;
        showErrorMessage(errorMessage);
      }
    } else if (!isAuthenticated) {
      navigate("/signup", { state: { plan } });
    } else {
      const response = await CheckoutPlan(plan);
      window.location.replace(response.data.url);
    }
  };

  useEffect(() => {
    const getPlans = async () => {
      const response = await GetSubscriptions();
      setPlans(response?.data?.subscriptions);
    };
    getPlans();
  }, []);
  
  return (
    <PageLayout>
      <div className="padding-sides" >
        <h2 className="black-text">Select Plans</h2>
        <h4 className="black-text">
          Select a payment plan to finish setting up your account.
        </h4>

        <div className="subscriptionCategoryPlanWrapper">
          <button
            className={`subscriptionCategoryPlanBtn ${
              planCategory === "Monthly" && "activePlan"
            }`}
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            onClick={() => setPlanCategory("Monthly")}
          >
            Monthly
          </button>
          <button
            className={`subscriptionCategoryPlanBtn ${
              planCategory === "Annual" && "activePlan"
            }`}
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            onClick={() => setPlanCategory("Annual")}
          >
            Annual
          </button>
        </div>

        <div
          className="subscriptionWrapper"
          style={{ gridTemplateColumns: "repeat(4, 1fr)" }}
        >
          {planCategory === "Monthly"
            ? plans.map(
                (plan) =>
                  plan.type === "Monthly" && (
                    <SubscriptionPlanCard
                      key={plan.id}
                      plan={plan}
                      onClick={(id) => selectPlan(id)}
                    />
                  )
              )
            : plans.map(
                (plan) =>
                  plan.type === "Yearly" && (
                    <SubscriptionPlanCard
                      key={plan.id}
                      plan={plan}
                      onClick={(id) => selectPlan(id)}
                    />
                  )
              )}
        </div>

        <h5 className="black-text">
          There are no extra charges once monthly credits are spent. You can upgrade your plan at any moment.
        </h5>
        <Modal
        isOpen={notificationModalIsOpen}
        onRequestClose={closeNotificationModal}
        contentLabel="Confirmation"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            minWidth: "50%",
            maxWidth: "50%",
            maxHeight: "50%",
            overflow: "auto",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <span className="modalTitle">
          Your Plan has been updated successfully.
        </span>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 12,
          }}
        >
          <button className="button__close" onClick={closeNotificationModal}>
            OK
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={showErrorMessageModal}
        onRequestClose={closeErrorMessageModal}
        contentLabel="Confirmation"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            minWidth: "50%",
            maxWidth: "50%",
            maxHeight: "50%",
            overflow: "auto",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <span className="modalTitle">
          You do not have a payment method to upgrade your subscription. <a style={{color:"blue"}} href="javascript:void(0)" onClick={openAddPaymentMethodPage} >Add Payment Method</a> first in order to upgrade your plan.
        </span>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 12,
          }}
        >
          <button className="button__close" onClick={closeNotificationModal}>
            OK
          </button>
        </div>
      </Modal>
        </div>
    </PageLayout>
  );
};

export default SubscriptionPlans;
